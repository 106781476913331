import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  Container,
  CardTitle,
  FormFeedback,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// ck editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import {
  EditNewsdata,
  createNewsLetter,
  updateNewsLetter,
  // editUser
} from "../../../toolkitStore/NewsSlice/NewsLetterSlice"
import { APIServerIP } from "helpers/Sats_api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Publish } from "@mui/icons-material"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, NewsLetterList } from "toolkitStore/memberSlice"

function NewsForm(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EditnewsDetails, NewsLetterList, NewsLetterisSuccessfully } =
    useSelector(state => state.NewsLetterReducer)
  //Delete
  const [selectedData, SetSelectData] = useState()
  const [indiviualArray, setIndiviualArray] = useState()
  // const [Ckdata,setCkdata]=useState("")

  //image

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    if (NewsLetterisSuccessfully) {
      //     // localStorage.removeItem("publishnewsletter")
      //     // validation.resetForm()
      // navigate("/sendnewsletter")
      SetSelectData(EditnewsDetails)
    }
  }, [NewsLetterisSuccessfully])

  useEffect(() => {
    SetSelectData()
    const EU = localStorage?.getItem("publishnewsletter")
    // console.log("Retrieved Data:", EU)
    if (localStorage.getItem("publishnewsletter")) {
      dispatch(EditNewsdata({ _id: EU }))
      SetSelectData(EditnewsDetails)
    } else {
      // console.log("Retrieved empty:", EU)
      SetSelectData()
    }
  }, [])
  //   useEffect(() => {
  //       SetSelectData(EditnewsDetails)
  // }, [EditnewsDetails])

  //   console.log(EditnewsDetails)

  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("publishnewsletter")
    // validation.resetForm()
    SetSelectData()
    navigate("/sendnewsletter")
  }
  // if (selectedData?.mailto) {
  //   console.log(selectedData?.mailto)
  //   const mailttt = selectedData?.mailto
  //     .map((item, ind) => {
  //       return item.email
  //     })
  //     .join()
  //   console.log(mailttt)
  // }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      job_name: selectedData?.job_name || "",
      mailto:
        (selectedData?.mailto &&
          selectedData?.mailto.map((item, ind) => item.email).join()) ||
        "",
      bulk_mail: selectedData?.bulk_mail || "",
      mail_content: selectedData?.mail_content || "",
    },
    validationSchema: Yup.object({
      job_name: Yup.string().required("job name is required"),
      bulk_mail: Yup.string().required("Select Mail Option"),
      //   mail_content: Yup.string().required("mail Content is required"),
    }),
    onSubmit: values => {
      // console.log(values)
      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        values["mailto"] = indiviualArray
        dispatch(updateNewsLetter(values))
        navigate("/sendnewsletter")
      } else {
        values["mailto"] = indiviualArray
        dispatch(createNewsLetter(values))
        navigate("/sendnewsletter")
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Job Creation" />
          <Card>
            <CardBody>
              <div className="mt-0 mb-3 d-flex align-items-center border-secondary border-bottom pb-2 ">
                <h5 className="mb-1 card-title flex-grow-1">Form</h5>
                <div className="flex-shrink-0 mb-1">
                  <Link
                    to="#!"
                    onClick={e => {
                      Cancel(e)
                    }}
                    className="btn btn-dark me-1"
                  >
                    Back
                  </Link>
                </div>
              </div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="mt-4">
                  <Col>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="job_name">Job Name (Mail Subject)</Label>
                      <Input
                        name="job_name"
                        className="form-control"
                        placeholder="Enter Subject"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.job_name || ""}
                        invalid={
                          validation.touched.job_name &&
                          validation.errors.job_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.job_name &&
                      validation.errors.job_name ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.job_name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12 ">
                      <Label htmlFor="bulk_mail">Bulk to</Label>
                      <select
                        id="bulk_mail"
                        name="bulk_mail"
                        className={`form-select ${
                          validation.touched.bulk_mail &&
                          validation.errors.bulk_mail &&
                          "is-invalid"
                        }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bulk_mail}
                      >
                        <option value={""}>Select Mail to</option>
                        <option value={"Individual"}>Individual</option>
                        <option value={"All Members"}>All members</option>
                        <option value={"All Subscribers"}>
                          All Subscribers
                        </option>
                        <option value={"All Members & Subscribers"}>
                          All members & Subscribers
                        </option>
                      </select>
                      {validation.touched.bulk_mail &&
                      validation.errors.bulk_mail ? (
                        <div
                          type="invalid"
                          className="invalid-feedback"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.bulk_mail}
                        </div>
                      ) : null}
                    </div>
                    {validation.values.bulk_mail === "Individual" && (
                      <div className="mb-3 col-12  ">
                        <Label htmlFor="mailto">Send to</Label>
                        <Input
                          name="mailto"
                          className="form-control"
                          placeholder="Enter Mail Id"
                          type="text"
                          onChange={e => {
                            const mailtoArray = e.target.value.split(",")
                            // console.log(mailtoArray[0])
                            const arr = mailtoArray.map(item => ({
                              email: item,
                              status: 0,
                            }))
                            setIndiviualArray(arr)
                            validation.setFieldValue("mailto", e.target.value)
                          }}
                          onBlur={validation.handleBlur}
                          // selectedData.mailto&&(selectedData.mailto.join())
                          value={validation.values.mailto}
                          invalid={
                            validation.touched.mailto &&
                            validation.errors.mailto
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mailto &&
                        validation.errors.mailto ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.mailto}
                          </FormFeedback>
                        ) : null}
                      </div>
                    )}
                  </Col>
                  <div className="mb-3 col-12 ">
                    <Label htmlFor="mail_content">Mail Content</Label>
                    <CKEditor
                      config={{
                        width: "100%",
                        placeholder: "Newsletter Content",
                        ckfinder: {
                          uploadUrl: `${APIServerIP}/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images`,
                        },
                      }}
                      editor={ClassicEditor}
                      data={`${validation.values.mail_content}`}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        // setCkdata(data)
                        validation.setFieldValue("mail_content", data)
                      }}
                    />
                  </div>

                  <Col className=" my-3 justify-content-end px-2 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger w-md px-5"
                      onClick={e => Cancel(e)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewsForm
