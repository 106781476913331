import React, { useMemo, useState } from "react"
import ExportExcel from "components/Excelconvert/ExportExcel"

import { useSelector, useDispatch } from "react-redux"
import {
  CardBody,
  Card,
  Row,
  Col,
  CardTitle,
  Badge,
  Modal,
  UncontrolledTooltip,
  Button,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import moment from "moment"

//import components
import TableContainer from "components/Common/TableContainer"
import { useEffect } from "react"

// slice
import {
  TicketBookedHistory,
  resetVenue,
} from "toolkitStore/eventMSlice/VenueSlice"

function TicketBooked(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)
  const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
  // console.log("venue_id", venue_id_c)

  const { TicketBookedDetails, VenueisSuccessfully } = useSelector(
    state => state.VenueReducer
  )

  useEffect(() => {
    dispatch(TicketBookedHistory(venue_id_c.VenueId))

    dispatch(resetVenue())
  }, [])
  // useEffect(() => {
  //   dispatch(TicketBookedHistory(venue_id_c.VenueId))
  //   dispatch(resetVenue())
  // }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Member ID",
        accessor: "sats_member_id",
        Cell: cellProps => (
          <ul className="list-unstyled hstack gap-3 mb-0 text-center">
            <li
              className={
                cellProps.row.original.sats_member_id
                  ? "text-dark"
                  : "text-danger"
              }
            >
              {cellProps.row.original.sats_member_id
                ? cellProps.row.original.sats_member_id
                : "Non Member"}
            </li>
          </ul>
        ),
      },
      {
        Header: "First Name",
        accessor: "firstname",
      },
      {
        Header: "Last Name",
        accessor: "lastname",
      },


      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Payment Date",
        accessor: "createdAt",
        Cell: cellProps => {
          return (
            <div>
              {moment
                .parseZone(cellProps.row.original.createdAt)
                .format("MM/DD/YYYY")}
            </div>
          )
        },
      },
      {
        Header: "Tansaction ID",
        accessor: "transaction_id",
      },

      {
        Header: "Adult",
        accessor: "adult_count",
      },
      {
        Header: "Child",
        accessor: "child_count",
      },
      {
        Header: "Amount",
        accessor: "net_amount",
        Cell: cellProps => {
          return <div>$ {cellProps.row.original.net_amount}</div>
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        Cell: cellProps => {
          // console.log("cellProps.row.original", cellProps.row.original)
          return (
            <ul className="list-unstyled mb-0">
              <div
                className={
                  cellProps.row.original.ticket_payment_status === "initiated"
                    ? "badge badge-soft-secondary"
                    : cellProps.row.original.ticket_payment_status === "failed"
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                }
              >
                {cellProps.row.original.ticket_payment_status === "initiated"
                  ? "Initiated"
                  : cellProps.row.original.ticket_payment_status === "failed"
                    ? "Failed"
                    : "Success"}
              </div>
            </ul>
          )
        },
      },

    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Booked Information" />

          <Row className="">
            <Card>
              <CardBody className="border-botton">
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    {event_id_c.EventName + " / " + venue_id_c.VenueName}
                  </h5>
                  <div className="mb-1 flex-shrink-0">
                    {/* <ExportExcel
                      excelData={TicketBookedDetails}
                      fileName={"TicketsSales"}
                    /> */}
                    <ExportExcel
                      excelData={TicketBookedDetails}
                      fileName={`Booking Details ${venue_id_c.VenueName}`}
                    />

                    <Link to="/upcomingevents" className="btn btn-secondary me-1">
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={TicketBookedDetails}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
                {TicketBookedDetails.length > 30 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TicketBooked
