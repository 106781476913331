import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  FormFeedback,
  Modal,
  Container,
} from "reactstrap"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createGallery,
  updateGallery,
  deleteGallery,
  activeInactiveGallery,
  fetchGallery,
  // editUser
} from "../../../toolkitStore/GallerySlice"

import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, GalleryList } from "toolkitStore/memberSlice"

function Gallery(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [imagec, SetImage] = useState()

  //image
  const { GalleryList, GalleryisSuccessfully } = useSelector(
    state => state.GalleryReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchGallery())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchGallery())
    if (GalleryisSuccessfully) {
      validation.resetForm()
      setIsRight(false)
    }
  }, [GalleryisSuccessfully])
  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "ga_title",
      },
      {
        Header: "Year",
        accessor: "ga_year",
      },
      {
        Header: "Description",
        accessor: "ga_shortdesc",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.ga_status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.ga_status === 0
                    ? "Inactive"
                    : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.ga_status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)

                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      ga_status: SData.ga_status,
                    }

                    dispatch(activeInactiveGallery(PassingData))
                  }}
                >
                  {cellProps.row.original.ga_status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ga_title: selectedData?.ga_title || "",
      ga_shortdesc: selectedData?.ga_shortdesc || "",
      // ga_photo:selectedData?.ga_photo||"",
      ga_photo_url: selectedData?.ga_photo_url || "",
      ga_video_url: selectedData?.ga_video_url || "",
      ga_year: selectedData?.ga_year || "",
      ga_month: selectedData?.ga_month || "",

      ga_cover_pic: selectedData?.ga_cover_pic || "",
    },
    validationSchema: Yup.object({
      ga_title: Yup.string().required("Gallery titile is required"),
      // ga_photo:Yup.string().required("Gallery Photo  is required"),
      ga_photo_url: Yup.string().required("Gallery Photo url is required"),
      ga_video_url: Yup.string().required("Gallery Video url is required"),
      ga_cover_pic: Yup.string().required(
        "Gallery Cover Image url is required"
      ),
      ga_year: Yup.number().required("Gallery Year is required"),
      ga_month: Yup.number().required("Gallery Month is required"),
    }),
    onSubmit: values => {
      // console.log(values)
      if (selectedData?._id) {
        // console.log(values, selectedData?._id)
        values["_id"] = selectedData?._id
        dispatch(updateGallery(values, imagec))
      } else {
        dispatch(createGallery(values, imagec))
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Gallery Management" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    Galleries
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel excelData={GalleryList} fileName={"Gallery"} />

                    <Link
                      to="#!"
                      onClick={() => {
                        toggleRightCanvas()
                        SetSelectData()
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add Gallery
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={GalleryList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {selectedData?._id ? "Update Gallery" : "Add Gallery"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="p-3">
                  <Col>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ga_title">Title</Label>
                      <Input
                        name="ga_title"
                        className="form-control"
                        placeholder="Enter The Gallery Title"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ga_title || ""}
                        invalid={
                          validation.touched.ga_title &&
                          validation.errors.ga_title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ga_title &&
                      validation.errors.ga_title ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ga_title}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12 ">
                      <Label htmlFor="ga_shortdesc">Short Description :</Label>
                      <Input
                        name="ga_shortdesc"
                        className="form-control "
                        placeholder="Explanation...!"
                        type="textarea"
                        rows="5"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ga_shortdesc || ""}
                        invalid={
                          validation.touched.ga_shortdesc &&
                          validation.errors.ga_shortdesc
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ga_shortdesc &&
                      validation.errors.ga_shortdesc ? (
                        <FormFeedback type="invalid">
                          {validation.errors.ga_shortdesc}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ga_photo">Cover Image</Label>
                      <Input
                        name="ga_cover_pic"
                        className="form-control"
                        placeholder="Upload the Banner Image"
                        type="file"
                        required
                        onChange={e => {
                          validation.setFieldValue(
                            "ga_cover_pic",
                            e.currentTarget.files[0].name

                            // e.currentTarget.files[0].name
                          )
                          SetImage(e.currentTarget.files[0])
                        }}
                        invalid={
                          validation.touched.ga_cover_pic &&
                          validation.errors.ga_cover_pic
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ga_cover_pic &&
                      validation.errors.ga_cover_pic ? (
                        <div
                          type="invalid"
                          className="invalid-feedback"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ga_cover_pic}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ga_year">Year </Label>
                      <Input
                        name="ga_year"
                        className="form-control"
                        placeholder="Enter the Gallery Year"
                        type="Number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ga_year || ""}
                        invalid={
                          validation.touched.ga_year &&
                          validation.errors.ga_year
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ga_year &&
                      validation.errors.ga_year ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ga_year}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ga_month">Month </Label>
                      <Input
                        name="ga_month"
                        className="form-control"
                        placeholder="Enter the Gallery Month"
                        type="Number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ga_month || ""}
                        invalid={
                          validation.touched.ga_month &&
                          validation.errors.ga_month
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ga_month &&
                      validation.errors.ga_month ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ga_month}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ga_photo_url">Gallery URL</Label>
                      <Input
                        name="ga_photo_url"
                        className="form-control"
                        placeholder="Paste the Photo url"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ga_photo_url || ""}
                        invalid={
                          validation.touched.ga_photo_url &&
                          validation.errors.ga_photo_url
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ga_photo_url &&
                      validation.errors.ga_photo_url ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ga_photo_url}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ga_video_url">Video URL</Label>
                      <Input
                        name="ga_video_url"
                        className="form-control"
                        placeholder="Paste the Video url"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ga_video_url || ""}
                        invalid={
                          validation.touched.ga_video_url &&
                          validation.errors.ga_video_url
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ga_video_url &&
                      validation.errors.ga_video_url ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ga_video_url}
                        </FormFeedback>
                      ) : null}
                    </div>
                    {/* <div className="mb-3 col-12  ">
                    <Label htmlFor="ga_cover_pic">Cover Image </Label>
                    <Input
                      name="ga_cover_pic"
                      className="form-control"
                      placeholder="Paste the Cover Image url"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ga_cover_pic || ""}
                      invalid={
                        validation.touched.ga_cover_pic && validation.errors.ga_cover_pic
                          ? true
                          : false
                      }
                    />
                    {validation.touched.ga_cover_pic && validation.errors.ga_cover_pic ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.ga_cover_pic}
                      </FormFeedback>
                    ) : null}
                  </div> */}
                  </Col>

                  <Col className=" mt-3 justify-content-around px-2 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger w-md px-5"
                      onClick={toggleRightCanvas}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Gallery ?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteGallery(delete_data))

                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Gallery
