import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const initialOrganiserState = {
  OrganiserList: [],
  OrganiserisSuccessfully: false,
  isUserEdit: false,
}

const OrganiserSlice = createSlice({
  name: "EventOrganiser",
  initialState: initialOrganiserState,
  reducers: {
    setOrganiserList: (state, action) => {
      state.OrganiserList = action.payload
    },
    setOrganiserSuccessfullyAdded: (state, action) => {
      state.OrganiserisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    resetOrganiser: (state, action) => {
      state.isSuccessfullyAdded = false
      state.isUserEdit = false
    },
  },
})

export const createOrganiser = values => {
  return async dispatch => {
    try {
      dispatch(resetOrganiser())
      const responseData = await post(
        `${APIServerIP}manage-event-venue-organiser/create-event-venue-organiser`,
        values
      )
      // console.log("Organiser user", responseData)
      if (responseData.response_code === 500) {
        dispatch(setOrganiserSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateOrganiser = values => {
  return async dispatch => {
    try {
      dispatch(resetOrganiser())
      const responseData = await post(
        `${APIServerIP}manage-event-venue-organiser/update-event-venue-organiser`,
        values
      )

      if (responseData.response_code === 200) {
        // console.log("Organiser user", responseData.state)
        dispatch(setOrganiserSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const deleteOrganiser = id => {
  return async dispatch => {
    try {
      dispatch(resetOrganiser())
      const responseData = await post(
        `${APIServerIP}manage-event-venue-organiser/delete-event-venue-organiser`,
        id
      )

      if (responseData.response_code === 200) {
        // console.log("OrgansierDetails user", responseData)
        dispatch(setOrganiserSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchOrganiser = data => {
  // console.log("fetchOrganiser", data)
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}manage-event-venue-organiser/fetch-event-venue-organisers`,
        data
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setOrganiserList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setOrganiserList,
  setOrganiserSuccessfullyAdded,
  resetOrganiser,
  setUserEdit,
} = OrganiserSlice.actions

export default OrganiserSlice.reducer
