import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  FormFeedback,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createAdmin,
  updateAdmin,
  deleteAdmin,
  activeInactiveAdmin,
  fetchAdmin,
  // editUser
} from "../../../toolkitStore/admin/AdminSlice"

import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, AdminList } from "toolkitStore/memberSlice"

function Admin(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  //image
  const { AdminList, AdminisSuccessfully } = useSelector(
    state => state.AdminReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchAdmin())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchAdmin())
    if (AdminisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [AdminisSuccessfully])

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstname",
      },
      {
        Header: "Last name",
        accessor: "lastname",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.status === 0 ? "Inactive" : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveAdmin(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: selectedData?.firstname || "",
      lastname: selectedData?.lastname || "",
      password: selectedData?.password || "",
      user_name: selectedData?.user_name || "",
      role: selectedData?.role || "",
    },

    validationSchema: Yup.object({
      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("Last name is required"),
      user_name: Yup.string().required("User name is required"),
      password: Yup.string()
        .required("Please Enter your new password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      role: Yup.string().required("Select Your State"),
    }),
    onSubmit: values => {
      // console.log(values)
      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        dispatch(updateAdmin(values))
      } else {
        dispatch(createAdmin(values))
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Admin Management" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center border-1 border-bottom ">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    Admin Users
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <Link
                      to="#!"
                      onClick={() => {
                        toggleRightCanvas()
                        SetSelectData()
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add New Admin
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={AdminList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {!selectedData ? "Add User" : "Edit User"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="p-3">
                  <Col>
                    <div className="mb-3 col-12">
                      <Label htmlFor="email">
                        First Name
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="firstname"
                        className={`form-control `}
                        placeholder="Enter First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstname || ""}
                        invalid={
                          validation.touched.firstname &&
                          validation.errors.firstname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstname &&
                      validation.errors.firstname ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.firstname}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col-12 mb-3">
                      <Label htmlFor="email">
                        Last Name
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="lastname"
                        className={`form-control`}
                        placeholder="Enter Last Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastname || ""}
                        invalid={
                          validation.touched.lastname &&
                          validation.errors.lastname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastname &&
                      validation.errors.lastname ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col-12 mb-3">
                      <Label htmlFor="user_name">
                        User Name
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="user_name"
                        className={`form-control`}
                        placeholder="Enter user name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.user_name || ""}
                        invalid={
                          validation.touched.user_name &&
                          validation.errors.user_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.user_name &&
                      validation.errors.user_name ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.user_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col-12 mb-3">
                      <Label className="form-label"> Password</Label>
                      <Input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12 ">
                      <Label htmlFor="role">Role</Label>
                      <select
                        id="role"
                        name="role"
                        className={`form-select ${
                          validation.touched.role &&
                          validation.errors.role &&
                          "is-invalid"
                        }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.role}
                      >
                        <option value={""} disabled>
                          Select Role
                        </option>
                        <option value={"Super Admin"}>Super Admin</option>
                        <option value={"Admin"}>Admin</option>
                      </select>
                      {validation.touched.role && validation.errors.role ? (
                        <div
                          type="invalid"
                          className="invalid-feedback"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.role}
                        </div>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="col-12 mt-3 justify-content-around px-2 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger w-md px-5"
                      onClick={toggleRightCanvas}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>

          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want delete this user?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteAdmin(delete_data))

                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Admin
