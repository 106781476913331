import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const fetchNewsLetterApi = `${APIServerIP}newsletter/fetch-newsletter`
const createNewsLetterApi = `${APIServerIP}newsletter/create-newsletter`
const updateNewsLetterApi = `${APIServerIP}newsletter/update-newsletter`
const activeInactiveNewsLetterApi = `${APIServerIP}newsletter/active-inactive-newsletter`
const deleteNewsLetterApi = `${APIServerIP}newsletter/delete-newsletter`
const EditNewsapi = `${APIServerIP}newsletter/find-newsletter`
const fetchBulkmailApi = `${APIServerIP}newsletter/fetch-bulk-mail`
const MailDatatoServerApi = `${APIServerIP}newsletter/send-recipients`

const initialState = {
  NewsLetterList: [],
  NewsLetterisSuccessfully: false,
  EditnewsDetails: "",
  BulkMailData: [],
  Bulkmailsuccess: false,
  mailtoServer: false,
  mailloading: false,
}

const NewsLetterSlice = createSlice({
  name: "NewsLetter",
  initialState: initialState,
  reducers: {
    setNewsLetterList: (state, action) => {
      state.NewsLetterList = action.payload
    },
    setNewsLetterEdit: (state, action) => {
      state.EditnewsDetails = action.payload
      state.NewsLetterisSuccessfully = true
    },
    setMailloading: state => {
      state.mailloading = true
    },
    setNewsLetterSuccessfullyAdded: (state, action) => {
      state.NewsLetterisSuccessfully = true
    },
    setBulkmail: (state, action) => {
      state.BulkMailData = action.payload
      state.Bulkmailsuccess = true
    },
    setMailtoserver: (state, action) => {
      state.mailtoServer = true
      state.mailloading = false
    },
    resetNewsLetter: (state, action) => {
      state.NewsLetterisSuccessfully = false
      state.Bulkmailsuccess = false
      state.isUserEdit = false
      state.mailtoServer = false
      state.mailloading = false
    },
  },
})

export const createNewsLetter = values => {
  return async dispatch => {
    dispatch(resetNewsLetter())
    try {
      const responseData = await post(createNewsLetterApi, values)
      // console.log("NewsLetter user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setNewsLetterSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateNewsLetter = values => {
  return async dispatch => {
    dispatch(resetNewsLetter())
    try {
      const responseData = await post(updateNewsLetterApi, values)
      // console.log("NewsLetter user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setNewsLetterSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteNewsLetter = id => {
  return async dispatch => {
    dispatch(resetNewsLetter())
    try {
      const responseData = await post(deleteNewsLetterApi, id)

      if (responseData.response_code === 200) {
        // console.log("NewsLetter user", responseData)
        dispatch(setNewsLetterSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveNewsLetter = data => {
  return async dispatch => {
    dispatch(resetNewsLetter())
    try {
      const responseData = await post(activeInactiveNewsLetterApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setNewsLetterSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const EditNewsdata = data => {
  return async dispatch => {
    dispatch(resetNewsLetter())
    try {
      const responseData = await post(EditNewsapi, data)

      if (responseData.response_code === 200) {
        // console.log("EditNewsdata", responseData.data)
        dispatch(setNewsLetterEdit(responseData.data))
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const fetchNewsLetter = () => {
  // console.log(id)
  return async dispatch => {
    dispatch(resetNewsLetter())
    try {
      let responseData = await get(fetchNewsLetterApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        // console.log(responseData)
        dispatch(setNewsLetterList(responseData.data))
        dispatch(resetNewsLetter())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const fetchBulkmail = data => {
  // console.log(id)
  return async dispatch => {
    dispatch(resetNewsLetter())
    try {
      let responseData = await post(fetchBulkmailApi, data)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        // console.log(responseData)
        dispatch(setBulkmail(responseData.data))
        // dispatch(resetNewsLetter())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const MailDatatoServer = data => {
  // console.log(id)
  return async dispatch => {
    dispatch(resetNewsLetter())
    dispatch(setMailloading())
    try {
      let responseData = await post(MailDatatoServerApi, { recipients: data })
      // console.log("mailtoserver", responseData)
      if (responseData.response_code === 200) {
        // console.log(responseData.data)
        dispatch(setMailtoserver())
        // dispatch(resetNewsLetter())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setNewsLetterList,
  setNewsLetterSuccessfullyAdded,
  setNewsLetterEdit,
  resetNewsLetter,
  setMailtoserver,
  setBulkmail,
  setMailloading,
} = NewsLetterSlice.actions

export default NewsLetterSlice.reducer
