import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  FormFeedback,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// ck editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import {
  createNewsLetter,
  updateNewsLetter,
  deleteNewsLetter,
  activeInactiveNewsLetter,
  fetchNewsLetter,
  // editUser
} from "../../../toolkitStore/NewsSlice/NewsLetterSlice"

import { useFormik } from "formik"
import * as Yup from "yup"
import { Publish } from "@mui/icons-material"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, NewsLetterList } from "toolkitStore/memberSlice"

function SendNewsletter(props) {
  const nav = useNavigate()
  const dispatch = useDispatch()

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    validation.setFieldValue("mail_content", data)
  }
  //image
  const { NewsLetterList, NewsLetterisSuccessfully } = useSelector(
    state => state.NewsLetterReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchNewsLetter())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchNewsLetter())
    if (NewsLetterisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [NewsLetterisSuccessfully])
  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }
  const columns = useMemo(
    () => [
      {
        Header: "Subject",
        accessor: "job_name",
      },
      {
        Header: "Mail to",
        accessor: "bulk_mail",
      },

      {
        Header: "status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.status === 0 ? "Inactive" : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="/newsform"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    // SetSelectData(SData)
                    localStorage.setItem("publishnewsletter", SData._id)
                    nav("/newsform")
                    nav
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveNewsLetter(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
              <li>
                <Link
                  to="/publishpage"
                  className="btn btn-sm btn-soft-success"
                  onClick={e => {
                    const SData = cellProps.row.original
                    // SetSelectData(SData)
                    localStorage.setItem("publishnewsletter", SData._id)
                    nav("/publishpage")
                    // return false
                  }}
                >
                  <span id="publishtooltip">Publish</span>

                  <UncontrolledTooltip
                    placement="top"
                    target={"publishtooltip"}
                  >
                    Publish
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      job_name: selectedData?.job_name || "",
      mailto: selectedData?.mailto || "",
      bulk_mail: selectedData?.bulk_mail || "",
      mail_content: selectedData?.mail_content || "",
    },
    validationSchema: Yup.object({
      job_name: Yup.string().required("job name is required"),
      bulk_mail: Yup.string().required("Select Mail Option"),
      mail_content: Yup.string().required("mail Content is required"),
    }),
    onSubmit: values => {
      // console.log(values)
      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        dispatch(updateNewsLetter(values))
      } else {
        dispatch(createNewsLetter(values))
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Newsletter Management" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    Newsletter Jobs
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <Link
                      to="/newsform"
                      onClick={e => {
                        // e.preventDefault()
                        /*  setPanel("mainservice")
                        toggleRightCanvas() */
                        // toggleRightCanvas()
                        // SetSelectData()
                        localStorage.removeItem("publishnewsletter")
                        nav("/newsform")
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add Newsletter Job
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={NewsLetterList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader toggle={toggleRightCanvas}>
              {selectedData?._id
                ? "Update Newsletter Job"
                : "Add Newsletter Job"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row>
                  <Col>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="job_name">Job Name (Mail Subject)</Label>
                      <Input
                        name="job_name"
                        className="form-control"
                        placeholder="Enter Subject"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.job_name || ""}
                        invalid={
                          validation.touched.job_name &&
                          validation.errors.job_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.job_name &&
                      validation.errors.job_name ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.job_name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12 ">
                      <Label htmlFor="bulk_mail">Bulk to</Label>
                      <select
                        id="bulk_mail"
                        name="bulk_mail"
                        className={`form-select ${
                          validation.touched.bulk_mail &&
                          validation.errors.bulk_mail &&
                          "is-invalid"
                        }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bulk_mail}
                      >
                        <option value={""}>Select Mail to</option>
                        <option value={"Individual"}>Individual</option>
                        <option value={"All members"}>All members</option>
                        <option value={"All Subscribers"}>
                          All Subscribers
                        </option>
                        <option value={"All members & Subscribers"}>
                          All members & Subscribers
                        </option>
                      </select>
                      {validation.touched.bulk_mail &&
                      validation.errors.bulk_mail ? (
                        <div
                          type="invalid"
                          className="invalid-feedback"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.bulk_mail}
                        </div>
                      ) : null}
                    </div>
                    {validation.values.bulk_mail === "Individual" && (
                      <div className="mb-3 col-12  ">
                        <Label htmlFor="mailto">Send to</Label>
                        <Input
                          name="mailto"
                          className="form-control"
                          placeholder="Enter Mail Id"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mailto || ""}
                          invalid={
                            validation.touched.mailto &&
                            validation.errors.mailto
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mailto &&
                        validation.errors.mailto ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.mailto}
                          </FormFeedback>
                        ) : null}
                      </div>
                    )}
                  </Col>
                  <div className="mb-3 col-12 ">
                    <Label htmlFor="mail_content">Mail Content</Label>
                    <CKEditor
                      config={{
                        placeholder: "Newsletter Content",
                        ckfinder: {
                          uploadUrl:
                            "/path/to/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images",
                        },
                      }}
                      editor={ClassicEditor}
                      data={validation.values.mail_content}
                      onChange={handleCkeditorIn}
                    />
                  </div>

                  <Col className=" mt-3 justify-content-around px-2 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-light w-md px-5"
                      onClick={toggleRightCanvas}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Newsletter ?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteNewsLetter(delete_data))

                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SendNewsletter
