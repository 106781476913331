import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"
import moment from "moment"
//actions
import { getEarningChartsData } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const Earning = ({ dataColors, DonateList }) => {
  const apexearningChartColors = getChartColorsArray(dataColors)

  const [selectMonth, setSelectMonth] = useState(moment().format("MMM"))
  const [TotalAmountArray, setTotalAmountArray] = useState()
  const [TotalMonthArray, setTotalMonthArray] = useState()
  const [AmountTotalperMonth, setTotalAmountperMonth] = useState()
  const [LastMonthAmount, setLastMonthAmount] = useState()
  const [totalAmount, setTotalAmount] = useState()

  useEffect(() => {
    const AmountArray = DonateList.map((item, ind) => {
      const currentyear = moment(item?.createdAt).format("MM YYYY")
      const year = moment().format("YYYY")
      const year123 = moment(`${selectMonth} ${year}`, "MMM YYYY").format(
        "MM YYYY"
      )
      if (currentyear === year123) {
        if (item.donate_amount) {
          return parseFloat(item.donate_amount)
        } else {
          return 0
        }
      } else {
        return 0
      }
    })

    const datefoionrmonth = DonateList.map((item, ind) => {
      const currentyear = moment(item?.createdAt).format("MM YYYY")
      const year = moment().format("YYYY")
      const year123 = moment(`${selectMonth} ${year}`, "MMM YYYY").format(
        "MM YYYY"
      )
      if (currentyear === year123) {
        if (item.donate_amount) {
          return moment(item.updatedAt).format("DD/MM")
        } else {
          return 0
        }
      } else {
        return 0
      }
    })

    const PreviousAmountArray = DonateList.map((item, ind) => {
      const currentyear = moment(item?.createdAt).format("MM YYYY")
      const year = moment().format("YYYY")
      const year123 = moment(`${selectMonth} ${year}`, "MMM YYYY").format(
        "MM YYYY"
      )
      const Previousmonth = moment(year123, "MM YYYY").format("MM")

      const year1234 = moment(`${Previousmonth - 1} ${year}`, "MM YYYY").format(
        "MM YYYY"
      )
      // console.log(year1234)
      if (currentyear === year1234) {
        if (item.donate_amount) {
          return parseFloat(item.donate_amount)
        } else {
          return 0
        }
      } else {
        return 0
      }
    })

    const donateAmount = AmountArray.reduce((acc, cur) => acc + cur, 0)
    const previousAmount = PreviousAmountArray.reduce(
      (acc, cur) => acc + cur,
      0
    )

    const totalDonateAmount = DonateList.map((item, ind) =>
      item.donate_amount ? parseFloat(item.donate_amount) : 0
    ).reduce((acc, cur) => acc + cur, 0)
    // console.log("amount", totalDonateAmount)

    setTotalAmount(totalDonateAmount)
    setTotalAmountArray(AmountArray)
    setTotalMonthArray(datefoionrmonth)
    setTotalAmountperMonth(donateAmount)
    setLastMonthAmount(previousAmount)
  }, [DonateList])

  const options = {
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },

    dataLabels: {
      enabled: !1,
    },
    colors: apexearningChartColors,
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      categories: TotalMonthArray,
      labels: {
        trim: false,
        rotate: 0,
        offsetX: 0, // Adjust this value to control the spacing between labels
        style: {
          fontSize: "12px", // Adjust the font size if needed
        },
      },
    },
  }

  const series = [
    {
      name: "Amount",
      data: TotalAmountArray,
    },
  ]

  /*
  call api action to receive data
  */

  const onChangeMonth = value => {
    setSelectMonth(value)
    // console.log(DonateList)
    const AmountArray = DonateList?.map((item, ind) => {
      const currentyear = moment(item?.createdAt).format("MM YYYY")
      const year = moment().format("YYYY")
      const year123 = moment(`${value} ${year}`, "MMM YYYY").format("MM YYYY")
      if (currentyear === year123) {
        if (item?.donate_amount) {
          return parseFloat(item.donate_amount)
        } else {
          return 0
        }
      } else {
        return 0
      }
    })
    const MonthArray = DonateList?.map((item, ind) => {
      const currentyear = moment(item?.createdAt).format("MM YYYY")
      const year = moment().format("YYYY")
      const year123 = moment(`${value} ${year}`, "MMM YYYY").format("MM YYYY")
      if (currentyear === year123) {
        if (item?.donate_amount) {
          return moment(item.updatedAt).format("DD/MM")
        } else {
          return 0
        }
      } else {
        return 0
      }
    })

    const PreviousAmountArray = DonateList.map((item, ind) => {
      const currentyear = moment(item?.createdAt).format("MM YYYY")
      const year = moment().format("YYYY")
      const year123 = moment(`${value} ${year}`, "MMM YYYY").format("MM YYYY")
      const Previousmonth = moment(year123, "MM YYYY").format("MM")

      const year1234 = moment(`${Previousmonth - 1} ${year}`, "MM YYYY").format(
        "MM YYYY"
      )
      // console.log(year1234)
      if (currentyear === year1234) {
        if (item.donate_amount) {
          return parseFloat(item.donate_amount)
        } else {
          return 0
        }
      } else {
        return 0
      }
    })

    const donateAmount = AmountArray.reduce((acc, cur) => acc + cur, 0)
    const previousAmount = PreviousAmountArray.reduce(
      (acc, cur) => acc + cur,
      0
    )

    setTotalAmountArray(AmountArray)
    setTotalMonthArray(MonthArray)
    setTotalAmountperMonth(donateAmount)
    setLastMonthAmount(previousAmount)
    // console.log("df", MonthArray, "df", AmountArray)
  }

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={selectMonth}
                    onChange={e => {
                      onChangeMonth(e.target.value)
                    }}
                  >
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="Jun">Jun</option>
                    <option value="Jul">Jul</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                  </select>
                  <label className="input-group-text">Month</label>
                </div>
              </div>
              <h4 className="card-title mb-4">Donate</h4>
            </div>

            <Row>
              <Col lg="2">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>This month</p>
                    <h4>${AmountTotalperMonth}</h4>
                  </div>

                  <div className="mt-4">
                    <p className="mb-2">Last month</p>
                    <h5>${LastMonthAmount}</h5>
                  </div>
                  <div className="mt-4">
                    <p className="mb-2">Total Amount</p>
                    <h5>${totalAmount}</h5>
                  </div>
                </div>
              </Col>

              <Col lg="10">
                <div
                  id="line-chart"
                  // style={{ width: "100%", overflowX: "auto" }}
                  dir="ltr"
                >
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Earning
