import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../helpers/api_helper"
import { APIServerIP } from "../../src/helpers/Sats_api_helper"

const fetchYouthWingApi = `${APIServerIP}sats-youth-wing/fetch-youth-wing`
const updateYouthWingApi = `${APIServerIP}sats-youth-wing/update-youth-wing-user`
const activeInactiveYouthWingApi = `${APIServerIP}sats-youth-wing/active-inactive-youth-wing-user`
const deleteYouthWingApi = `${APIServerIP}sats-youth-wing/delete-youth-wing-user`

const initialState = {
  YouthWingList: [],
  YouthWingisSuccessfully: false,
}

const YouthWingSlice = createSlice({
  name: "YouthWing",
  initialState: initialState,
  reducers: {
    setYouthWingList: (state, action) => {
      state.YouthWingList = action.payload
    },
    setYouthWingSuccessfullyAdded: (state, action) => {
      state.YouthWingisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetYouthWing: (state, action) => {
      state.YouthWingisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const updateYouthWing = values => {
  return async dispatch => {
    dispatch(resetYouthWing())
    try {
      const responseData = await post(updateYouthWingApi, values)

      if (responseData.response_code === 200) {
        // console.log("YouthWing user", responseData)
        dispatch(setYouthWingSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteYouthWing = id => {
  return async dispatch => {
    dispatch(resetYouthWing())
    try {
      const responseData = await post(deleteYouthWingApi, id)

      if (responseData.response_code === 200) {
        // console.log("YouthWing user", responseData)
        dispatch(setYouthWingSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveYouthWing = data => {
  return async dispatch => {
    dispatch(resetYouthWing())
    try {
      const responseData = await post(activeInactiveYouthWingApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setYouthWingSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const fetchYouthWing = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchYouthWingApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setYouthWingList(responseData.data))
        dispatch(resetYouthWing())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setYouthWingList,
  setYouthWingSuccessfullyAdded,
  setUserEdit,
  resetYouthWing,
} = YouthWingSlice.actions

export default YouthWingSlice.reducer
