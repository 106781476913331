import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  Table,
  FormFeedback,
  Container,
} from "reactstrap"
import { profile_Image_Api } from "helpers/Sats_api_helper"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { APIServerIP } from "helpers/Sats_api_helper"

import {
  createExecutive,
  updateExecutive,
  deleteExecutive,
  activeInactiveExecutive,
  fetchExecutive,
  fetchPervious,
  // editUser
} from "../../../toolkitStore/CommitteeSlice/ExecutiveSlice"
import ExportExcel from "components/Excelconvert/ExportExcel"

import { useFormik } from "formik"
import * as Yup from "yup"
import { data } from "autoprefixer"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, ExecutiveList } from "toolkitStore/memberSlice"

function Committee(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [imagec, SetImage] = useState()
  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState(false)
  const [dataShow, SetDataShow] = useState()
  const [sq3, setsq3] = useState(true)
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    // console.log("is Right", isRight)
    setIsRight(!isRight)
  }
  const Categorylist = [
    { category: "The Signing Officers", category_id: 1 },
    { category: "Executive Committee Members", category_id: 2 },
    { category: "Board Of Trustees", category_id: 3 },
    { category: "Honorary Executive Committee Members", category_id: 4 },
  ]
  //image
  const { PerviousList, ExecutiveList, ExecutiveisSuccessfully } = useSelector(
    state => state.ExecutiveReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchPervious())
    dispatch(fetchExecutive())
    // SetDataShow(ExecutiveList)
    // console.log("hii")
  }, [])

  useEffect(() => {
    dispatch(fetchExecutive())
    if (ExecutiveisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [ExecutiveisSuccessfully])

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }
  const columnspervious = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "firstname",
      },
      {
        Header: "Designation",
        accessor: "designation",
      },
      {
        Header: "Member Category",
        accessor: "member_category",
      },
      {
        Header: "Contact No",
        accessor: "contact_no",
      },
      {
        Header: "Year End",
        accessor: "year_end",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.status === 0 ? "Inactive" : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    toggleRightCanvas()
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })

                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveExecutive(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "firstname",
        Cell: cellProps => {
          return (
            <div className="fw-bold">
              {cellProps.row.original.firstname +
                " " +
                cellProps.row.original.lastname}
            </div>
          )
        },
      },
      {
        Header: "Designation",
        accessor: "designation",
      },
      {
        Header: "Member Category",
        accessor: "member_category",
      },
      {
        Header: "Contact No",
        accessor: "contact_no",
      },
      {
        Header: "Profile Image",
        accessor: "year_end",
        Cell: cellProps => {
          let { image_name, status, year_end } = cellProps.row.original
          return (
            <img
              src={`${APIServerIP}uploads/${image_name}`}
              style={{
                width: "100px",
                objectFit: "contain",
                borderRadius: 5,
              }}
            ></img>
          )
        },
      },
      {
        Header: "status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.status === 0 ? "Inactive" : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    // console.log("sdata -->", SData)
                    SetSelectData(SData)
                    toggleRightCanvas()
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })

                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2 `}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveExecutive(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: selectedData?.firstname || "",
      lastname: selectedData?.lastname || "",
      designation: selectedData?.designation || "",
      image_name: selectedData?.image_name || "",
      contact_no: selectedData?.contact_no || "",
      admin_status: selectedData?.admin_status || "",
      member_category: selectedData?.member_category || "",
      member_category_id: selectedData?.member_category_id || "", //1,2,3,4
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("Last name is required"),

      image_name: Yup.string().required("A photo is required"),

      member_category: Yup.string().required("Select  Category"),
      designation: Yup.string().required("Enter  Designation"),
      contact_no: Yup.string()
        .matches(/^[0-9]+$/, "Must be only Numbers")
        .required("Contact number is required"),
    }),
    onSubmit: (values, actions) => {
      values["_id"] = selectedData?._id
      dispatch(updateExecutive(values, imagec))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Committee Management" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center border-bottom border-secondary pb-3 ">
                  <h5 className="mb-1 card-title flex-grow-1">
                    <Link
                      to="#!"
                      onClick={() => {
                        SetDataShow(!dataShow)
                      }}
                      className="btn btn-info me-1"
                    >
                      {!dataShow
                        ? "Show Previous Committee"
                        : "Show Current Committee"}
                    </Link>
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel
                      excelData={dataShow ? PerviousList : ExecutiveList}
                      fileName={
                        dataShow ? "Pervious Committee" : "Current Committee"
                      }
                    />
                    {!dataShow && (
                      <Link
                        to="/committee/makeasmember"
                        // onClick={() => {
                        //   toggleRightCanvas()
                        //   SetSelectData()
                        // }}
                        className="btn btn-primary me-1"
                      >
                        Add Committee Member
                      </Link>
                    )}

                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="">
                    <div className="py-3 ">
                      <h4
                        className="flex-grow-1 inline-block text-secondary"
                        style={{ marginBottom: 0 }}
                      >
                        {dataShow ? "Pervious Committee" : "Current Committee"}
                      </h4>
                    </div>
                    {dataShow ? (
                      <TableContainer
                        columns={columnspervious}
                        data={PerviousList}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={30}
                        isJobListGlobalFilter={false}
                        // className="custom-header-css"
                      />
                    ) : (
                      <TableContainer
                        columns={columns}
                        data={ExecutiveList}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={30}
                        isJobListGlobalFilter={false}
                        // className="custom-header-css"
                      />
                    )}

                    {/* <TableContainer
                columns={columns}
                data={dataShow ? PerviousList : ExecutiveList}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={30}
                isJobListGlobalFilter={false}
                className="custom-header-css"
              /> */}
                  </div>
                </Row>

                {/* <div className="d-flex justify-content-center  border-1 border-bottom ">
              
              <div className="m-3">
            <div className="square-switch" style={{ width: '200px' }}>
                            <input
                              type="checkbox"
                              id="square-switch3"
                              className="switch switch-bool"
                              defaultChecked={sq3}
                              onChange={() =>{
                                setsq3(!sq3)
                                SetDataShow(!dataShow)}
                              }
                            />
                            <label
                              htmlFor="square-switch3"
                              data-on-label="CC"
                              data-off-label="PC"
                            />
                          </div> */}
                {/* <Link
                    to="#!"
                    onClick={() => {
                   
                     SetDataShow(true)
                    }}
                    className="btn btn-outline-primary me-1"
                  >
                    pervious Committee 
                  </Link>
                  <Link
                    to="#!"
                    onClick={() => {
                      
                   
                     SetDataShow(false)
                    }}
                    className="btn btn-outline-primary me-1"
                  >
                    Current Committee 
                  </Link> 
                  
              </div>
              </div>*/}
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {selectedData?._id
                ? "Update Member Commitee Info"
                : "Add Member Commitee Info"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="p-3">
                  <Col className="col-12 mb-3 justify-content-center align-items-center">
                    <img
                      src={`${APIServerIP}assets/committee/${validation.values.image_name}`}
                      alt="mi"
                      style={{
                        width: "40%",
                      }}
                    ></img>
                  </Col>
                  <Col className="col-12 mb-3">
                    <div style={{ fontSize: 15, fontWeight: 500 }}>
                      {validation.values.firstname} {validation.values.lastname}
                    </div>
                    <div>{validation.values.contact_no}</div>
                  </Col>
                  {!dataShow && (
                    <Col>
                      <div className="mb-3 col-12  ">
                        <Label htmlFor="designation">Designation</Label>
                        <Input
                          name="designation"
                          className="form-control"
                          placeholder="Enter  Designation"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.designation || ""}
                          invalid={
                            validation.touched.designation &&
                            validation.errors.designation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.designation &&
                        validation.errors.designation ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.designation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3 col-12 ">
                        <Label htmlFor="member_category">Category</Label>
                        <select
                          id="member_category"
                          name="member_category"
                          className={`form-select ${
                            validation.touched.member_category &&
                            validation.errors.member_category &&
                            "is-invalid"
                          }`}
                          onChange={e => {
                            const V_code = Categorylist.filter(
                              country => country.category === e.target.value
                            )
                            validation.setFieldValue(
                              "member_category",
                              e.target.value
                            )
                            validation.setFieldValue(
                              "member_category_id",
                              V_code?.[0].category_id
                            )
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.member_category}
                        >
                          <option value="" disabled>
                            Select Your Committee
                          </option>
                          {Categorylist.map((item, i) => (
                            <option value={item.category} key={i}>
                              {item.category}
                            </option>
                          ))}
                        </select>
                        {validation.touched.member_category &&
                        validation.errors.member_category ? (
                          <div
                            type="invalid"
                            className="invalid-feedback"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.member_category}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  )}
                  {!dataShow && (
                    <Col className="px-2 d-flex gap-2">
                      <button
                        type="button"
                        className="btn btn-danger w-md px-5"
                        onClick={toggleRightCanvas}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary w-md px-5"
                      >
                        {selectedData?._id ? "Update" : "Submit"}
                      </button>
                    </Col>
                  )}
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>

          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want delete this Member?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteExecutive(delete_data))

                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Committee
