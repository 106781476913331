import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const initialVenueState = {
  VenueList: [],
  volunteers: [],
  TicketBookedDetails: [],
  VenueisSuccessfully: false,
  isUserEdit: false,
  scannedUser: null,
  scannedUserStatus: null,
  scannerStatusMessage: null
}

const VenueSlice = createSlice({
  name: "EventVenue",
  initialState: initialVenueState,
  reducers: {
    setVenueList: (state, action) => {
      state.VenueList = action.payload
    },
    setVenueSuccessfullyAdded: (state, action) => {
      state.VenueisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    setvolunteerList: (state, action) => {
      state.volunteers = action.payload
    },
    setTicketBookedList: (state, action) => {
      state.TicketBookedDetails = action.payload
      state.VenueisSuccessfully = true
    },
    resetVenue: (state, action) => {
      state.VenueisSuccessfully = false
      state.isUserEdit = false
    },
    ticketScanned: (state, action) => {
      state.scannedUser = action.payload.data
      state.scannedUserStatus = action.payload.message_code
      state.scannerStatusMessage = action.payload.message
    },
    resetScannedData: (state, action) => {
      state.scannedUser = null
      state.scannedUserStatus = null
      state.scannerStatusMessage = null
    },
  },
})

export const createVenue = values => {
  return async dispatch => {
    try {
      dispatch(resetVenue())
      const responseData = await post(
        `${APIServerIP}manage-event-venue/create-event-venue`,
        values
      )
      // console.log("Venue user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setVenueSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateVenue = values => {
  return async dispatch => {
    try {
      dispatch(resetVenue())
      const responseData = await post(
        `${APIServerIP}manage-event-venue/update-event-venue`,
        values
      )
      // console.log("Venue user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setVenueSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteVenue = id => {
  return async dispatch => {
    try {
      dispatch(resetVenue())
      const responseData = await post(
        `${APIServerIP}manage-event-venue/delete-event-venue`,
        id
      )

      if (responseData.response_code === 200) {
        console.log("Venue user", responseData)
        dispatch(setVenueSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveVenue = data => {
  return async dispatch => {
    try {
      dispatch(resetVenue())
      const responseData = await post(
        `${APIServerIP}manage-event-venue/SetactiveInactive-manage-event-venue`,
        data
      )

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setVenueSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchVenue = id => {
  // console.log(id)
  return async dispatch => {
    dispatch(resetVenue())
    try {
      let responseData = await post(
        `${APIServerIP}manage-event-venue/fetch-event-venues`,
        { master_event_id: id }
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setVenueList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const TicketBookedHistory = id => {
  // console.log(id)
  return async dispatch => {
    dispatch(resetVenue())
    try {
      let responseData = await post(
        `${APIServerIP}manage-booking-user/fetch-venue-ticket-booking-user`,
        { master_venue_id: id }
      )
      console.log("TicketBookedHistory", responseData)
      if (responseData.response_code === 200) {
        dispatch(setTicketBookedList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const volunteerList = data => {
  return async dispatch => {
    try {
      let responseData = await get(`${APIServerIP}`, data)
      // console.log("volunteers get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setvolunteerList(responseData.data))
      }
    } catch (err) {
      console.log("try error volunteers", err)
    }
  }
}


export const scanningData = data => {
  return async dispatch => {
    try {
      let responseData = await post(`${APIServerIP}manage-booking-user/scan-ticket`, data)
      console.log("responseData", responseData)
      // if (responseData.response_code === 200) {
      dispatch(ticketScanned(responseData))
      // }
    } catch (error) {
      console.log("error", error)
    }
  }
}

export const {
  setVenueList,
  setVenueSuccessfullyAdded,
  setUserEdit,
  setTicketBookedList,
  resetVenue,
  setvolunteerList,
  ticketScanned,
  resetScannedData
} = VenueSlice.actions

export default VenueSlice.reducer
