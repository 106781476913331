import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { useSelector, useDispatch } from "react-redux"
import {
  Alert,
  CardBody,
  Card,
  Row,
  Col,
  CardTitle,
  Badge,
  Modal,
  UncontrolledTooltip,
  Button,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//import components
import TableContainer from "components/Common/TableContainer"
// slice

import { EditSeats, UpdateSeats } from "toolkitStore/SeatArrangement/SeatSlice"
import {
  fetchVenue,
  editUser,
  deleteVenue,
  activeInactiveVenue,
} from "../../../../toolkitStore/eventMSlice/VenueSlice"

function VenueList(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)

  const { VenueList, VenueisSuccessfully } = useSelector(
    state => state.VenueReducer
  )

  useEffect(() => {
    if (VenueList) {
      dispatch(fetchVenue(event_id_c.EventId))
    }
  }, [setmodal_backdrop, VenueisSuccessfully])

  useEffect(() => {
    localStorage.removeItem("venue_id")
    dispatch(fetchVenue(event_id_c.EventId))
  }, [])

  // console.log(VenueList)
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Venue",
        accessor: "venue",
        disableFilters: true,
      },
      {
        Header: "Started on",
        accessor: "start_datetime",
        disableFilters: true,
        Cell: cellProps => {
          let sd = moment
            .parseZone(cellProps.row.original.start_datetime)
            .format("YYYY-MM-DD  hh:mm A")
          // let sd = moment(cellProps.row.original.start_datetime)
          //   .utc(cellProps.row.original.start_datetime)
          //   .tz("America/Chicago")
          //   .format("LLLL")
          return (
            <ul className="list-unstyled">
              <p>{sd}</p>
            </ul>
          )
        },
      },
      {
        Header: "Ends on",
        accessor: "end_datetime",
        disableFilters: true,
        Cell: cellProps => {
          let sd = moment(cellProps.row.original.end_datetime).format(
            "YYYY-MM-DD  hh:mm A"
          )

          return (
            <ul className="list-unstyled">
              <p>{sd}</p>
            </ul>
          )
        },
      },

      {
        Header: "Max tickets",
        accessor: "max_tickets",
        disableFilters: true,
      },
      {
        Header: "Sold",
        accessor: "ticket_sold",
        disableFilters: true,
        Cell: cellProps => (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
              <div className="me-2">{cellProps.row.original.ticket_sold}</div>
            </li>
            <li>
              <Link
                to="/bookinghistory"
                className="btn btn-sm btn-info"
                onClick={e => {
                  const SData = cellProps.row.original
                  const PassingData = {
                    VenueName: SData.venue,
                    VenueId: SData._id,
                    MaxTicket: SData.max_tickets,
                  }
                  localStorage.setItem("venue_id", JSON.stringify(PassingData))
                }}
              >
                Details
              </Link>
            </li>
          </ul>
        ),
      },

      {
        Header: "Remaining",
        accessor: "remaining_tickets",
        disableFilters: true,
      },

      {
        Header: "Organizers",
        disableFilters: true,
        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <ul className="list-unstyled hstack  gap-3 mb-0">
              <li>
                <Link
                  to="/organiserlist"
                  className="btn btn-sm btn-primary"
                  style={new Date(SData.end_datetime) < new Date() ? {pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    const PassingData = {
                      VenueName: SData.venue,
                      VenueId: SData._id,
                    }
                    localStorage.setItem(
                      "venue_id",
                      JSON.stringify(PassingData)
                    )
                  }}
                >
                  Add Organizers
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/volunteerlist"
                  className="btn btn-sm btn-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    const PassingData = {
                      VenueName: SData.venue,
                      VenueId: SData._id,
                    }
                    localStorage.setItem(
                      "venue_id",
                      JSON.stringify(PassingData)
                    )
                  }}
                >
                  Volunteer List
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/seatmap"
                  className="btn btn-sm btn-warning"
                  onClick={e => {
                    const SData = cellProps.row.original
                    const PassingData = {
                      VenueName: SData.venue,
                      VenueId: SData._id,
                      MaxTicket: SData.max_tickets,
                    }
                    localStorage.setItem(
                      "venue_id",
                      JSON.stringify(PassingData)
                    )
                    // dispatch(EditSeats(PassingData))
                  }}
                >
                  Seat Arrangement
                </Link>
              </li> */}
            </ul>
          )
        },
      },
      {
        Header: "Tickets",
        accessor: "",
        disableFilters: true,
        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="/seatallocationlist"
                  className="btn btn-sm btn-secondary"
                  style={new Date(SData.end_datetime) < new Date() ? {pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    const PassingData = {
                      VenueName: SData.venue,
                      VenueId: SData._id,
                      MaxTicket: SData.max_tickets,
                    }
                    localStorage.setItem(
                      "venue_id",
                      JSON.stringify(PassingData)
                    )
                  }}
                >
                  Tickets Allocation
                </Link>
              </li>
            </ul>
          )
        },
      },
      {
        Header: "Action",
        disableFilters: true,

        Cell: cellProps => {
          const SData = cellProps.row.original
          let sdbtncolor = SData.active_status === "Active"
              ? "btn-soft-danger"
              : "btn-soft-success"
          // console.log(sdbtncolor)
          return (
            <ul className="list-unstyled hstack  gap-1 mb-0">
              <li>
                <Link
                  to="/venue"
                  className="btn btn-sm btn-soft-info"
                  style={new Date(SData.end_datetime) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    const PassingData = {
                      VenueName: SData.venue,
                      VenueId: SData._id,
                      MaxTicket: SData.max_tickets,
                    }
                    localStorage.setItem(
                      "venue_id",
                      JSON.stringify(PassingData)
                    )
                    dispatch(editUser(SData))
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  style={new Date(SData.end_datetime) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  style={new Date(SData.end_datetime) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      VenueId: SData._id,
                      status: SData.active_status,
                    }

                    dispatch(activeInactiveVenue(PassingData))
                    dispatch(fetchVenue(event_id_c.EventId))
                  }}
                >
                  {cellProps.row.original.active_status === "Active"
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

//   {
//     Header: "Action",
//     disableFilters: true,

//     Cell: cellProps => {
//       let sdbtncolor =
//         cellProps.row.original.active_status === "Active"
//           ? "btn-soft-danger"
//           : "btn-soft-success";

//       // Check if end_datetime has passed
//       const isEventFinished = moment(cellProps.row.original.end_datetime).isBefore(moment());

//       // If the event has finished, don't render action buttons
//       if (isEventFinished) {
//         return null;
//       }

//       return (
//         <ul className="list-unstyled hstack gap-1 mb-0">
//           <li>
//             <Link
//               to="/venue"
//               className="btn btn-sm btn-soft-info"
//               onClick={e => {
//                 const SData = cellProps.row.original;
//                 const PassingData = {
//                   VenueName: SData.venue,
//                   VenueId: SData._id,
//                   MaxTicket: SData.max_tickets,
//                 };
//                 localStorage.setItem("venue_id", JSON.stringify(PassingData));
//                 dispatch(editUser(SData));
//                 return false;
//               }}
//             >
//               <i className="mdi mdi-pencil-outline" id="edittooltip" />
//               <UncontrolledTooltip placement="top" target="edittooltip">
//                 Edit
//               </UncontrolledTooltip>
//             </Link>
//           </li>
//           <li>
//             <Link
//               to="#"
//               className="btn btn-sm btn-soft-danger"
//               onClick={e => {
//                 e.preventDefault();
//                 const SData = cellProps.row.original;
//                 tog_backdrop();
//                 SetDelete_data({ _id: SData._id });
//                 return false;
//               }}
//             >
//               <i className="mdi mdi-delete-outline" id="deletetooltip" />
//               <UncontrolledTooltip placement="top" target="deletetooltip">
//                 Delete
//               </UncontrolledTooltip>
//             </Link>
//           </li>
//           <li>
//             <Link
//               to="#!"
//               className={`badge btn ${sdbtncolor} p-2`}
//               id="active"
//               onClick={e => {
//                 e.preventDefault();
//                 const SData = cellProps.row.original;
//                 let PassingData = {
//                   VenueId: SData._id,
//                   status: SData.active_status,
//                 };
//                 dispatch(activeInactiveVenue(PassingData));
//                 dispatch(fetchVenue(event_id_c.EventId));
//               }}
//             >
//               {cellProps.row.original.active_status === "Active"
//                 ? "Set Inactive"
//                 : "Set Active"}
//             </Link>
//           </li>
//         </ul>
//       );
//     },
//   },
// ],
// []
// );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Venues" breadcrumbItem="Venues" />
          <Row className="justify-content-center">
            <Card>
              <CardBody className="border-botton">
                <div className="d-flex align-items-center ">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    {event_id_c.EventName}
                  </h5>
                  <div className="mb-1 flex-shrink-0">
                    {/* <ExportExcel
                      excelData={VenueList}
                      fileName={event_id_c.EventName}
                    /> */}
                    <Link
                      to="#"
                      className="btn btn-primary me-1"
                      onClick={e => {
                        e.preventDefault()
                        navigate("/venue")
                      }}
                    >
                      Add Venue
                    </Link>
                    <Link
                      to="#!"
                      className="btn btn-secondary me-1"
                      onClick={e => {
                        e.preventDefault()
                        navigate("/eventlist")
                      }}
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(fetchVenue(event_id_c.EventId))
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={VenueList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={20}
                      isJobListGlobalFilter={false}
                      isColumnFilterEnabled={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
                {VenueList.length > 10 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>
          {/*delete Modal */}
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Are you sure, you want to delete?</p>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={() => {
                  dispatch(deleteVenue(delete_data))
                  setmodal_backdrop(false)
                  // dispatch(fetchVenue(event_id_c.EventId))
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VenueList
