import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { useSelector, useDispatch } from "react-redux"
import {
  CardBody,
  Card,
  Row,
  Col,
  Modal,
  UncontrolledTooltip,
  Button,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//import components
import TableContainer from "components/Common/TableContainer"

// slice
import {
  resetEvent,
  editUser,
  fetchEvent,
  deleteEvent,
  activeInactiveEvent,
  PublishEvent,
} from "../../../toolkitStore/eventMSlice/eventSlice"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

function EventList(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [publish_data, SetPublish_data] = useState()
  const [modalFor, SetmodalFor] = useState()

  const { newEventList, isSuccessfullyAdded } = useSelector(
    state => state.EventReducer
  )

  useEffect(() => {
    if (isSuccessfullyAdded) {
      dispatch(fetchEvent())
    }
    dispatch(resetEvent())
  }, [modal_backdrop, isSuccessfullyAdded])
  useEffect(() => {
    localStorage.removeItem("event_id")
    localStorage.removeItem("venue_id")
    dispatch(fetchEvent())
  }, [])

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
    SetPublish_data()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Event Name",
        accessor: "name" || "event_name",
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="" style={{ fontWeight: 600 }}>
              {cellProps.row.original.name}
            </div>
          )
        },
      },
      {
        Header: "Event Date",
        accessor: "start_date",
        filterable: false,
        Cell: cellProps => {
          let sd = moment
            .parseZone(cellProps.row.original.start_date)
            .format("LLLL")
          // let sd = moment(cellProps.row.original.start_datetime)
          //   .utc(cellProps.row.original.start_datetime)
          //   .tz("America/Chicago")
          //   .format("LLLL")
          return <div>{sd}</div>
        },
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Event For",
        accessor: "event_for",
      },
      {
        Header: "Number of Days",
        accessor: "no_of_days",
      },
      {
        Header: "Venue",
        filter: false,
        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <Link
              to="/venuelist"
              className="btn btn-sm btn-primary"
              onClick={e => {
                let PassingData = {
                  EventName: SData.name,
                  EventId: SData._id,
                  EventDay: SData.no_of_days,
                }
                // console.log(PassingData)

                localStorage.setItem("event_id", JSON.stringify(PassingData))
              }}
              id="venuetooltip"
            >
              Venue
            </Link>
          )
        },
      },

      // {
      //   Header: "Status",
      //   accessor: "active_status",
      //   filter: false,
      //   Cell: cellProps => {
      //     const SData = cellProps.row.original
      //     var AIA = SData.active_status === "Active" ? "success" : "danger"
      //     return (
      //       <ul className="list-unstyled hstack d-flex justify-content-center mb-0">
      //         <li>
      //           <div className="text-center">
      //             <span
      //               className={`badge rounded-pill badge-soft-${AIA} font-size-12 px-3`}
      //             >
      //               {SData.active_status}
      //             </span>
      //           </div>
      //         </li>
      //       </ul>
      //     )
      //   },
      // },
      {
        Header: "Publish",
        filter: false,
        Cell: cellProps => {
          const SData = cellProps.row.original
          let aInbtncolor =
            SData.active_status === "Active"
              ? "btn-soft-danger"
              : "btn-soft-success"

          let IPCbtncolor =
            SData.publish_status === "Draft"
              ? "success"
              : SData.publish_status === "Published"
              ? "danger"
              : SData.publish_status === "Canceled"
              ? "success"
              : SData.publish_status === "Republished"
              ? "danger"
              : ""

          let data =
            SData.publish_status === "Draft"
              ? "Publish"
              : SData.publish_status === "Published"
              ? "Cancel"
              : SData.publish_status === "Canceled"
              ? "Re Publish"
              : SData.publish_status === "Republished"
              ? "Cancel"
              : ""
          data = SData.active_status == 'Inactive' ? "" : data
          return (
            <Link
              to="#!"
              className={`badge bg-${IPCbtncolor} p-2`}
              id="volunteer"
              onClick={e => {
                e.preventDefault()
                tog_backdrop()
                SetPublish_data(SData)
                SetmodalFor(`${data}`)
                return false
              }}
            >
              {`${data}`}
              <UncontrolledTooltip placement="top" target="volunteer">
                Publish
              </UncontrolledTooltip>
            </Link>
          )
        },
      },
      {
        Header: "Publish Status",
        accessor: "publish_status",
        filter: false,
        Cell: cellProps => {
          const SData = cellProps.row.original
          let IPCbtncolor =
            SData.publish_status === "Draft"
              ? "warning"
              : SData.publish_status === "Published"
              ? "success"
              : SData.publish_status === "Canceled"
              ? "danger"
              : SData.publish_status === "Republished"
              ? "info"
              : ""

          // console.log(SData)
          return (
            <ul className="list-unstyled hstack d-flex justify-content-center mb-0">
              {/* {cellProps.row.original.active_status === "Active" && ( */}
              <li>
                <div className="text-center">
                  <span
                    className={`badge rounded-pill badge-soft-${IPCbtncolor} font-size-12 px-3`}
                  >
                    {cellProps.row.original.publish_status}
                  </span>
                </div>
              </li>
            </ul>
          )
        },
      },
      {
        Header: "Action",
        filter: false,
        Cell: cellProps => {
          const SData = cellProps.row.original
          const disbledClass = "disabled";
          let aInbtncolor =
            SData.active_status === "Active"
              ? "btn-soft-danger"
              : "btn-soft-success"

          let IPCbtncolor =
            SData.publish_status === "Draft"
              ? "success"
              : SData.publish_status === "Published"
              ? "danger"
              : SData.publish_status === "Canceled"
              ? "success"
              : SData.publish_status === "Republished"
              ? "danger"
              : ""

          let data =
            SData.publish_status === "Draft"
              ? "Publish"
              : SData.publish_status === "Published"
              ? "Cancel"
              : SData.publish_status === "Canceled"
              ? "RePublish"
              : SData.publish_status === "Republished"
              ? "Cancel"
              : ""

          // console.log("nj", data)

          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {/* <li>
                <Link
                  to="#!"
                  className={`badge btn ${aInbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    dispatch(activeInactiveEvent(SData))
                  }}
                >
                  {cellProps.row.original.active_status === "Active"
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li> */}
              <li>
                <Link
                  to="/eventform"
                  className="btn btn-sm btn-soft-info"
                  style={SData.active_status == 'Inactive' ? {pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    let PassingData = {
                      EventName: SData.name,
                      EventId: SData._id,
                      EventDay: SData.no_of_days,
                    }
                    // console.log(PassingData)

                    localStorage.setItem(
                      "event_id",
                      JSON.stringify(PassingData)
                    )
                    dispatch(editUser(SData))
                    // console.log(SData)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  style={SData.active_status == 'Inactive' ? {pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    e.preventDefault()
                    // console.log("delete", SData._id)
                    tog_backdrop()
                    // SetDelete_data({ _id: SData._id })
                    SetDelete_data(SData)
                    SetmodalFor("Delete")
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

//   {
//     Header: "Action",
//     filter: false,
//     Cell: cellProps => {
//       const SData = cellProps.row.original;

//       // Check if the event date has passed
//       const isEventDatePassed = moment(cellProps.row.original.start_date).isBefore(moment());

//       // If the event date has passed, don't render the action buttons
//       if (isEventDatePassed) {
//         return null;
//       }

//       // Continue rendering the action buttons if the event date is in the future
//       let aInbtncolor =
//         SData.active_status === "Active"
//           ? "btn-soft-danger"
//           : "btn-soft-success";

//       let IPCbtncolor =
//         SData.publish_status === "Draft"
//           ? "success"
//           : SData.publish_status === "Published"
//           ? "danger"
//           : SData.publish_status === "Canceled"
//           ? "success"
//           : SData.publish_status === "Republished"
//           ? "danger"
//           : "";

//       let data =
//         SData.publish_status === "Draft"
//           ? "Publish"
//           : SData.publish_status === "Published"
//           ? "Cancel"
//           : SData.publish_status === "Canceled"
//           ? "RePublish"
//           : SData.publish_status === "Republished"
//           ? "Cancel"
//           : "";

//       return (
//         <ul className="list-unstyled hstack gap-1 mb-0">
//           {/* Continue with the rest of your code for rendering action buttons */}
//         </ul>
//       );
//     },
//   },
// ],
// []
// );
//   // console.log(newEventList)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Event Management"
            breadcrumbItem="Event Management"
          />

          <Row className="justify-content-center">
            <Card>
              <CardBody className="">
                <div className="d-flex justify-content-end">
                  <div className="mb-1 flex-shrink-0">
                    <ExportExcel excelData={newEventList} fileName={"Event"} />

                    <Link
                      to="/eventform"
                      onClick={e => {
                        // console.log("New Event Open")
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add New Event
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(fetchEvent())
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={newEventList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      isShowPageSize={false}
                      isColumnFilterEnabled={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
                {newEventList.length > 7 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>

          <div>
            <Modal
              isOpen={modal_backdrop}
              toggle={tog_backdrop}
              backdrop={"static"}
              id="staticBackdrop"
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {modalFor}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={CloseandCancel}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body font-size-15">
                <p>Do you want {modalFor} this Event ?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    if (modalFor === "Delete") {
                      dispatch(deleteEvent(delete_data))
                    } else {
                      dispatch(PublishEvent(publish_data))
                    }
                    tog_backdrop()
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={CloseandCancel}
                >
                  No
                </button>
              </div>
            </Modal>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EventList
