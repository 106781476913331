import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
// import { useExcelDownloder } from "react-xls"
import ExportExcel from "components/Excelconvert/ExportExcel"

import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Modal,
  Input,
  FormFeedback,
  Container,
} from "reactstrap"
import { APIServerIP } from "helpers/Sats_api_helper"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// ck editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import {
  createBanner,
  updateBanner,
  deleteBanner,
  activeInactiveBanner,
  fetchBanner,
  // editUser
} from "../../../toolkitStore/Banner/BannerSlice"

import { useFormik } from "formik"
import * as Yup from "yup"

import { downloadExcel } from "components/Xslx"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, BannerList } from "toolkitStore/memberSlice"

function Banner(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const { ExcelDownloder, Type } = useExcelDownloder()

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [isRight, setIsRight] = useState(false)
  const [imagec, SetImage] = useState()

  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  //image
  const { BannerList, BannerisSuccessfully } = useSelector(
    state => state.BannerReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchBanner())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchBanner())
    if (BannerisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
      SetDelete_data()
    }
  }, [BannerisSuccessfully])

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "ba_title",
      },
      {
        Header: "Description",
        accessor: "ba_description",
        Cell: cellProps => {
          let { ba_description } = cellProps.row.original
          return <div dangerouslySetInnerHTML={{ __html: ba_description }} />
        },
      },

      {
        Header: "Photo",
        accessor: "ba_bannerphoto",
        Cell: cellProps => {
          let { ba_bannerphoto } = cellProps.row.original
          return (
            <ul className="list-unstyled  mb-0">
              <li>
                <img
                  src={`${APIServerIP}assets/banner/${ba_bannerphoto}`}
                  style={{
                    height: "100px",
                    width: "200px",
                    objectFit: "contain",
                  }}
                ></img>
              </li>
            </ul>
          )
        },
      },
      {
        Header: "Status",
        accessor: "ba_status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.ba_status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.ba_status === 0
                    ? "Inactive"
                    : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.ba_status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      ba_status: SData.ba_status,
                    }

                    dispatch(activeInactiveBanner(PassingData))
                  }}
                >
                  {cellProps.row.original.ba_status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )
  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    validation.setFieldValue("ba_description", data)
  }

  const toggleScrollBackDrop = () => {
    setIsScrollBackDrop(!isScrollBackDrop)
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ba_title: selectedData?.ba_title || "",
      ba_bannerphoto: selectedData?.ba_bannerphoto || "",
      // banner_link: "",
      ba_description: selectedData?.ba_description || "",
    },
    validationSchema: Yup.object({
      ba_title: Yup.string().required("Enter The Banner Title"),
      ba_bannerphoto: Yup.string().required("A photo is required"),

      // ba_bannerphoto: Yup.mixed()
      // .required("Please select a file"),
      // banner_link: Yup.string().required("Enter/Paste The Banner Link"),
      // ba_description: Yup.string().required("write the Thirukural "),
    }),
    onSubmit: values => {
      if (selectedData?._id) {
        // console.log(values, selectedData?._id)
        values["_id"] = selectedData?._id
        dispatch(updateBanner(values, imagec))
      } else {
        dispatch(createBanner(values, imagec))
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Banners Management" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    Banners
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel excelData={BannerList} fileName={"Banner"} />

                    <Link
                      to="#!"
                      onClick={() => {
                        /*  setPanel("mainservice")
                         */
                        toggleRightCanvas()
                        SetSelectData()
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add New Banner
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={BannerList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {selectedData?._id ? "Edit Banner" : "Add Banner"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="p-3">
                  <Col>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ba_bannerphoto">
                        Banner Image{" "}
                        <small>
                          (Size : Width=1600px, Height=575 )
                          <span className="text-danger">*</span>
                        </small>
                      </Label>
                      <Input
                        name="ba_bannerphoto"
                        className="form-control"
                        placeholder="Upload  Banner Image"
                        type="file"
                        onChange={e => {
                          validation.setFieldValue(
                            "ba_bannerphoto",
                            e.currentTarget.files[0].name

                            // e.currentTarget.files[0].name
                          )
                          SetImage(e.currentTarget.files[0])
                        }}
                        invalid={
                          validation.touched.ba_bannerphoto &&
                          validation.errors.ba_bannerphoto
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ba_bannerphoto &&
                      validation.errors.ba_bannerphoto ? (
                        <div
                          type="invalid"
                          className="invalid-feedback"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ba_bannerphoto}
                        </div>
                      ) : null}
                      {
                        <span className="text-info">
                          {selectedData?.ba_bannerphoto}
                        </span>
                      }
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="ba_title">Banner Title</Label>
                      <Input
                        name="ba_title"
                        className="form-control"
                        placeholder="Enter Banner Title"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ba_title || ""}
                        invalid={
                          validation.touched.ba_title &&
                          validation.errors.ba_title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ba_title &&
                      validation.errors.ba_title ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.ba_title}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* <div className="mb-3 col-12  ">
                    <Label htmlFor="banner_link">Banner Link</Label>
                    <Input
                      name="banner_link"
                      className="form-control"
                      placeholder="Paste the Banner Url"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.banner_link || ""}
                      invalid={
                        validation.touched.banner_link &&
                        validation.errors.banner_link
                          ? true
                          : false
                      }
                    />
                    {validation.touched.banner_link &&
                    validation.errors.banner_link ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.banner_link}
                      </FormFeedback>
                    ) : null}
                  </div> */}
                    <div className="mb-3 col-12 ">
                      <Label htmlFor="ba_description">Description</Label>
                      <CKEditor
                        config={{
                          placeholder: "Description....!",
                        }}
                        editor={ClassicEditor}
                        data={validation.values.ba_description}
                        onChange={handleCkeditorIn}
                      />
                    </div>
                    {validation.touched.ba_description &&
                    validation.errors.ba_description ? (
                      <div
                        className="invalid-feedback"
                        style={{ fontSize: "12px" }}
                      >
                        {validation.errors.ba_description}
                      </div>
                    ) : null}
                  </Col>

                  <Col className="d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger w-md px-5"
                      onClick={toggleRightCanvas}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Banner ?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteBanner(delete_data))

                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Banner
