import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const fetchAdminApi = `${APIServerIP}admin/fetch-admin-users`
const createAdminApi = `${APIServerIP}admin/register-admin-user`
const updateAdminApi = `${APIServerIP}admin/update-admin-user`
const activeInactiveAdminApi = `${APIServerIP}admin/active-inactive-admin-user`
const deleteAdminApi = `${APIServerIP}admin/delete-admin-user`

const initialState = {
  AdminList: [],
  AdminisSuccessfully: false,
}

const AdminSlice = createSlice({
  name: "Admin",
  initialState: initialState,
  reducers: {
    setAdminList: (state, action) => {
      state.AdminList = action.payload
    },
    setAdminSuccessfullyAdded: (state, action) => {
      state.AdminisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetAdmin: (state, action) => {
      state.AdminisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const createAdmin = values => {
  return async dispatch => {
    dispatch(resetAdmin())
    try {
      const responseData = await post(createAdminApi, values)
      // console.log("Admin user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setAdminSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateAdmin = values => {
  return async dispatch => {
    dispatch(resetAdmin())
    try {
      const responseData = await post(updateAdminApi, values)
      // console.log("Admin user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setAdminSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteAdmin = id => {
  return async dispatch => {
    dispatch(resetAdmin())
    try {
      const responseData = await post(deleteAdminApi, id)

      if (responseData.response_code === 200) {
        // console.log("Admin user", responseData)
        dispatch(setAdminSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveAdmin = data => {
  return async dispatch => {
    dispatch(resetAdmin())
    try {
      const responseData = await post(activeInactiveAdminApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setAdminSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

// export const editUser = data => {
//   return async dispatch => {
//     try {
//       localStorage.setItem("editUser", JSON.stringify(data))
//       dispatch(setUserEdit())
//     } catch (err) {
//       console.log("editUsererr", err)
//     }
//   }
// }

export const fetchAdmin = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchAdminApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setAdminList(responseData.data))
        dispatch(resetAdmin())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setAdminList,
  setAdminSuccessfullyAdded,
  setUserEdit,
  resetAdmin,
} = AdminSlice.actions

export default AdminSlice.reducer
