import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import moment from "moment"
import APIServerIP from "helpers/Sats_api_helper"
// import { CurrentComitte_img_Api } from "helpers/Sats_api_helper"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Table,
  FormFeedback,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  fetchMember,
  fetchRemainingMember,
  // editUser
} from "../../../toolkitStore/MemberMagement/MemberSlice"
import { createExecutive } from "../../../toolkitStore/CommitteeSlice/ExecutiveSlice"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

function MakeCommittee(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { RemainingMemberList } = useSelector(state => state.MemberReducer)
  const { ExecutiveisSuccessfully } = useSelector(
    state => state.ExecutiveReducer
  )
  //Delete
  const [imagec, SetImage] = useState()
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()

  //image

  const Categorylist = [
    { category: "The Signing Officers", category_id: 1 },
    { category: "Executive Committee Members", category_id: 2 },
    { category: "Board Of Trustees", category_id: 3 },
    { category: "Honorary Executive Committee Members", category_id: 4 },
  ]

  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchRemainingMember())
  }, [])
  useEffect(() => {
    // dispatch(fetchExecutive())
    if (ExecutiveisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
      navigate("/committee")
    }
  }, [ExecutiveisSuccessfully])

  //   useEffect(() => {
  //     dispatch(fetchMember())

  //   }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Member Id",
        accessor: "member_id",
      },
      {
        Header: "First Name ",
        accessor: "firstname",
      },
      {
        Header: "Last Name ",
        accessor: "lastname",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      // {
      //   Header: "Boarded ON",
      //   accessor: "boarded_on",
      //   Cell: cellProps => {
      //     let sd = moment(cellProps.row.original.boarded_on).format(
      //       "YYYY-MM-DD"
      //     )
      //     return (
      //       <ul className="list-unstyled d-block">
      //         <li>{sd}</li>
      //       </ul>
      //     )
      //   },
      // },
      {
        Header: "status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.active_status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.active_status === 0
                    ? "Inactive"
                    : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    // console.log(SData)

                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  Make As Committe Member
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      member_id: selectedData?._id || "",
      firstname: selectedData?.firstname || "",
      lastname: selectedData?.lastname || "",
      gender: selectedData?.gender || "",
      designation: "",
      image_name: selectedData?.profile_img || "",
      contact_no: selectedData?.mobile || "",
      admin_status: "Current Committee",
      member_category: "",
      member_category_id: "", //1,2,3,4
    },
    validationSchema: Yup.object({
      // firstname: Yup.string().required("First name is required"),
      // lastname: Yup.string().required("Last name is required"),

      // image_name: Yup.string().required("A photo is required"),

      member_category: Yup.string().required("Select  Category"),
      designation: Yup.string().required("Enter  Designation"),
      // contact_no: Yup.string()
      //   .matches(/^[0-9]+$/, "Must be only Numbers")
      //   .required("Contact number is required"),
    }),
    onSubmit: (values, actions) => {
      // console.log(values)

      dispatch(createExecutive(values, imagec))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Add Member to Committee" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center ">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    Committee Members
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <Link to="/committee" className="btn btn-dark me-1">
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={RemainingMemberList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              Add Committee Member
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row>
                  <Row className="p-3">
                    {validation.values.image_name && (
                      <Col className="col-12 mb-3 justify-content-center align-items-center">
                        <img
                          src={`${APIServerIP}uploads/${validation.values.image_name}`}
                          alt="img"
                          style={{
                            width: "40%",
                          }}
                        ></img>
                      </Col>
                    )}

                    <Col className="col-12 mb-3">
                      <div style={{ fontSize: 15, fontWeight: 500 }}>
                        {validation.values.firstname}{" "}
                        {validation.values.lastname}
                      </div>
                      <div>{validation.values.contact_no}</div>
                    </Col>
                    {
                      <Col>
                        <div className="mb-3 col-12  ">
                          <Label htmlFor="designation">Designation</Label>
                          <Input
                            name="designation"
                            className="form-control"
                            placeholder="Enter  Designation"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.designation || ""}
                            invalid={
                              validation.touched.designation &&
                              validation.errors.designation
                                ? true
                                : false
                            }
                          />
                          {validation.touched.designation &&
                          validation.errors.designation ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.designation}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12 ">
                          <Label htmlFor="member_category">Category</Label>
                          <select
                            id="member_category"
                            name="member_category"
                            className={`form-select ${
                              validation.touched.member_category &&
                              validation.errors.member_category &&
                              "is-invalid"
                            }`}
                            onChange={e => {
                              const V_code = Categorylist.filter(
                                country => country.category === e.target.value
                              )
                              validation.setFieldValue(
                                "member_category",
                                e.target.value
                              )
                              validation.setFieldValue(
                                "member_category_id",
                                V_code?.[0].category_id
                              )
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.member_category}
                          >
                            <option value="" disabled>
                              Select Your Committee
                            </option>
                            {Categorylist.map((item, i) => (
                              <option value={item.category} key={i}>
                                {item.category}
                              </option>
                            ))}
                          </select>
                          {validation.touched.member_category &&
                          validation.errors.member_category ? (
                            <div
                              type="invalid"
                              className="invalid-feedback"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.member_category}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    }
                    {
                      <Col className="px-2 d-flex gap-2">
                        <button
                          type="button"
                          className="btn btn-danger w-md px-5"
                          onClick={toggleRightCanvas}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary w-md px-5"
                        >
                          {selectedData?._id ? "Update" : "Submit"}
                        </button>
                      </Col>
                    }
                  </Row>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MakeCommittee
