import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"

import {
  CardBody,
  Card,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Container,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// ck editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import {
  EditNewsdata,
  fetchBulkmail,
  MailDatatoServer,
  // editUser
} from "../../../toolkitStore/NewsSlice/NewsLetterSlice"

import { useFormik } from "formik"
import * as Yup from "yup"
import { Publish } from "@mui/icons-material"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, BulkMailData } from "toolkitStore/memberSlice"

function ShowNewsletter(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //   const handleCkeditorIn = (event, editor) => {
  //     const data = editor.getData()
  //     validation.setFieldValue("mail_content", data)
  //   }
  //image
  const {
    EditnewsDetails,
    mailtoServer,
    BulkMailData,
    Bulkmailsuccess,
    resetNewsLetter,
    NewsLetterisSuccessfully,
    mailloading,
  } = useSelector(state => state.NewsLetterReducer)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [selectedRows, setSelectedRows] = useState([...BulkMailData])
  const EU = localStorage.getItem("publishnewsletter")

  useEffect(() => {
    if (mailtoServer) {
      alert("Emails sent successfully")
      navigate("/sendnewsletter")
      // dispatch(bulkmailData(EditnewsDetails?.bulk_mail))
      // dispatch(MailDatatoServer({}))
    }
  }, [mailtoServer])

  useEffect(() => {
    try {
      // console.log("Retrieved Data:", EU)
      if (EU != null) {
        dispatch(EditNewsdata({ _id: EU }))
        // dispatch(fetchBulkmail({bulk_mail:EditnewsDetails?.bulk_mail}))
      }
    } catch (error) {
      // console.error("Error parsing JSON:", error)
    }
  }, [])
  useEffect(() => {
    setSelectedRows(BulkMailData)

    if (NewsLetterisSuccessfully) {
      dispatch(
        fetchBulkmail({ _id: EU, bulk_mail: EditnewsDetails?.bulk_mail })
      )
    }
  }, [NewsLetterisSuccessfully])

  useEffect(() => {
    setSelectedRows(BulkMailData)
  }, [Bulkmailsuccess])
  const Cancel = e => {
    e.preventDefault()
    // localStorage.removeItem("publishnewsletter")
    // SetSelectData("")
    navigate("/sendnewsletter")
  }

  const columns = useMemo(
    () => [
      {
        Header: "Select",
        accessor: "_id", // Replace with a unique identifier for each row
        Cell: ({ row }) => {
          // const isSelected = selectedRows.includes(row.original.status)

          const toggleRowSelection = ID => {
            // console.log(row.original.status)
            if (row.original.status === 1) {
              const allRowIds = selectedRows.map(row => {
                if (row._id == ID._id) {
                  // console.log(row._id, ID._id)
                  return {
                    _id: ID._id,
                    firstname: ID.firstname,
                    lastname: ID.lastname,
                    email: ID.email,
                    status: 0,
                  }
                } else {
                  return row
                }
              })
              setSelectedRows(allRowIds)
            } else {
              const allRowIds = selectedRows.map(row => {
                if (row._id == ID._id) {
                  // console.log(row._id, ID._id)
                  return {
                    _id: ID._id,
                    firstname: ID.firstname,
                    lastname: ID.lastname,
                    email: ID.email,
                    status: 1,
                  }
                } else {
                  return row
                }
              })
              setSelectedRows(allRowIds)
            }
          }

          return (
            <input
              type="checkbox"
              defaultChecked={row.original.status}
              onClick={() => toggleRowSelection(row.original)}
            />
          )
        },
      },

      {
        Header: "First Name",
        accessor: "firstname",
      },
      {
        Header: "Last Name",
        accessor: "lastname",
      },
      {
        Header: "Email",
        accessor: "email",
      },

      // {
      //   Header: "status",
      //   accessor: "status",
      //   Cell: cellProps => {
      //     return (
      //       <ul className="list-unstyled">
      //         <li>
      //           {cellProps.row.original.status === 0 ? "In Active" : "Active"}
      //         </li>
      //       </ul>
      //     )
      //   },
      // },
    ],
    [selectedRows, BulkMailData]
  )

  const maildata = selectedRows.filter(item => item.status === 1)
  const [btncolortoggle, SetBtncolortoggle] = useState(true)
  const ToggleSelect = () => {
    const allRowIds = BulkMailData.map(row => {
      if (btncolortoggle) {
        return {
          _id: row._id,
          firstname: row.firstname,
          lastname: row.lastname,
          email: row.email,
          status: 1,
        }
      } else {
        return {
          _id: row._id,
          firstname: row.firstname,
          lastname: row.lastname,
          email: row.email,
          status: 0,
        }
      }
    })
    setSelectedRows(allRowIds)
    SetBtncolortoggle(!btncolortoggle)
    // Select all
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Publish Newsletter Job" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center border-secondary border-bottom pb-3">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    Job Publishing
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <Link
                      to="/sendnewsletter"
                      onClick={e => {
                        Cancel(e)
                      }}
                      className="btn btn-dark me-1"
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {}}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                {mailloading ? (
                  <div className="d-flex align-items-center border-secondary border-bottom pb-2 mb-3 pt-3">
                    <Spinner type="grow" color="info" />
                    <h2 className="fw-bold">Sending Mails</h2>
                  </div>
                ) : (
                  <div className="d-flex align-items-center border-secondary border-bottom pb-2 mb-3 pt-4">
                    <Row>
                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Mail Subject
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {EditnewsDetails?.job_name}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Mail To
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {EditnewsDetails?.bulk_mail
                            ? EditnewsDetails.bulk_mail
                            : EditnewsDetails.mailto}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Mail Content
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: EditnewsDetails?.mail_content,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                <div className="">
                  {mailloading ? (
                    <Spinner type="grow" color="warning">
                      Sending Mail
                    </Spinner>
                  ) : (
                    <Row>
                      {/* Add buttons for Select All and Deselect All */}
                      <div className="mb-3 d-flex justify-content-between">
                        <div>
                          <Button
                            onClick={() => {
                              ToggleSelect()
                            }}
                            color={btncolortoggle ? "primary" : "danger"}
                            className="mb-3"
                          >
                            {btncolortoggle ? "Select All" : "Deselect All"}
                          </Button>
                        </div>
                        <div>
                          {maildata.length > 0 && (
                            <Button
                              onClick={() => {
                                const maildata = selectedRows.filter(
                                  item => item.status === 1
                                )
                                dispatch(
                                  MailDatatoServer({
                                    EditnewsDetails,
                                    maildata,
                                  })
                                )
                              }}
                              color="success"
                              className="mb-3 flex-shrink-0 me-2"
                            >
                              Send ({maildata.length})
                            </Button>
                          )}

                          <Button
                            onClick={e => {
                              Cancel(e)
                            }}
                            color="light"
                            className="mb-3 flex-shrink-0"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>

                      <div className="container-fluid">
                        <TableContainer
                          columns={columns}
                          data={selectedRows}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={30}
                          isJobListGlobalFilter={false}
                          // className="custom-header-css"
                        />
                      </div>
                      <div className="my-5 text-end">
                        {maildata.length > 0 && (
                          <Button
                            onClick={() => {
                              const maildata = selectedRows.filter(
                                item => item.status === 1
                              )
                              dispatch(
                                MailDatatoServer({ EditnewsDetails, maildata })
                              )
                            }}
                            color="success"
                            className="mb-3 flex-shrink-0 me-2"
                          >
                            Send ({maildata.length})
                          </Button>
                        )}
                        <Button
                          onClick={e => {
                            Cancel(e)
                          }}
                          color="light"
                          className="mb-3 flex-shrink-0"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Row>
                  )}
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ShowNewsletter
