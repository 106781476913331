import React, { useRef, useState, useEffect } from "react"
/* import axios from "axios" */
import { Link, useNavigate } from "react-router-dom"
import APIServerIP from "helpers/Sats_api_helper"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import moment from "moment"
import {
  Card,
  Modal,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Col,
  Container,
  CardImg,
  CardImgOverlay,
  Form,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import classnames from "classnames"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

import { Country } from "components/country-state-city/countries"
import { State } from "components/country-state-city/states"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { editUserMember } from "../../../toolkitStore/MemberMagement/MemberSlice"

// const formData = new FormData()

function MemberDetails(props) {
  //meta title
  /* document.title =
    "Create New Project | Skote - React Admin & Dashboard Template" */
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EditUser, MemberisSuccessfully } = useSelector(
    state => state.MemberReducer
  )

  // console.log(EditUser)
  //   const handleCkeditorIn = (event, editor) => {
  //     const data = editor.getData()
  //     validation.setFieldValue("description", data)
  //   }

  document.title = "Form File Upload | Skote - React Admin & Dashboard Template"
  const { spouse, children } = EditUser

  //   const [EditUser, SetSelectData] = useState()

  //edit /update Function

  // useEffect(() => {
  //   if (MemberisSuccessfully) {
  //     navigate("/eventlist")
  //   }
  // }, [MemberisSuccessfully])

  useEffect(() => {
    const EU = JSON.parse(localStorage.getItem("editMember"))
    // console.log(EU)
    // SetSelectData()
    if (EU != "") {
      // console.log(EU)
      dispatch(editUserMember({ _id: EU }))
      //   SetSelectData(EditUser)
    }
  }, [])

  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("editMember")
    // SetSelectData("")
    navigate("/members")
  }

  return (
    <>
      <div className="page-content ">
        <Container fluid>
          <Row>
            <Card className="bg-light">
              {/* <CardTitle className=" p-3 mb-4  border-2 border-bottom border-success">
                {EditUser?._id ? "Update" : "Create"}  Member
              </CardTitle> */}
              <CardBody>
                <div className="p-3 mb-4 d-flex align-items-center border-2 border-bottom border-success ">
                  <h5 className="mb-1 card-title flex-grow-1">
                    Member Details
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <Link
                      to="/memberform"
                      onClick={e => {
                        Cancel(e)
                      }}
                      className="btn btn-dark me-1"
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-info me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
              </CardBody>

              <div
                className="col-12 d-flex flex-wrap justify-content-around  "
                style={{ position: "relative", top: "-35px" }}
              >
                <div className="col-12 ">
                  <div className="col-12  d-flex justify-content-center gap-1">
                    <Card className="col-12">
                      <CardImg
                        src={`${APIServerIP}assets/uploads/${EditUser?.profile_img}`}
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                          alignSelf: "center",
                        }}
                        className="rounded-circle m-5 border border-5 border-success"
                      ></CardImg>

                      <CardImgOverlay
                        style={{
                          top: "9.5rem",
                          left: "12rem",
                        }}
                      >
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            // setDis(false)
                            setProfileImageModal(true)
                          }}
                        >
                          {/* <BiSolidCameraPlus
                            className="text-primary fw-5 bg-light rounded-circle"
                            style={{ fontSize: "2.5rem" }}
                          /> */}
                        </button>
                      </CardImgOverlay>
                      <CardText className="d-flex px-2">
                        <Table borderless className="d-sm-block">
                          <tbody>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Member Id{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.member_id}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                First Name{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.firstname}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Last Name{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.lastname}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Email{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.email}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Date of Birth(DD-MM){" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.dob}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Gender{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.gender}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Mobile Number{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.mobile}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" className="fw-bold">
                                {null}
                              </th>
                              <td></td>
                              {/* <td className="fw-bold text-warning">
                                <button type="button" className="btn btn-info">
                                  Upgrade
                                </button>
                              </td> */}
                            </tr>
                          </tbody>
                        </Table>
                        <Table borderless className=" d-blockd-sm-block">
                          <tbody>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Address{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.address}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                City{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.city}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                State{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.state}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Country{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.country}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                Subscriber{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.subscribe}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="fw-bold">
                                MemberShip Type{" "}
                              </th>
                              <td>:</td>
                              <td className="fw-bold text-warning">
                                {EditUser?.membership_type}
                              </td>
                            </tr>
                            {EditUser?.membership_type ===
                            "Lifetime members" ? (
                              ""
                            ) : (
                              <tr>
                                <th scope="row" className="fw-bold">
                                  Expired Date{" "}
                                </th>
                                <td>: </td>
                                <td className="fw-bold text-warning">
                                  {moment(
                                    EditUser?.membership_expired_on
                                  ).format("Do MMMM YYYY")}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </CardText>
                    </Card>
                  </div>
                </div>
              </div>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MemberDetails
