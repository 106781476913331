import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../helpers/Sats_api_helper"

const initialBookingDetailsState = {
  BookingDetailsList: [],
  BookingDetailsisSuccessfully: false,
  isUserEdit: false,
}

const SeatAllocationSlice = createSlice({
  name: "EventBookingDetails",
  initialState: initialBookingDetailsState,
  reducers: {
    setBookingDetailsList: (state, action) => {
      state.BookingDetailsList = action.payload
    },
    setBookingDetailsSuccessfullyAdded: (state, action) => {
      state.BookingDetailsisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    resetBookingDetails: (state, action) => {
      state.BookingDetailsisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const createBookingDetails = values => {
  return async dispatch => {
    try {
      dispatch(resetBookingDetails())
      const responseData = await post(
        `${APIServerIP}manage-event-venue-ticket-allocation/create-event-venue-ticket-allocation`,
        values
      )
      // console.log("BookingDetails user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setBookingDetailsSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateBookingDetails = values => {
  return async dispatch => {
    try {
      dispatch(resetBookingDetails())
      const responseData = await post(
        `${APIServerIP}manage-event-venue-ticket-allocation/update-event-venue-ticket-allocation`,
        values
      )

      if (responseData.response_code === 200) {
        // console.log("BookingDetails user", responseData)
        dispatch(setBookingDetailsSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const deleteBookingDetails = id => {
  return async dispatch => {
    try {
      dispatch(resetBookingDetails())
      const responseData = await post(
        `${APIServerIP}manage-event-venue-ticket-allocation/delete-event-venue-ticket-allocation`,
        id
      )

      if (responseData.response_code === 200) {
        // console.log("BookingDetails user", responseData)
        dispatch(setBookingDetailsSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchBookingDetails = data => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}manage-event-venue-ticket-allocation/fetch-event-venue-ticket-allocations`,
        data
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setBookingDetailsList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setBookingDetailsList,
  setBookingDetailsSuccessfullyAdded,
  resetBookingDetails,
  setUserEdit,
} = SeatAllocationSlice.actions

export default SeatAllocationSlice.reducer
