import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const fetchBannerApi = `${APIServerIP}banners/fetch-banner`
const createBannerApi = `${APIServerIP}banners/create-banner`
const updateBannerApi = `${APIServerIP}banners/update-banner`
const activeInactiveBannerApi = `${APIServerIP}banners/active-inactive-banner`
const deleteBannerApi = `${APIServerIP}banners/delete-banner`

const initialState = {
  BannerList: [],
  BannerisSuccessfully: false,
}

const BannerSlice = createSlice({
  name: "Banner",
  initialState: initialState,
  reducers: {
    setBannerList: (state, action) => {
      state.BannerList = action.payload
    },
    setBannerSuccessfullyAdded: (state, action) => {
      state.BannerisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetBanner: (state, action) => {
      state.BannerisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

// export const createBanner = values => {
//   return async dispatch => {
//     try {
//       dispatch(resetBanner())
//       const responseData = await post(createBannerApi, values)
//       // console.log("Banner user", responseData)
//       if (responseData.response_code === 200) {
//         dispatch(setBannerSuccessfullyAdded())
//       }
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }
export const createBanner = (values, image) => {
  return async dispatch => {
    // console.log("values", values, image)
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))

    dispatch(resetBanner())
    await fetch(createBannerApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        console.log("res", res)
        if (res.status === 200) {
          dispatch(setBannerSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })
  }
}
// export const updateBanner = values => {
//   return async dispatch => {
//     try {
//       dispatch(resetBanner())
//       const responseData = await post(updateBannerApi, values)
//       // console.log("Banner user", responseData)
//       if (responseData.response_code === 200) {
//         dispatch(setBannerSuccessfullyAdded())
//       }
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }

export const updateBanner = (values, image) => {
  return async dispatch => {
    // console.log("Updatevalues", values, image)
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))
    dispatch(resetBanner())
    await fetch(updateBannerApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        // console.log("Updateres", res)
        if (res.status == 200) {
          dispatch(setBannerSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}

export const deleteBanner = id => {
  return async dispatch => {
    try {
      dispatch(resetBanner())
      const responseData = await post(deleteBannerApi, id)

      if (responseData.response_code === 200) {
        // console.log("Banner user", responseData)
        dispatch(setBannerSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveBanner = data => {
  return async dispatch => {
    try {
      dispatch(resetBanner())
      const responseData = await post(activeInactiveBannerApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setBannerSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

// export const editUser = data => {
//   return async dispatch => {
//     try {
//       localStorage.setItem("editUser", JSON.stringify(data))
//       dispatch(setUserEdit())
//     } catch (err) {
//       console.log("editUsererr", err)
//     }
//   }
// }

export const fetchBanner = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchBannerApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setBannerList(responseData.data))
        dispatch(resetBanner())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setBannerList,
  setBannerSuccessfullyAdded,
  setUserEdit,
  resetBanner,
} = BannerSlice.actions

export default BannerSlice.reducer
