import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  FormFeedback,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createMemberType,
  updateMemberType,
  deleteMemberType,
  activeInactiveMemberType,
  fetchMemberType,
  // editUser
} from "../../../toolkitStore/MemberMagement/MemberTypeSlice"

import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, memberTypeList } from "toolkitStore/memberSlice"

function MemberType(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isScrollBackDrop, setIsScrollBackDrop] = useState(false)

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  const Categorylist = [
    { category: "Lifetime", category_id: "0" },
    { category: "Annually", category_id: "1" },
  ]
  //image
  const { MemberTypeList, MemberTypeisSuccessfully } = useSelector(
    state => state.MemberTypeReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchMemberType())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchMemberType())
    if (MemberTypeisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [MemberTypeisSuccessfully])

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Membership Name",
        accessor: "membership_name",
      },
      {
        Header: "Membership Fee $",
        accessor: "fees",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled  mb-0">
              <li>{`$ ${cellProps.row.original.fees}`}</li>
            </ul>
          )
        },
      },

      {
        Header: "Subscription Term",
        accessor: "term_name",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.status === 0 ? "Inactive" : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveMemberType(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      membership_name: selectedData?.membership_name || "",
      fees: selectedData?.fees || "",

      term_name: selectedData?.term_name || "",
      term: selectedData?.term || "",
    },
    validationSchema: Yup.object({
      membership_name: Yup.string().required("Membership is required"),
      term_name: Yup.string().required("Membership term is required"),
      fees: Yup.string().required("Membership fees is required"),
    }),
    onSubmit: values => {
      // console.log(values)
      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        dispatch(updateMemberType(values))
      } else {
        dispatch(createMemberType(values))
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Membership" />

          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex ">
                  <h5 className="mb-1 card-title flex-grow-1"></h5>
                  <div className="flex-shrink-0 mb-1">
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  setPanel("mainservice")
                        toggleRightCanvas() */
                        toggleRightCanvas()
                        SetSelectData()
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add New Membership
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={MemberTypeList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {selectedData !== undefined
                ? "Edit Membership"
                : "Add MemberShip"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="p-3">
                  <Col>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="membership_name">Name</Label>
                      <Input
                        name="membership_name"
                        className="form-control"
                        placeholder="Enter Membership Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.membership_name || ""}
                        invalid={
                          validation.touched.membership_name &&
                          validation.errors.membership_name
                            ? true
                            : false
                        }
                        // disabled={selectedData?._id ? true : false}
                      />
                      {validation.touched.membership_name &&
                      validation.errors.membership_name ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.membership_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="fees">Fees ($)</Label>
                      <div className="input-group ">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                        <Input
                          name="fees"
                          className="form-control"
                          placeholder="Enter Membership Fees"
                          type="text"
                          aria-describedby="basic-addon1"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={`${validation.values.fees}` || ""}
                          invalid={
                            validation.touched.fees && validation.errors.fees
                              ? true
                              : false
                          }
                        />
                      </div>
                      {validation.touched.fees && validation.errors.fees ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.fees}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12 ">
                      <Label htmlFor="term_name">Subscription terms</Label>
                      <select
                        id="term_name"
                        name="term_name"
                        className={`form-select ${
                          validation.touched.term_name &&
                          validation.errors.term_name &&
                          "is-invalid"
                        }`}
                        onChange={e => {
                          validation.setFieldValue("term_name", e.target.value)
                          if (e.target.value === "Lifetime") {
                            validation.setFieldValue("term", 0)
                          } else {
                            validation.setFieldValue("term", 1)
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.touched.term_name}
                        // defaultValue={}
                      >
                        <option value={" "} disabled>
                          Select Subscription terms
                        </option>
                        <option value={"Lifetime"}>Lifetime</option>
                        <option value={"Annually"}>Annually</option>
                      </select>
                      {validation.touched.term_name &&
                      validation.errors.term_name ? (
                        <FormFeedback style={{ fontSize: "12px" }}>
                          {validation.errors.term_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col className=" mt-3 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger w-md px-5"
                      onClick={toggleRightCanvas}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>

          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Member ?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteMemberType(delete_data))

                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MemberType
