import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { del, get, post, put } from "../../helpers/api_helper"

import { APIServerIP } from "../../helpers/Sats_api_helper"

const initialEveState = {
  // companyInfo: null,
  newEventList: [],
  EventListWithvenue: [],
  isSuccessfullyAdded: false,
  isUserEdit: false,
}

const eventFormSlice = createSlice({
  name: "EventFromValue",
  initialState: initialEveState,
  reducers: {
    setEventList: (state, action) => {
      state.newEventList = action.payload
      // state.newEventList = [...state.newEventList, action.payload]
    },

    setEventListVenue: (state, action) => {
      state.EventListWithvenue = action.payload
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    setEventSuccessfullyAdded: (state, action) => {
      state.isSuccessfullyAdded = true
    },
    resetEvent: (state, action) => {
      state.isSuccessfullyAdded = false
      state.isUserEdit = false
    },
  },
})

export const createEvent = (image, values) => {
  return async dispatch => {
    // console.log("values", values, image)
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))

    dispatch(resetEvent())
    await fetch(`${APIServerIP}manage-event/create-event`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        /*  "Content-Type": "multipart/form-data", */
      },
      body: formData,
    })
      .then(res => {
        // console.log("res", res)
        if (res.status === 200) {
          dispatch(setEventSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })

    // try {
    //   dispatch(resetEvent())
    //   const responseData = await post(
    //     `${APIServerIP}manage-event/create-event`,
    //     formData
    //   )
    //   console.log("create user", responseData)
    //   if (responseData.response_code === 200) {
    //     dispatch(setEventSuccessfullyAdded())
    //   }
    // } catch (error) {
    //   console.log("try error", error)
    // }
  }
}

export const updateEvent = (image, values) => {
  return async dispatch => {
    // console.log("Updatevalues", values, image)

    const formData1 = new FormData()
    formData1.append("file", image),
      formData1.append("values", JSON.stringify(values))
    // if (values.imageUpdated) {

    // } else {
    //   console.log("publish")
    //   formData1.append("values", JSON.stringify(values))
    // }

    dispatch(resetEvent())
    await fetch(`${APIServerIP}manage-event/update-event`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        /*  "Content-Type": "multipart/form-data", */
      },
      body: formData1,
    })
      .then(res => {
        // console.log("Updateres", res)
        if (res.status == 200) {
          dispatch(setEventSuccessfullyAdded())
          localStorage.removeItem("editUser")
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}

export const deleteEvent = id => {
  return async dispatch => {
    try {
      dispatch(resetEvent())
      const responseData = await post(
        `${APIServerIP}manage-event/delete-event`,
        id
      )

      if (responseData.response_code === 200) {
        // console.log("Event user", responseData)
        dispatch(setEventSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveEvent = data => {
  return async dispatch => {
    try {
      dispatch(resetEvent())
      const responseData = await post(
        `${APIServerIP}manage-event/active-inactive-event`,
        data
      )

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setEventSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }

  // try {
  //   dispatch(resetEvent())
  //   const responseData = await post(
  //     "http://192.168.1.35:8060/sats-eventinfo/event-create",
  //     formData
  //   )
  //   console.log("create user", responseData)
  //   if (responseData.response_code === 200) {
  //     dispatch(setEventSuccessfullyAdded())
  //   }
  // } catch (error) {
  //   console.log("try error", error)
  // }
}

export const fetchEvent = () => {
  return async dispatch => {
    try {
      let responseData = await get(`${APIServerIP}manage-event/fetch-events`)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setEventList(responseData.data))
        dispatch(resetEvent())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const PublishEvent = data => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}manage-event/edit-publish-event`,
        data
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setEventSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const fetchEventandVenue = () => {
  return async dispatch => {
    try {
      let responseData = await get(
        `${APIServerIP}manage-event/fetch-event-and-venues-publish`
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setEventListVenue(responseData.data))
        dispatch(resetEvent())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setEventList,
  setEventSuccessfullyAdded,
  resetEvent,
  setEventListVenue,
  setUserEdit,
} = eventFormSlice.actions

export default eventFormSlice.reducer
