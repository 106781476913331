import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//jobs
import JobReducer from "./jobs/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer"

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer"

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer"

//auth
import AuthReducer from "../toolkitStore/authSlice"

//event management
import EventReducer from "../toolkitStore/eventMSlice/eventSlice"
import VenueReducer from "../toolkitStore/eventMSlice/VenueSlice"
import OrganiserReducer from "toolkitStore/eventMSlice/OrganiserSlice"
import SeatAllocationReducer from "../toolkitStore/eventMSlice/SeatAllocationSlice"

//SeatMap
import SeatReducer from "toolkitStore/SeatArrangement/SeatSlice"
//Member Management
import MemberTypeReducer from "toolkitStore/MemberMagement/MemberTypeSlice"
import MemberReducer from "toolkitStore/MemberMagement/MemberSlice"
//Committee
import ExecutiveReducer from "toolkitStore/CommitteeSlice/ExecutiveSlice"
//News Management
import NewsLetterReducer from "toolkitStore/NewsSlice/NewsLetterSlice"
import SubscriberReducer from "toolkitStore/NewsSlice/SubscriberSlice"
//Sponor
import SponsorReducer from "toolkitStore/SponsorSlice"
//AdMin Magnagement
import AdminReducer from "toolkitStore/admin/AdminSlice"
//Banner Magnagement
import BannerReducer from "toolkitStore/Banner/BannerSlice"
//Gallery MAnagement
import GalleryReducer from "toolkitStore/GallerySlice"
//MalligaiMalar
import MalligaiMalarReducer from "toolkitStore/MalligaiMalarSlice"
//YouthWing
import YouthWingReducer from "toolkitStore/YouthWingSlice"
import DonateReducer from "toolkitStore/DonateSlice"
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,
  //auth
  AuthReducer,
  //Event Mangement
  EventReducer,
  VenueReducer,
  OrganiserReducer,
  SeatAllocationReducer,
  //SeatMap
  SeatReducer,
  //Member Management
  MemberTypeReducer,
  MemberReducer,
  //SATS Team
  ExecutiveReducer,
  //News Magement
  NewsLetterReducer,
  SubscriberReducer,
  //Sponsor
  SponsorReducer,
  //Admin
  AdminReducer,
  // Banner
  BannerReducer,
  //Gallery
  GalleryReducer,
  //MalligaiMalar
  MalligaiMalarReducer,
  //YouthWing
  YouthWingReducer,
  DonateReducer,
})

export default rootReducer
