import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Input } from "reactstrap"
import Seat from "./Seat"
import { ShowDatalist } from "../../toolkitStore/SeatArrangement/SeatSlice"

function RowCount({ values2, Blocks, Rows, valueBlock2, totseat }) {
  const [seat, SetSeat] = useState()
  const dispatch = useDispatch()

  // console.log("selected V", values2)
  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)
  const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
  // console.log("venue_id", venue_id_c)

  useEffect(() => {
    let seatcount = values2.seatcount.length
    SetSeat(seatcount || totseat)
  }, [])

  // values2.seats = []
  for (let k = 1; k <= seat; k++) {
    values2.seats.push({
      id:
        Blocks +
        Rows +
        event_id_c.EventId +
        venue_id_c.VenueId +
        k +
        Blocks +
        Rows,
      Block: Blocks,
      Row: Rows,
      Seat: k,
      status: 0,
    })
  }

  return (
    <div className="d-flex justify-content-between col-12">
      <div className="col-2 mx-2 my-1">
        <Input
          name="seat"
          className={"form-control input-number"}
          placeholder="Enter the Number of seat"
          type="number"
          min="1"
          value={seat}
          onChange={e => {
            dispatch(ShowDatalist(valueBlock2))
            SetSeat(e.target.value)
          }}
        />
      </div>

      <div className="d-flex  col-10 overflow-auto my-2">
        {
          /* values2[0] || */ values2.seats?.map((s, inj) => (
            <div key={inj}>
              <Seat values3={s} valueBlock3={valueBlock2} />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default RowCount
