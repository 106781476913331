import React, { useState, useEffect } from "react"

import { Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { ShowDatalist } from "../../toolkitStore/SeatArrangement/SeatSlice"

function Seat({ valueBlock3, values3 }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ShowDatalist(valueBlock3))
  }, [])
  // console.log("seat", values3)

  return (
    <div className="btn-group me-2" role="group" aria-label="First group">
      <Button color="secondary" className={"btn btn-soft-danger btn-sm "}>
        {values3.Seat}
      </Button>
    </div>
  )
}

export default Seat
