import React, { useEffect, useState } from "react"
import { Input, Label, Col, Button } from "reactstrap"
import RowCount from "./RowCount"

function Block(props) {
  const { values, Block, valueBlock, totseat } = props
  const [row, SetRow] = useState()
  console.log("selected VB", values)

  useEffect(() => {
    let rowcount = values.rowcount.length > 0 ? values.rowcount.length : ""
    console.log(rowcount)
    SetRow(rowcount)
  }, [])

  for (let j = 1; j <= row; j++) {
    values.rows.push({
      Rowsv: j,
      seats: [],
      seatcount: values.rowcount[j - 1]?.seats || "",
    })
  }

  return (
    <div className="col-12">
      <Col className=" col-8 d-flex">
        <h1>block{values.i}</h1>
        <div className="mx-1 mb-3 col-6 d-flex">
          <Label htmlFor="row">Number of row:</Label>
          <Input
            name="row"
            className={`form-control input-number`}
            placeholder="Enter the Number of row"
            type="number"
            min="1"
            value={row}
            onChange={e => {
              SetRow(e.target.value)
            }}
          />
        </div>
      </Col>
      <Col>
        {values.rows.map((r, ink) => (
          <div key={ink}>
            <RowCount
              values2={r}
              Blocks={Block}
              Rows={r.Rowsv}
              valueBlock2={valueBlock}
              totseat={totseat}
            />
          </div>
        ))}
      </Col>
    </div>
  )
}

export default Block
