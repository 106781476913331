import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
//Import Components
import { fetchSponsor } from "toolkitStore/SponsorSlice"
import { fetchDonate } from "toolkitStore/DonateSlice"
import {
  fetchMember,
  fetchHomeCount,
} from "toolkitStore/MemberMagement/MemberSlice"
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"
import moment from "moment"

const DashboardSaas = props => {
  //meta title
  document.title = "SA Tamil Sangam - Admin Dashboard"

  const dispatch = useDispatch()
  const { MemberList, HomeMembersCountList, MemberisSuccessfully } =
    useSelector(state => state.MemberReducer)
  const { SponsorList, SponsorisSuccessfully } = useSelector(
    state => state.SponsorReducer
  )
  const { DonateList, DonateisSuccessfully } = useSelector(
    state => state.DonateReducer
  )
  // const [totalSponsers, setTotalSponsers] = useState(SponsorList)
  // const [totalMembers, setTotalMembers] = useState(MemberList)
  // const [totalDonaters, setTotalDonaters] = useState(DonateList)

  useEffect(() => {
    dispatch(fetchSponsor())
    dispatch(fetchMember())
    dispatch(fetchDonate())
    // dispatch(fetchHomeCount())
  }, [])

  const MemberLifeMembership = MemberList.filter(
    item => item.membership_type_id === "1"
  )
  const MemberStudent = MemberList.filter(
    item =>
      item.membership_type_id === "2"
  )
  const MemberAnnualFamily = MemberList.filter(
    item => item.membership_type_id === "3"
  )
  const DonateTotalAmountArray = DonateList.map((item, ind) => {
    if (item.donate_amount) {
      return parseFloat(item.donate_amount)
    } else {
      return 0
    }
  })
  const DonateTotalAmount = DonateTotalAmountArray.reduce(
    (acc, cur) => acc + cur,
    0
  )

  // console.log("Total count Member", MemberList.length)
  // console.log(" Life Membership", MemberLifeMembership.length)
  // console.log(" Total CountFamily ", MemberAnnualFamily.length)

  // const seriesSponsors = []
  // console.log("sponsors", seriesSponsors)

  const reports = [
    // {
    //   icon: "bx bx-copy-alt",
    //   title: "Orders",
    //   value: "1,452",
    //   badgeValue: "+ 0.2%",
    //   color: "success",
    //   desc: "From previous period",
    // },
    {
      icon: "bx bx-archive-in",
      title: "Donate",
      value: `$ ${DonateTotalAmount}`,
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    // {
    //   icon: "bx bx-purchase-tag-alt",
    //   title: "Average Price",
    //   value: "$ 16.2",
    //   badgeValue: "0%",
    //   color: "warning",
    //   desc: "From previous period",
    // },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="SA Tamil Sangam Admin Dashboard"
          />

          {/* Card User */}
          <CardUser
            TotalMembers={MemberList.length}
            MemberLifeMembership={MemberLifeMembership.length}
            MemberAnnualFamily={MemberAnnualFamily.length}
            MemberStudent={MemberStudent.length}
          // inactiveMembers={"m"}
          />

          {/* <Row>
            <CardWelcome />

            <Col xl="8">
              <Row>
                <MiniWidget reports={reports} />
              </Row>
            </Col>
          </Row> */}

          <Row>
            {/* earning */}
            <Earning dataColors='["--bs-primary"]' DonateList={DonateList} />

            {/* sales anytics */}
            <SalesAnalytics
              dataColors='["--bs-info","--bs-primary", "--bs-success", "--bs-danger","--bs-warning"]'
              SponsorList={SponsorList}
            />
          </Row>

          <Row>
            {/* total selling product */}
            {/* <TotalSellingProduct /> */}

            {/* tasks */}
            {/* <Tasks /> */}

            {/* chat box */}
            {/* <ChatBox /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
