import React, { useEffect, useState } from "react"
import "./seatStyle.css"
import {
  CardTitle,
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  FormFeedback,
  Label,
} from "reactstrap"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"
import { useNavigate, Link } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import {
  CreateSeats,
  ShowDatalist,
  UpdateSeats,
  EditSeats,
} from "toolkitStore/SeatArrangement/SeatSlice"
import Block from "components/SeatMap/Block"

function SeatMap() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const event_id_c = JSON.parse(localStorage.getItem("event_id"))

  const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))

  useEffect(() => {
    dispatch(EditSeats({ master_venue_id: venue_id_c.VenueId }))
  }, [])

  const { SeatId,FormisSuccessfully, selectedData, SeatDataList } = useSelector(
    state => state.SeatReducer
  )

  const [block, SetBlock] = useState()
  const [totseat, Settotseat] = useState()
useEffect(()=>{
  if(FormisSuccessfully){
    dispatch(ShowDatalist(valueBlock))
  }

},[FormisSuccessfully])
  useEffect(() => {
    if (selectedData.length > 0) {
      SetBlock(selectedData?.length)
      Settotseat(selectedData[0]?.rows[0]?.seats.length)
    } else {
      SetBlock("")
      Settotseat("")
    }
  }, [SeatId])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      block: block || "",
      rowperseat: totseat || "",
    },
    validationSchema: Yup.object({
      block: Yup.number()
        .required("Number is required")
        .min(1, "Number must be at least 1")
        .max(5, "Number must be at most 5")
        .integer("Number must be an integer"),
      rowperseat: Yup.number()
        // .required("Number is required")
        .min(1, "Number must be at least 1")
        .max(50, "Number must be at most 50")
        .integer("Number must be an integer"),
    }),
    onSubmit: (values, actions) => {
      SetBlock(values.block)
      Settotseat(values.rowperseat)
      dispatch(ShowDatalist(valueBlock))
    },
  })

  const valueBlock = []

  for (let i = 1; i <= block; i++) {
    valueBlock.push({
      Blocksv: i,
      rows: [],
      rowcount: selectedData[i - 1]?.rows || "",
    })
  }

  const toggleClass = (e, ...others) => {
    if (
      e.target.classList.contains("seat") &&
      !e.target.classList.contains("sold")
    ) {
      e.target.classList.toggle("selected")
    }
  }
  //Data Store to DB
  const DataStore = () => {
    // console.log(SeatId)
    if (SeatId === "") {
      dispatch(
        CreateSeats({
          master_event_id: event_id_c.EventId,
          master_venue_id: venue_id_c.VenueId,
          valueBlock: valueBlock,
        })
      )
    } else {
      dispatch(
        UpdateSeats({
          _id: SeatId,
          master_event_id: event_id_c.EventId,
          master_venue_id: venue_id_c.VenueId,
          valueBlock: valueBlock,
        })
      )
    }
    navigate("/venuelist")
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <CardBody>
            <div className="d-inline-block ">
              <p>Event Name: {event_id_c.EventName}</p>
              <p>Venue Place:{venue_id_c.VenueName}</p>
              <p>Maximum Tickets:{venue_id_c.MaxTicket}</p>
            </div>
            <CardTitle className=" mb-3 pb-1  align-items-center  d-flex border-2 border-bottom ">
              <h5 className="mt-2 card-title flex-grow-1">
                Seat Setting Details
              </h5>
              <div className="flex-shrink-0">
                <Link to="/venuelist" className="btn btn-secondary me-1">
                  Back
                </Link>
                <Link
                  to="#!"
                  onClick={() => {
                    dispatch(EditSeats({ VenueId: venue_id_c.VenueId }))
                  }}
                  className="btn btn-light me-1"
                >
                  <i className="mdi mdi-refresh"></i>
                </Link>
              </div>
            </CardTitle>

            <Row className="col-lg-12 d-flex flex-wrap justify-content-around">
              <div className="mb-3 col-5">
                <Label htmlFor="block">Block</Label>
                <Input
                  name="block"
                  className={`form-control`}
                  placeholder="Enter the Block"
                  onBlur={validation.handleBlur}
                  type="number"
                  min="1"
                  value={validation.values.block /* || block */}
                  onChange={
                    validation.handleChange
                    // SetBlock(e.target.value)
                  }
                  invalid={
                    validation.touched.block && validation.errors.block
                      ? true
                      : false
                  }
                />
                {validation.touched.block && validation.errors.block ? (
                  <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                    {validation.errors.block}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mx-1 mb-3 col-6 ">
                <Label htmlFor="rowperseat">Number of seat per row:</Label>
                <Input
                  name="rowperseat"
                  className={`form-control`}
                  placeholder="Enter the Number of row"
                  onBlur={validation.handleBlur}
                  type="number"
                  min="1"
                  value={validation.values.rowperseat /* || totseat */}
                  onChange={
                    validation.handleChange
                    // Settotseat(e.target.value)
                  }
                  invalid={
                    validation.touched.rowperseat &&
                    validation.errors.rowperseat
                      ? true
                      : false
                  }
                />
                {validation.touched.rowperseat &&
                validation.errors.rowperseat ? (
                  <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                    {validation.errors.rowperseat}
                  </FormFeedback>
                ) : null}
              </div>
              <button
                className="btn btn-success "
                type="submit"
                onClick={e => {
                  e.preventDefault()

                  validation.handleSubmit()
                }}
              >
                {"Submit"}
              </button>
              <button
                className="btn btn-danger mt-2"
                onClick={e => {
                  e.preventDefault()

                  DataStore()
                }}
              >
                {"Store"}
              </button>
            </Row>
          </CardBody>
        </Card>

        <div className="col-12 d-flex flex-wrap ">
          {/* <div className="screen"></div> */}
          {valueBlock.map((val, ib) => {
            return (
              <div key={ib} className="col-6">
                <Block
                  values={val}
                  Block={val.Blocksv}
                  valueBlock={valueBlock}
                  totseat={totseat}
                />
              </div>
            )
          })}
        </div>
        {/*  */}
        <div className="col-10 d-flex flex-wrap justify-content-around">
          {SeatDataList?.map((SDT, index) => (
            <div key={index}>
              <Col className="col-12 mt-3 mx-3">
                <h1>block{SDT.Blocksv}</h1>
                <div className="mx-1">
                  {SDT.rows.map((rows1, index1) => (
                    <Col
                      key={index1}
                      className="col-12 d-flex  2px border solid"
                    >
                      <small className="">{rows1.Rowsv}</small>

                      <div className="d-flex mt-2 flex-wrap  justify-content-start ">
                        {
                          /* rows1[0] || */ rows1.seats.map(
                            (seats1, index2) => (
                              <Col key={index2}>
                                <div
                                  className="btn-group me-2 "
                                  role="group"
                                  aria-label="First group"
                                >
                                  <Button
                                    className={`btn btn-sm seat ${
                                      seats1.isReserved ? "sold" : null
                                    }
                                `}
                                    onClick={e =>
                                      toggleClass(e, SDT.i, rows1.j, seats1)
                                    }
                                  >
                                    {seats1.Seat}
                                  </Button>
                                </div>
                              </Col>
                            )
                          )
                        }
                      </div>
                    </Col>
                  ))}
                </div>
              </Col>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default SeatMap
