import React, { useRef, useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  createBookingDetails,
  updateBookingDetails,
  resetBookingDetails,
} from "toolkitStore/eventMSlice/SeatAllocationSlice"

function SeatAllocation({ setIsRight }) {
  //meta title
  /* document.title =
    "Create New Project | Skote - React Admin & Dashboard Template" */

  const [selectedData, SetSelectData] = useState()
  const [is_reserved, setIsReserved] = useState(false)

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)
  const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
  // console.log("venue_id", venue_id_c)

  //edit /update Function
  // useEffect(() => {
  //   if (isUserEdit) {
  //     const editData = JSON.parse(localStorage.getItem("editUser"))
  //     SetSelectData(editData)
  //   }
  // }, [isUserEdit])

  //Cancel

  return (
    <>
      <Container fluid></Container>
    </>
  )
}

export default SeatAllocation
