import React, { useState, useEffect } from "react"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Label,
  Input,
  Container,
  FormFeedback,
  
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// ck editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import {
  EditNewsdata,
  createNewsLetter,
  updateNewsLetter,
  // editUser
} from "../../../toolkitStore/NewsSlice/NewsLetterSlice"
import { createDonate, updateDonate } from './../../../toolkitStore/DonateSlice'
import { fetchEventandVenue } from './../../../toolkitStore/eventMSlice/eventSlice'
import { sponsorTicketAllocation } from './../../../toolkitStore/SponsorSlice'
import { APIServerIP } from "helpers/Sats_api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Publish } from "@mui/icons-material"
import axios from 'axios'
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, NewsLetterList } from "toolkitStore/memberSlice"

function SponserTickets(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EventListWithvenue } = useSelector((state) => state.EventReducer)
  const { EditnewsDetails, NewsLetterList, NewsLetterisSuccessfully } =
    useSelector(state => state.NewsLetterReducer)
  //Delete
  const [selectedData, SetSelectData] = useState()
  // const [indiviualArray, setIndiviualArray] = useState()
  const [venue, setVenue] = useState([])
  // const [Ckdata,setCkdata]=useState("")

  //image

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    if (NewsLetterisSuccessfully) {
      //     // localStorage.removeItem("publishnewsletter")
      //     // validation.resetForm()
      // navigate("/sendnewsletter")
      SetSelectData(EditnewsDetails)
    }
  }, [NewsLetterisSuccessfully])

  useEffect(() => {
    SetSelectData()
    dispatch(fetchEventandVenue())
    const EU = localStorage?.getItem("publishnewsletter")
    // console.log("Retrieved Data:", EU)
    if (localStorage.getItem("publishnewsletter")) {
      dispatch(EditNewsdata({ _id: EU }))
      SetSelectData(EditnewsDetails)
    } else {
      // console.log("Retrieved empty:", EU)
      SetSelectData()
    }
  }, [])

  useEffect(() => {
    console.log("EventListWithvenue", EventListWithvenue);
  }, [EventListWithvenue])

  const fetchEvents = async () => {
    const events = await axios.get('fetch-event-and-venues-publish')
  }
  //   useEffect(() => {
  //       SetSelectData(EditnewsDetails)
  // }, [EditnewsDetails])

  //   console.log(EditnewsDetails)

  const Cancel = e => {
    e.preventDefault()
    // localStorage.removeItem("publishnewsletter")
    // validation.resetForm()
    // SetSelectData()
    navigate("/donate")
  }
  // if (selectedData?.mailto) {
  //   console.log(selectedData?.mailto)
  //   const mailttt = selectedData?.mailto
  //     .map((item, ind) => {
  //       return item.email
  //     })
  //     .join()
  //   console.log(mailttt)
  // }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      master_event_id: "",
      master_venue_id: "",
      firstname: selectedData?.firstname || "",
      lastname: selectedData?.lastname || "",
      email: selectedData?.email || "",
      event_name: selectedData?.event_name || "",
      venue_name: selectedData?.venue_name || "",
      event_date: selectedData?.event_date || "",
      venue_date: selectedData?.venue_date || "",
      adult_count: selectedData?.adult_count || "",
      child_count: selectedData?.child_count || ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("First Name is required"),
    //   lastname: Yup.string().required("Last Name is required"),
      email: Yup.string().required("Email is required"),
      event_name: Yup.string().required("Event name is required"),
      venue_name: Yup.string().required("Venue name is required"),
      adult_count: Yup.string().required("Adult count is required"),
      child_count: Yup.string().required("Child Count is required"),
      venue_date: Yup.string().required("Venue Date is required"),
      event_date: Yup.string().required("Event Date is required"),
      //   mail_content: Yup.string().required("mail Content is required"),
    }),
    onSubmit: values => {
      // console.log(values)
      // if (selectedData?._id) {
      //   values["_id"] = selectedData?._id
      //   dispatch(updateDonate(values))
      //   navigate("/sponsor")
      // } else {
        // values["mailto"] = indiviualArray
        dispatch(sponsorTicketAllocation(values))
        navigate("/sponsor")
      // }
    },
  })

  const handleEventChange = () => {
    if(validation.values.event_name) {
      const selectedEvent = EventListWithvenue?.find(val => val.name == validation.values.event_name)
      validation.values.master_event_id = selectedEvent?._id
      validation.values.event_date = selectedEvent?.start_date
      setVenue(selectedEvent.venues)
    }
  }

  const handleVenueChange = () => {
    if(validation.values.venue_name) {
      const selectedVenue = venue?.find(val => val.venue == validation.values.venue_name)
      validation.values.master_venue_id = selectedVenue?._id
      validation.values.venue_date = selectedVenue?.start_datetime
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Add Donation" />
          <Card>
            <CardBody>
              <div className="mt-0 mb-3 d-flex align-items-center border-secondary border-bottom pb-2 ">
                <h5 className="mb-1 card-title flex-grow-1">Form</h5>
                <div className="flex-shrink-0 mb-1">
                  <Link
                    to="/donate"
                    onClick={e => {
                      Cancel(e)
                    }}
                    className="btn btn-dark me-1"
                  >
                    Back
                  </Link>
                </div>
              </div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="mt-4">
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="firstname">First Name</Label>
                      <Input
                        name="firstname"
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstname || ""}
                        invalid={
                          validation.touched.firstname &&
                            validation.errors.firstname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstname &&
                        validation.errors.firstname ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.firstname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="lastname">Last Name</Label>
                      <Input
                        name="lastname"
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastname || ""}
                        invalid={
                          validation.touched.lastname &&
                            validation.errors.lastname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastname &&
                        validation.errors.lastname ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email &&
                            validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email &&
                        validation.errors.email ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="event_name">Event Name</Label>
                      <Input
                        name="event_name"
                        className="form-control"
                        placeholder="Enter Event name"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={handleEventChange}
                        value={validation.values.event_name || ""}
                        invalid={
                          validation.touched.event_name &&
                            validation.errors.event_name
                            ? true
                            : false
                        }
                      ><option key="select" value={""}>Select Event</option>
                        {EventListWithvenue.map(val => <option key={val.name} value={val.name}>{val.name}</option>)}
                      </Input>
                      {validation.touched.event_name &&
                        validation.errors.event_name ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.event_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="venue_name">Venue Name</Label>
                      <Input
                        name="venue_name"
                        className="form-control"
                        placeholder="Enter venue_name"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={handleVenueChange}
                        value={validation.values.venue_name || ""}
                        invalid={
                          validation.touched.venue_name &&
                            validation.errors.venue_name
                            ? true
                            : false
                        }
                      >
                        <option key={"select"} value="">Select Venue</option>
                        {venue?.map(val => <option key={val._id} value={val.venue}>{val.venue}</option>)}
                      </Input>
                      {validation.touched.venue_name &&
                        validation.errors.venue_name ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.venue_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {/* <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="address2">Address</Label>
                      <Input
                        name="address2"
                        className="form-control"
                        placeholder="Enter Address"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address2 || ""}
                        invalid={
                          validation.touched.address2 &&
                            validation.errors.address2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address2 &&
                        validation.errors.address2 ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.address2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  <Col md={3}>
                    <div className="mb-3">
                      <Label htmlFor="adult_count">Adult Count</Label>
                      <Input
                        name="adult_count"
                        className="form-control"
                        placeholder="Enter adult count"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.adult_count || ""}
                        invalid={
                          validation.touched.adult_count &&
                            validation.errors.adult_count
                            ? true
                            : false
                        }
                      />
                      {validation.touched.adult_count &&
                        validation.errors.adult_count ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.adult_count}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label htmlFor="child_count">Child Count</Label>
                      <Input
                        name="child_count"
                        className="form-control"
                        placeholder="Enter child count"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.child_count || ""}
                        invalid={
                          validation.touched.child_count &&
                            validation.errors.child_count
                            ? true
                            : false
                        }
                      />
                      {validation.touched.child_count &&
                        validation.errors.child_count ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.child_count}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="my-3 justify-content-end px-2 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger w-md px-5"
                      onClick={e => Cancel(e)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>

              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SponserTickets