import React, { useState } from "react"

import getCountries from "../../../node_modules/country-state-city/lib/country"
import getStates from "../../../node_modules/country-state-city/lib/state"
import getCities from "../../../node_modules/country-state-city/lib/city"
function MyForm() {
  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")

  const handleCountryChange = event => {
    setCountry(event.target.value)
  }

  const handleStateChange = event => {
    setState(event.target.value)
  }

  const handleCityChange = event => {
    setCity(event.target.value)
  }

  const validateForm = () => {
    if (!country) {
      alert("Please select a country")
      return false
    }

    if (!state) {
      alert("Please select a state")
      return false
    }

    if (!city) {
      alert("Please select a city")
      return false
    }

    return true
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (validateForm()) {
      // Perform further actions (e.g., submit form to server)
    }
  }

  const countries = getCountries()
  const states = getStates(country)
  const cities = getCities(state)

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="country">Country:</label>
      <select id="country" value={country} onChange={handleCountryChange}>
        <option value="">Select a country</option>
        {countries.map(country => (
          <option value={country.isoCode} key={country.isoCode}>
            {country.name}
          </option>
        ))}
      </select>
      <br />

      <label htmlFor="state">State:</label>
      <select id="state" value={state} onChange={handleStateChange}>
        <option value="">Select a state</option>
        {states.map(state => (
          <option value={state.isoCode} key={state.isoCode}>
            {state.name}
          </option>
        ))}
      </select>
      <br />

      <label htmlFor="city">City:</label>
      <select id="city" value={city} onChange={handleCityChange}>
        <option value="">Select a city</option>
        {cities.map(city => (
          <option value={city.name} key={city.name}>
            {city.name}
          </option>
        ))}
      </select>
      <br />

      <input type="submit" value="Submit" />
    </form>
  )
}

export default MyForm
