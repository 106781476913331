//  1-Local Testing
//  2- Development
//  3-Production

const MODE = 2
export let APIServerIP

if (MODE === 1) {
  // Local API
  APIServerIP = `http://192.168.43.85:8064/`
} else if (MODE === 2) {
  // Live DEVELOPMENT
  APIServerIP = `https://satsdevapi.quasarpixels.com/`
} else if (MODE === 3) {
  // Live PRODUCTION
  APIServerIP = `https://api.satamilsangam.org/`
}

export default APIServerIP
