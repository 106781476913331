import React, { useRef, useState, useEffect } from "react"
/* import axios from "axios" */
import { Link, useNavigate } from "react-router-dom"
import { APIServerIP } from "../../../helpers/Sats_api_helper"
import moment from "moment"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

//Import Date Picker
import { format } from "date-fns"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  createEvent,
  updateEvent,
  resetEvent,
} from "../../../toolkitStore/eventMSlice/eventSlice"
import { Feedback } from "@mui/icons-material"

// const formData = new FormData()

function EventForm(props) {
  //meta title
  /* document.title =
    "Create New Project | Skote - React Admin & Dashboard Template" */
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { newEventList, isSuccessfullyAdded, isUserEdit } = useSelector(
    state => state.EventReducer
  )

  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    validation.setFieldValue("description", data)
  }

  document.title = "Form File Upload | Skote - React Admin & Dashboard Template"

  const [image, SetImage] = useState()
  const [selectedData, SetSelectData] = useState()
  const [dis4, SetDis4] = useState(false)
  const [dis1, SetDis1] = useState(false)

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))

  //edit /update Function

  // useEffect(() => {
  //   if (isSuccessfullyAdded) {
  //     navigate("/eventlist")
  //   }
  // }, [isSuccessfullyAdded])

  useEffect(() => {
    if (isUserEdit) {
      const editUser = JSON.parse(localStorage.getItem("editUser"))
      SetSelectData(editUser)

      // if (selectedData?.event_status == "4") {
      //   SetDis1(true)
      //   SetDis4(true)
      // }
      // if (selectedData?.event_status == "1") {
      //   SetDis1(true)
      // }
      // if (selectedData?.event_status == "0") {
      //   SetDis1(false)
      //   SetDis4(false)
      // }
    }
  }, [isUserEdit])
  //Cancel
  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("editUser")
    SetSelectData("")
    navigate("/eventlist")
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: selectedData?.name || "",
      short_description: selectedData?.short_description || "",
      description: selectedData?.description || "",
      image_name: selectedData?.image_name || "",
      event_for: selectedData?.event_for || "",
      type: selectedData?.type || "",
      image_preview: selectedData?.image_preview || 0,
      no_of_days: selectedData?.no_of_days || "",
      start_date: selectedData?.start_date
        ? moment.parseZone(selectedData?.start_date).format("yyyy-MM-DDThh:mm")
        : "",
      active_status: selectedData?.active_status || "Active",
      publish_status: selectedData?.publish_status || "Draft",
      publish_status_id: selectedData?.public_status_id || 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(" Enter Your Event Name"),
      short_description: Yup.string().required(" Enter Your SHORT Description"),
      start_date: Yup.string().required(
        " Enter Your Event Start Date and Time"
      ),
      event_for: Yup.string().required(" Event For is required"),
      // active_status: Yup.string().required("Active statusFor is required"),

      type: Yup.string().required("Type is required"),
      // image_preview: Yup.string("Image Preview is reuired"),
      image_name: Yup.string().required("A photo is required"),
      no_of_days: Yup.number().required("Number of day event"),
    }),
    onSubmit: (values, actions) => {
      console.log("hi")
      if (selectedData?._id) {
        // image == undefined
        //   ? (values["imageUpdated"] = false)
        //   : (values["imageUpdated"] = true)
        values["_id"] = selectedData?._id
        console.log("mm", values)
        dispatch(updateEvent(image, values))
      } else {
        dispatch(createEvent(image, values))
      }

      actions.resetForm()
      SetImage("")
      navigate("/eventlist")
      console.log(values)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Event Information" />
          <Row>
            <Form
              onSubmit={event => {
                event.preventDefault()
                validation.handleSubmit()
              }}
            >
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center border-bottom border-secondary pb-3">
                    <h5 className="mb-1 card-title flex-grow-1 text-primary ">
                      {event_id_c ? event_id_c.EventName : "New Event"}
                    </h5>
                    <div className="mb-1 flex-shrink-0">
                      <Link to="/eventlist" className="btn btn-secondary me-1">
                        Back
                      </Link>
                      <Link
                        to="#!"
                        onClick={() => {
                          /*  dispatch(fetchServices(userInformation.m_company_id)) */
                        }}
                        className="btn btn-light me-1"
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                  </div>

                  <Row className="mt-3 mb-3">
                    <Col lg={12} className="mb-3">
                      <div className="">
                        <Label htmlFor="name">Event Name</Label>
                        <Input
                          name="name"
                          className={`form-control`}
                          placeholder="Enter Event Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                          disabled={dis4}
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12} className="mb-3">
                      <div className="">
                        <Label htmlFor="short_description">
                          Event Short Description
                        </Label>
                        <Input
                          name="short_description"
                          className={`form-control`}
                          placeholder="Enter event short description"
                          type="textarea"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.short_description || ""}
                          invalid={
                            validation.touched.short_description &&
                            validation.errors.short_description
                              ? true
                              : false
                          }
                          disabled={dis4}
                        />
                        {validation.touched.short_description &&
                        validation.errors.short_description ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.short_description}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={12} className="mb-3">
                      <div
                        className="" //position-relative
                      >
                        <Label htmlFor="how_to">Description</Label>
                        <CKEditor
                          config={{
                            placeholder: "Write about event...",
                          }}
                          editor={ClassicEditor}
                          data={validation.values.event_desc}
                          onChange={handleCkeditorIn}
                          disabled={dis4}
                        />
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="">
                        <Label htmlFor="image_preview">Image Preview</Label>
                        <select
                          id="image_preview"
                          name="image_preview"
                          className={`form-select ${
                            validation.touched.image_preview &&
                            validation.errors.image_preview &&
                            "is-invalid"
                          }`}
                          value={validation.values.image_preview}
                          onChange={e =>
                            validation.setFieldValue(
                              "image_preview",
                              e.target.value
                            )
                          }
                        >
                          <option value={0}>Landscape</option>
                          <option value={1}>Protriat</option>
                        </select>
                        {validation.touched.image_preview &&
                        validation.errors.image_preview ? (
                          <FormFeedback>
                            {validation.errors.image_preview}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="">
                        <Label htmlFor="image_name">Event Photos</Label>
                        <Input
                          name="image_name"
                          className="form-control"
                          placeholder="Enter The Designation"
                          type="file"
                          onChange={e => {
                            validation.setFieldValue(
                              "image_name",
                              e.currentTarget.files[0].name
                            )
                            SetImage(e.currentTarget.files[0])
                          }}
                          invalid={
                            validation.touched.image_name &&
                            validation.errors.image_name
                              ? true
                              : false
                          }
                          disabled={dis1}
                        />
                        {validation.touched.image_name &&
                        validation.errors.image_name ? (
                          <div
                            type="invalid"
                            className="invalid-feedback"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.image_name}
                          </div>
                        ) : selectedData?.image_name ? (
                          <a
                            href={`${APIServerIP}uploads/${selectedData?.image_name}`}
                          >
                            {selectedData?.image_name}
                          </a>
                        ) : (
                          validation.values.image_name
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <div className="">
                        <Label htmlFor="type">Event Type</Label>
                        <select
                          id="type"
                          name="type"
                          className={`form-select ${
                            validation.touched.type &&
                            validation.errors.type &&
                            "is-invalid"
                          }`}
                          onChange={e =>
                            validation.setFieldValue("type", e.target.value)
                          }
                          value={validation.values.type || ""}
                        >
                          <option value=" ">Select Type </option>
                          <option value={"PAID"}>PAID</option>
                          <option value={"FREE"}>FREE</option>
                        </select>
                        {validation.touched.type && validation.errors.type ? (
                          <FormFeedback>{validation.errors.type}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="">
                        <Label htmlFor="event_for">Event For</Label>
                        <select
                          id="event_for"
                          name="event_for"
                          // className="form-select"
                          className={`form-select ${
                            validation.touched.event_for &&
                            validation.errors.event_for &&
                            "is-invalid"
                          }`}
                          value={validation.values.event_for || ""}
                          onChange={e =>
                            validation.setFieldValue(
                              "event_for",
                              e.target.value
                            )
                          }
                        >
                          <option value={""}>Select Event for</option>
                          <option value={"All"}>All</option>
                          <option value={"Member"}>Member</option>
                          <option value={"Non-Member"}>Non-Member</option>
                        </select>
                        {validation.touched.event_for &&
                        validation.errors.event_for ? (
                          <FormFeedback>
                            {validation.errors.event_for}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="">
                        <Label htmlFor="no_of_days">Number of days</Label>
                        <Input
                          name="no_of_days"
                          className={`form-control`}
                          placeholder="Number of days"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.no_of_days || ""}
                          invalid={
                            validation.touched.no_of_days &&
                            validation.errors.no_of_days
                              ? true
                              : false
                          }
                          disabled={dis4}
                        />
                        {validation.touched.no_of_days &&
                        validation.errors.no_of_days ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.no_of_days}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="">
                        <Label htmlFor="start_date">Start Date & Time</Label>
                        <Input
                          name="start_date"
                          className="form-control  "
                          placeholder="yyyy-mm-dd"
                          type="datetime-local"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.start_date || ""}
                          invalid={
                            validation.touched.start_date &&
                            validation.errors.start_date
                              ? true
                              : false
                          }
                          disabled={dis1}
                        />
                        {validation.touched.start_date &&
                        validation.errors.start_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.start_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {event_id_c && (
                      <Col>
                        <div className="">
                          <Label htmlFor="active_status"> Event Status </Label>
                          <select
                            id="active_status"
                            name="active_status"
                            className={`form-select ${
                              validation.touched.active_status &&
                              validation.errors.active_status &&
                              "is-invalid"
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.active_status}
                          >
                            <option value={" "}>Select the status</option>
                            <option value={"Active"}>Active</option>
                            <option value={"Inactive"}>Inactive</option>
                          </select>
                          {validation.touched.active_status &&
                          validation.errors.active_status ? (
                            <FormFeedback>
                              {validation.errors.active_status}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    )}
                  </Row>

                  <Col className="d-flex mt-3 justify-content-end">
                    <div className=" text-center mt-3 ">
                      <button
                        type="submit"
                        className="btn btn-success w-md px-5"
                      >
                        {selectedData?._id ? "Update" : "Submit"}
                      </button>
                    </div>
                    <div className=" text-center mt-3 mx-3 ">
                      <button
                        onClick={Cancel}
                        className="btn btn-danger w-md px-5"
                      >
                        {"Cancel"}
                      </button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EventForm
