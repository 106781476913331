import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const initialSeatMapState = {
  SeatDataList: [],
  SeatId: "",
  FormisSuccessfully: false,
  selectedData: [],
}

const SeatSlice = createSlice({
  name: "SeatFormation",
  initialState: initialSeatMapState,
  reducers: {
    setDataList: (state, action) => {
      state.SeatDataList = action.payload
    },
    resetDataList: (state, action) => {
      state.SeatDataList = []
    },
    setSeatSuccessfullyAdded: (state, action) => {
      state.FormisSuccessfully = true
    },
    setEditSeats: (state, action) => {
      state.selectedData = action.payload
    },
    setSeatId: (state, action) => {
      state.SeatId = action.payload
    },
    resetSeat: (state, action) => {
      state.FormisSuccessfully = false
      state.selectedData = []
      state.SeatId = ""
    },
  },
})

export const ShowDatalist = data => {
  return async dispatch => {
    // dispatch(resetSeat())
    try {
      dispatch(setSeatSuccessfullyAdded())
      dispatch(setDataList(await data))
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const CreateSeats = data => {
  return async dispatch => {
    dispatch(resetSeat())
    try {
      // console.log("seatdata", data)

      let responseData = await post(
        `${APIServerIP}manage-venue-seats/create-venue-seats`,
        data
      )
      // console.log("create-venue-seats", responseData)
      if (responseData.response_code === 500) {
        dispatch(setSeatSuccessfullyAdded())
        // dispatch(setDataList(data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const EditSeats = data => {
  return async dispatch => {
    try {
      dispatch(resetSeat())
      dispatch(resetDataList())
      // console.log("Edit seatdata", data)

      let responseData = await post(
        `${APIServerIP}manage-venue-seats/edit-venue-seats`,
        data
      )
      // console.log("Edit Data", responseData)
      if (responseData.response_code === 200) {
        dispatch(setEditSeats(responseData.data.valueBlock))
        // console.log("id", responseData.data._id)
        dispatch(setSeatId(responseData.data._id))
      }
      if (responseData.response_code === 404) {
        dispatch(resetSeat())
        dispatch(resetDataList())
      }
    } catch (error) {
      // console.log("try error fetch", error)
    }
  }
}

export const UpdateSeats = data => {
  return async dispatch => {
    try {
      // console.log("seatdata", data)
      let responseData = await post(
        `${APIServerIP}manage-venue-seats/update-venue-seats`,
        data
      )

      // console.log("update-venue-seats", responseData)
      if (responseData.response_code === 500) {
        dispatch(setSeatSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setDataList,
  setSeatSuccessfullyAdded,
  resetSeat,
  setEditSeats,
  setSeatId,
  resetDataList,
} = SeatSlice.actions

export default SeatSlice.reducer
