import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"

import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  DropdownButton,
  Dropdown,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createMember,
  updateMember,
  deleteMember,
  activeInactiveMember,
  fetchFamilyMembers,

  // editUser
} from "../../../toolkitStore/MemberMagement/MemberSlice"

import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import APIServerIP from "../../../helpers/Sats_api_helper"

// import { FamilyMembersList, FamilyMembersList } from "toolkitStore/memberSlice"

function FamilyMembers(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isScrollBackDrop, setIsScrollBackDrop] = useState(false)

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()

  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    // console.log("is Right", isRight)
    setIsRight(!isRight)
  }

  //image
  const { FamilyMembersList, MemberisSuccessfully } = useSelector(
    state => state.MemberReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchFamilyMembers())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchFamilyMembers())
    if (MemberisSuccessfully) {
      setIsScrollBackDrop(false)
    }
  }, [modal_backdrop, MemberisSuccessfully])

  const columns = useMemo(
    () => [
      {
        Header: "Member Id",
        accessor: "member_id",
      },
      //   {
      //     Header: "Parent Id",
      //     accessor: "family_member_sats_id",
      //   },
      {
        Header: "Name ",
        accessor: "firstname",
      },
      {
        Header: "Member Type",
        accessor: "member_type",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>{cellProps.row.original?.member_type}</li>
            </ul>
          )
        },
      },
      // {
      //   Header: "Membership Type ",
      //   accessor: "",
      // },
      // {
      //   Header: "Subscription term",
      //   accessor: "membership_type",
      //   Cell: cellProps => {
      //     return (
      //       <ul className="list-unstyled mb-0">
      //         <li>{cellProps.row.original?.membership_type || "Member"}</li>
      //       </ul>
      //     )
      //   },
      // },
      //   {
      //     Header: "Joined Date",
      //     accessor: "joined_on",
      //     Cell: cellProps => {
      //       let sd = moment
      //         .parseZone(cellProps.row.original.joined_on)
      //         .format("DD / MM / YYYY")
      //       return (
      //         <ul className="list-unstyled mb-0">
      //           <li>{sd !== "Invalid date" ? sd : "NA"}</li>
      //         </ul>
      //       )
      //     },
      //   },
      // {
      //   Header: "Expired Date",
      //   accessor: "expired_date",
      //   Cell: cellProps => {
      //     let sd = moment(cellProps.row.original.expired_date).format(
      //       "YYYY-MM-DD hh:mm a"
      //     )
      //     return (
      //       <ul className="list-unstyled d-block">
      //       <li>{sd}</li>
      //     </ul>
      //     )
      //   },
      // },

      {
        Header: "status",
        accessor: "active_status",
        Cell: cellProps => {
          // console.log("cellProps.row.original.active_status", cellProps.row.original)
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.active_status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.active_status === 0
                    ? "Inactive"
                    : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info me-2"
                  onClick={e => {
                    const SData = cellProps.row.original
                    localStorage.setItem(
                      "editMember",
                      JSON.stringify(SData._id)
                    )
                    SetSelectData(SData)
                    toggleRightCanvas()
                    //setIsScrollBackDrop(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-book-outline" id="edittooltip" />
                </Link>
              </li>
              {/* <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger me-2"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2 me-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      active_status: SData.active_status,
                    }

                    dispatch(activeInactiveMember(PassingData))
                  }}
                >
                  {cellProps.row.original.active_status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li> */}
            </ul>
          )
        },
      },
    ],
    []
  )

  const toggleScrollBackDrop = () => {
    setIsScrollBackDrop(!isScrollBackDrop)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Member Management" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex ">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary ">
                    SATS Members
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel
                      excelData={FamilyMembersList}
                      fileName={"SATS FamilyMembers"}
                    />
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={FamilyMembersList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={15}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>

          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
          >
            {selectedData !== undefined && (
              <>
                <OffcanvasHeader
                  toggle={toggleRightCanvas}
                  className="border-bottom border-secondary"
                >
                  {"Member Information"}
                </OffcanvasHeader>
                <OffcanvasBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                    }}
                  >
                    <Row className="p-3">
                      {selectedData.image_name && (
                        <Col className="col-12 mb-3 justify-content-center align-items-center">
                          <img
                            src={`${APIServerIP}assets/uploads/${selectedData.image_name}`}
                            alt="mi"
                            style={{
                              width: "40%",
                            }}
                          ></img>
                        </Col>
                      )}

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Name
                        </div>
                        <div style={{ fontSize: 15, fontWeight: 500 }}>
                          {selectedData.firstname} {selectedData.lastname}
                        </div>
                        <div style={{ fontSize: 14 }}>
                          {selectedData.member_id}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          DOB
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.dob}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Address
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.address}
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.address2}
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.city}, {selectedData.state},
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.country}, {selectedData.zipcode}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Mobile
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.mobile}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Email
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.email}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Membership Type
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                          {selectedData.membership_type}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </OffcanvasBody>
              </>
            )}
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FamilyMembers
