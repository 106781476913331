import React from "react"
import Button from "@mui/material/Button"
import * as FileSaver from "file-saver"
import XSLX from "sheetjs-style"
import { Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import { color } from "@mui/system"
const ExportExcel = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"
  const exportToExcel = async () => {
    const ws = XSLX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XSLX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  return (
    <>
      <Tooltip title="Excel Export ">
        <Link to="#!" onClick={exportToExcel} className="btn btn-success me-1">
          Export
        </Link>
      </Tooltip>
    </>
  )
}

export default ExportExcel
