import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { useSelector, useDispatch } from "react-redux"
import {
    CardBody,
    Card,
    Row,
    Col,
    Modal,
    UncontrolledTooltip,
    Button,
    Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//import components
import TableContainer from "components/Common/TableContainer"

// slice
import {
    resetEvent,
    editUser,
    fetchEvent,
    deleteEvent,
    activeInactiveEvent,
    PublishEvent,
} from "../../../toolkitStore/eventMSlice/eventSlice"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

function UpcomingEvents(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //Delete
    const [modal_backdrop, setmodal_backdrop] = useState(false)
    const [delete_data, SetDelete_data] = useState()
    const [publish_data, SetPublish_data] = useState()
    const [modalFor, SetmodalFor] = useState()

    const { newEventList, isSuccessfullyAdded } = useSelector(
        state => state.EventReducer
    )

    useEffect(() => {
        if (isSuccessfullyAdded) {
            dispatch(fetchEvent())
        }
        dispatch(resetEvent())
    }, [modal_backdrop, isSuccessfullyAdded])
    useEffect(() => {
        localStorage.removeItem("event_id")
        localStorage.removeItem("venue_id")
        dispatch(fetchEvent())
    }, [])

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop)
        removeBodyCss()
    }

    const CloseandCancel = () => {
        // console.log("ii")
        tog_backdrop()
        SetDelete_data()
        SetPublish_data()
    }

    const columns = useMemo(
        () => [
            {
                Header: "Event Name",
                accessor: "name" || "event_name",
                filterable: false,
                Cell: cellProps => {
                    return (
                        <div className="" style={{ fontWeight: 600 }}>
                            {cellProps.row.original.name}
                        </div>
                    )
                },
            },
            {
                Header: "Event Date",
                accessor: "start_date",
                filterable: false,
                Cell: cellProps => {
                    console.log("event date", cellProps.row.original.start_date)
                    let sd = moment
                        .parseZone(cellProps.row.original.start_date)
                        .format("LLLL")
                    // let sd = moment(cellProps.row.original.start_datetime)
                    //   .utc(cellProps.row.original.start_datetime)
                    //   .tz("America/Chicago")
                    //   .format("LLLL")
                    return <div>{sd}</div>
                },
            },
            {
                Header: "Type",
                accessor: "type",
            },
            {
                Header: "Event For",
                accessor: "event_for",
            },
            {
                Header: "Number of Days",
                accessor: "no_of_days",
            },
            {
                Header: "Venue",
                filter: false,
                Cell: cellProps => {
                    const SData = cellProps.row.original
                    return (
                        <Link
                            to="/eventvenues"
                            className="btn btn-sm btn-primary"
                            onClick={e => {
                                let PassingData = {
                                    EventName: SData.name,
                                    EventId: SData._id,
                                    EventDay: SData.no_of_days,
                                }
                                // console.log(PassingData)

                                localStorage.setItem("event_id", JSON.stringify(PassingData))
                            }}
                            id="venuetooltip"
                        >
                            Venue
                        </Link>
                    )
                },
            },


        ],
        []
    )
    // console.log(newEventList)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs
                        title="Event Management"
                        breadcrumbItem="Event Management"
                    />

                    <Row className="justify-content-center">
                        <Card>
                            <CardBody className="">
                                <div className="d-flex justify-content-end">
                                    <div className="mb-1 flex-shrink-0">
                                        {/* <ExportExcel excelData={newEventList} fileName={"Event"} /> */}

                                        <Link
                                            to="#!"
                                            onClick={() => {
                                                dispatch(fetchEvent())
                                            }}
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                                <Row>
                                    <div className="container-fluid">
                                        <TableContainer
                                            columns={columns}
                                            data={newEventList}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            customPageSize={30}
                                            isJobListGlobalFilter={false}
                                            isShowPageSize={false}
                                            isColumnFilterEnabled={false}
                                        // className="custom-header-css"
                                        />
                                    </div>
                                </Row>
                                {newEventList.length > 7 ? (
                                    <Row>
                                        <Col xs="12">
                                            <div className="text-center my-3">
                                                <Link to="#" className="text-success">
                                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                                    Load more
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Row>

                    <div>
                        <Modal
                            isOpen={modal_backdrop}
                            toggle={tog_backdrop}
                            backdrop={"static"}
                            id="staticBackdrop"
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    {modalFor}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={CloseandCancel}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body font-size-15">
                                <p>Do you want {modalFor} this Event ?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => {
                                        if (modalFor === "Delete") {
                                            dispatch(deleteEvent(delete_data))
                                        } else {
                                            dispatch(PublishEvent(publish_data))
                                        }
                                        tog_backdrop()
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={CloseandCancel}
                                >
                                    No
                                </button>
                            </div>
                        </Modal>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UpcomingEvents
