import React, { useState, useEffect } from "react"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Label,
  Input,
  Container,
  FormFeedback,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// ck editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import {
  EditNewsdata,
  createNewsLetter,
  updateNewsLetter,
  // editUser
} from "../../../toolkitStore/NewsSlice/NewsLetterSlice"
import { createDonate, updateDonate } from './../../../toolkitStore/DonateSlice'
import { APIServerIP } from "helpers/Sats_api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Publish } from "@mui/icons-material"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import { memberList, NewsLetterList } from "toolkitStore/memberSlice"

function Donate(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EditnewsDetails, NewsLetterList, NewsLetterisSuccessfully } =
    useSelector(state => state.NewsLetterReducer)
  //Delete
  const [selectedData, SetSelectData] = useState()
  const [indiviualArray, setIndiviualArray] = useState()
  // const [Ckdata,setCkdata]=useState("")

  //image

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    if (NewsLetterisSuccessfully) {
      //     // localStorage.removeItem("publishnewsletter")
      //     // validation.resetForm()
      // navigate("/sendnewsletter")
      SetSelectData(EditnewsDetails)
    }
  }, [NewsLetterisSuccessfully])

  useEffect(() => {
    SetSelectData()
    const EU = localStorage?.getItem("publishnewsletter")
    // console.log("Retrieved Data:", EU)
    if (localStorage.getItem("publishnewsletter")) {
      dispatch(EditNewsdata({ _id: EU }))
      SetSelectData(EditnewsDetails)
    } else {
      // console.log("Retrieved empty:", EU)
      SetSelectData()
    }
  }, [])
  //   useEffect(() => {
  //       SetSelectData(EditnewsDetails)
  // }, [EditnewsDetails])

  //   console.log(EditnewsDetails)

  const Cancel = e => {
    e.preventDefault()
    // localStorage.removeItem("publishnewsletter")
    // validation.resetForm()
    // SetSelectData()
    navigate("/donate")
  }
  // if (selectedData?.mailto) {
  //   console.log(selectedData?.mailto)
  //   const mailttt = selectedData?.mailto
  //     .map((item, ind) => {
  //       return item.email
  //     })
  //     .join()
  //   console.log(mailttt)
  // }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: selectedData?.firstname || "",
      lastname: selectedData?.lastname || "",
      email: selectedData?.email || "",
      mobile: selectedData?.mobile || "",
      address: selectedData?.address || "",
      city: selectedData?.city || "",
      country: selectedData?.country || "",
      country_code: selectedData?.country_code || "",
      state: selectedData?.state || "",
      zipcode: selectedData?.zipcode || "",
      project_chapter: selectedData?.project_chapter || "",
      donate_amount: selectedData?.donate_amount || ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("First Name is required"),
      lastname: Yup.string().required("Last Name is required"),
      email: Yup.string().required("Emailis required"),
      project_chapter: Yup.string().required("Project chapter is required"),
      city: Yup.string().required("City is required"),
      country: Yup.string().required("Country is required"),
      zipcode: Yup.string().required("zipcode is required"),
      donate_amount: Yup.string().required("Donate amount is required"),
      //   mail_content: Yup.string().required("mail Content is required"),
    }),
    onSubmit: values => {
      // console.log(values)
      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        dispatch(updateDonate(values))
        navigate("/donate")
      } else {
        values["mailto"] = indiviualArray
        dispatch(createDonate(values))
        navigate("/donate")
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Add Donation" />
          <Card>
            <CardBody>
              <div className="mt-0 mb-3 d-flex align-items-center border-secondary border-bottom pb-2 ">
                <h5 className="mb-1 card-title flex-grow-1">Form</h5>
                <div className="flex-shrink-0 mb-1">
                  <Link
                    to="/donate"
                    onClick={e => {
                      Cancel(e)
                    }}
                    className="btn btn-dark me-1"
                  >
                    Back
                  </Link>
                </div>
              </div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="mt-4">
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="firstname">First Name</Label>
                      <Input
                        name="firstname"
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstname || ""}
                        invalid={
                          validation.touched.firstname &&
                            validation.errors.firstname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstname &&
                        validation.errors.firstname ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.firstname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="lastname">Last Name</Label>
                      <Input
                        name="lastname"
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastname || ""}
                        invalid={
                          validation.touched.lastname &&
                            validation.errors.lastname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastname &&
                        validation.errors.lastname ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email &&
                            validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email &&
                        validation.errors.email ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="mobile">Mobile</Label>
                      <Input
                        name="mobile"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mobile || ""}
                        invalid={
                          validation.touched.mobile &&
                            validation.errors.mobile
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mobile &&
                        validation.errors.mobile ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.mobile}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="address">Address</Label>
                      <Input
                        name="address"
                        className="form-control"
                        placeholder="Enter Address"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                          validation.touched.address &&
                            validation.errors.address
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address &&
                        validation.errors.address ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.address}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {/* <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="address2">Address</Label>
                      <Input
                        name="address2"
                        className="form-control"
                        placeholder="Enter Address"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address2 || ""}
                        invalid={
                          validation.touched.address2 &&
                            validation.errors.address2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address2 &&
                        validation.errors.address2 ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.address2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  <Col md={3}>
                    <div className="mb-3">
                      <Label htmlFor="country">Country</Label>
                      <Input
                        name="country"
                        className="form-control"
                        placeholder="Enter Country"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.country || ""}
                        invalid={
                          validation.touched.country &&
                            validation.errors.country
                            ? true
                            : false
                        }
                      />
                      {validation.touched.country &&
                        validation.errors.country ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.country}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label htmlFor="state">State</Label>
                      <Input
                        name="state"
                        className="form-control"
                        placeholder="Enter State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state &&
                            validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state &&
                        validation.errors.state ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label htmlFor="city">City</Label>
                      <Input
                        name="city"
                        className="form-control"
                        placeholder="Enter City"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.city || ""}
                        invalid={
                          validation.touched.city &&
                            validation.errors.city
                            ? true
                            : false
                        }
                      />
                      {validation.touched.city &&
                        validation.errors.city ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.city}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label htmlFor="zipcode">Zipcode</Label>
                      <Input
                        name="zipcode"
                        className="form-control"
                        placeholder="Enter zipcode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.zipcode || ""}
                        invalid={
                          validation.touched.zipcode &&
                            validation.errors.zipcode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.zipcode &&
                        validation.errors.zipcode ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.zipcode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="project_chapter">Donate For</Label>
                      <Input
                        name="project_chapter"
                        className="form-control"
                        placeholder="Donate for"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.project_chapter || ""}
                        invalid={
                          validation.touched.project_chapter &&
                            validation.errors.project_chapter
                            ? true
                            : false
                        }
                      />
                      {validation.touched.project_chapter &&
                        validation.errors.project_chapter ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.project_chapter}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="donate_amount">Donate Amount</Label>
                      <Input
                        name="donate_amount"
                        className="form-control"
                        placeholder="Enter Donate amount"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.donate_amount || ""}
                        invalid={
                          validation.touched.donate_amount &&
                            validation.errors.donate_amount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.donate_amount &&
                        validation.errors.donate_amount ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.donate_amount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="my-3 justify-content-end px-2 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger w-md px-5"
                      onClick={e => Cancel(e)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Row>

              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Donate




{/* <div className="mb-3 col-12 ">
                      <Label htmlFor="bulk_mail">Bulk to</Label>
                      <select
                        id="bulk_mail"
                        name="bulk_mail"
                        className={`form-select ${
                          validation.touched.bulk_mail &&
                          validation.errors.bulk_mail &&
                          "is-invalid"
                        }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bulk_mail}
                      >
                        <option value={""}>Select Mail to</option>
                        <option value={"Individual"}>Individual</option>
                        <option value={"All Members"}>All members</option>
                        <option value={"All Subscribers"}>
                          All Subscribers
                        </option>
                        <option value={"All Members & Subscribers"}>
                          All members & Subscribers
                        </option>
                      </select>
                      {validation.touched.bulk_mail &&
                      validation.errors.bulk_mail ? (
                        <div
                          type="invalid"
                          className="invalid-feedback"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.bulk_mail}
                        </div>
                      ) : null}
                    </div>
                    {validation.values.bulk_mail === "Individual" && (
                      <div className="mb-3 col-12  ">
                        <Label htmlFor="mailto">Send to</Label>
                        <Input
                          name="mailto"
                          className="form-control"
                          placeholder="Enter Mail Id"
                          type="text"
                          onChange={e => {
                            const mailtoArray = e.target.value.split(",")
                            // console.log(mailtoArray[0])
                            const arr = mailtoArray.map(item => ({
                              email: item,
                              status: 0,
                            }))
                            setIndiviualArray(arr)
                            validation.setFieldValue("mailto", e.target.value)
                          }}
                          onBlur={validation.handleBlur}
                          // selectedData.mailto&&(selectedData.mailto.join())
                          value={validation.values.mailto}
                          invalid={
                            validation.touched.mailto &&
                            validation.errors.mailto
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mailto &&
                        validation.errors.mailto ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.mailto}
                          </FormFeedback>
                        ) : null}
                      </div>
                    )}
                  </Col>
                  <div className="mb-3 col-12 ">
                    <Label htmlFor="mail_content">Mail Content</Label>
                    <CKEditor
                      config={{
                        width: "100%",
                        placeholder: "Newsletter Content",
                        ckfinder: {
                          uploadUrl: `${APIServerIP}/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images`,
                        },
                      }}
                      editor={ClassicEditor}
                      data={`${validation.values.mail_content}`}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        // setCkdata(data)
                        validation.setFieldValue("mail_content", data)
                      }}
                    />
                  </div> */}
