import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../helpers/Sats_api_helper"
import axios from 'axios'

const fetchExecutiveApi = `${APIServerIP}committee/admin-fetch-current-committee`
const fetchPerviousApi = `${APIServerIP}committee/admin-fetch-previous-committee`

const createExecutiveApi = `${APIServerIP}committee/create-committee-member`
const updateExecutiveApi = `${APIServerIP}committee/update-committee-member`
const activeInactiveExecutiveApi = `${APIServerIP}committee/active-inactive-committee-member`
const deleteExecutiveApi = `${APIServerIP}committee/delete-committee-member`

const initialState = {
  ExecutiveList: [],
  PerviousList: [],
  ExecutiveisSuccessfully: false,
}

const ExecutiveSlice = createSlice({
  name: "Executive",
  initialState: initialState,
  reducers: {
    setExecutiveList: (state, action) => {
      state.ExecutiveList = action.payload
    },
    setPerviousList: (state, action) => {
      state.PerviousList = action.payload
    },
    setExecutiveSuccessfullyAdded: (state, action) => {
      state.ExecutiveisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetExecutive: (state, action) => {
      state.ExecutiveisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const createExecutive = (values, image) => {
  return async dispatch => {
    // console.log("values", values, image)
    // const formData = new FormData()
    // formData.append("file", image)
    // formData.append("values", JSON.stringify(values))

    dispatch(resetExecutive())

    axios.post(createExecutiveApi, values)
      .then((res) => {
        console.log("res", res)
        if (res.status === 200) {
          dispatch(setExecutiveSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })

    // await fetch(createExecutiveApi, {
    //   method: "POST",
    //   headers: {
    //     accept: "application/json"
    //   },
    //   body: JSON.stringify(values),
    // })
      // .then(res => {
      //   // console.log("res", res)
      //   if (res.status === 200) {
      //     dispatch(setExecutiveSuccessfullyAdded())
      //   }
      // })
      // .catch(err => {
      //   console.log("try error", err)
      // })
  }
}

export const updateExecutive = (values, image) => {
  return async dispatch => {
    // console.log("Updatevalues", values, image)
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))
    dispatch(resetExecutive())
    await fetch(updateExecutiveApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        // console.log("Updateres", res)
        if (res.status == 200) {
          dispatch(setExecutiveSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}

export const deleteExecutive = id => {
  return async dispatch => {
    dispatch(resetExecutive())
    try {
      const responseData = await post(deleteExecutiveApi, id)

      if (responseData.response_code === 200) {
        // console.log("Executive user", responseData)
        dispatch(setExecutiveSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveExecutive = data => {
  return async dispatch => {
    dispatch(resetExecutive())
    try {
      const responseData = await post(activeInactiveExecutiveApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setExecutiveSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

// export const editUser = data => {
//   return async dispatch => {
//     try {
//       localStorage.setItem("editUser", JSON.stringify(data))
//       dispatch(setUserEdit())
//     } catch (err) {
//       console.log("editUsererr", err)
//     }
//   }
// }

export const fetchExecutive = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchExecutiveApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setExecutiveList(responseData.data))
        dispatch(resetExecutive())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const fetchPervious = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchPerviousApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setPerviousList(responseData.data))
        dispatch(resetExecutive())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setExecutiveList,
  setExecutiveSuccessfullyAdded,
  setUserEdit,
  resetExecutive,
  setPerviousList,
} = ExecutiveSlice.actions

export default ExecutiveSlice.reducer
