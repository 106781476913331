import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  Table,
  FormFeedback,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createSponsor,
  updateSponsor,
  deleteSponsor,
  activeInactiveSponsor,
  fetchSponsor,
  // editUser
} from "../../toolkitStore/SponsorSlice"

import { useFormik } from "formik"
import * as Yup from "yup"
import APIServerIP from "helpers/Sats_api_helper"

// import { memberList, SponsorList } from "toolkitStore/memberSlice"

function MalligaiMalar(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [imagec, SetImage] = useState()

  //image
  const { SponsorList, SponsorisSuccessfully } = useSelector(
    state => state.SponsorReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchSponsor())
    console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchSponsor())
    if (SponsorisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [SponsorisSuccessfully])

  const CloseandCancel = () => {
    console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sponsor’s Name",
        accessor: "sp_name",
      },
      {
        Header: "Contact Person",
        accessor: "sp_ct_person",
      },
      {
        Header: "Conatct Number",
        accessor: "sp_phone",
      },
      {
        Header: "Logo ",
        accessor: "logo",
        Cell: cellProps => {
          let { logo } = cellProps.row.original
          return (
            <ul className="list-unstyled">
              {logo ? (
                <li>
                  <img
                    src={`${APIServerIP}/assets/sponsorship/${logo}`}
                    style={{
                      height: "200px",
                      width: "150px",
                      objectFit: "contain",
                    }}
                  ></img>
                </li>
              ) : (
                <li>Null</li>
              )}
            </ul>
          )
        },
      },

      {
        Header: "status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled">
              <li>
                {cellProps.row.original.status === 0 ? "In Active" : "Active"}
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex justify-content-between mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info me-2"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger me-2"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2 me-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveSponsor(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      membership: selectedData?.membership || "", //number 0 and 1
      membership_category: selectedData?.membership_category || "",
      // corporate: selectedData?.corporate || "",
      // individual: selectedData?.individual || "",
      sp_name: selectedData?.sp_name || "",
      sp_ct_person: selectedData?.sp_ct_person || "",
      sp_address: selectedData?.sp_address || "",
      sp_email: selectedData?.sp_email || "",
      sp_phone: selectedData?.sp_phone || "",
      logo: selectedData?.logo || "",
      make_visible_web: selectedData?.make_visible_web || false,
      website_url: selectedData?.website_url || "",
    },
    validationSchema: Yup.object({
      membership: Yup.string().required("membership is required"),
      sp_name: Yup.string().required("Name is required"),
      sp_ct_person: Yup.string().required("Contact Person is required"),
      sp_email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      sp_phone: Yup.string().required("Phone Number is required"),
      membership_category: Yup.string().required(
        "Membership level is required"
      ),
      logo: Yup.string().required("Logo is required"),
      website_url: Yup.string()
        .url("Invalid url format")
        .required("Website url is required"),
    }),
    onSubmit: values => {
      console.log(values)
      if (selectedData?._id) {
        console.log(values, selectedData?._id)
        values["_id"] = selectedData?._id
        dispatch(updateSponsor(values, imagec))
      } else {
        dispatch(createSponsor(values, imagec))
      }
    },
  })

  return (
    <>
      <div className="page-content">
        <Row className="justify-content-center">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center border-1 border-bottom ">
                <h5 className="mb-1 card-title flex-grow-1">Sponsors</h5>
                <div className="flex-shrink-0 mb-1">
                  {/* <Link
                    to="#!"
                    onClick={() => {
                     
                      toggleRightCanvas()
                      SetSelectData()
                    }}
                    className="btn btn-primary me-1"
                  >
                    Add Sponsor
                  </Link> */}
                  <Link
                    to="#!"
                    onClick={() => {
                      /*  dispatch(fetchServices(userInformation.m_company_id)) */
                    }}
                    className="btn btn-light me-1"
                  >
                    <i className="mdi mdi-refresh"></i>
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
          <Row>
            <div className="container-fluid">
              <TableContainer
                columns={columns}
                data={SponsorList}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={30}
                isJobListGlobalFilter={false}
                className="custom-header-css"
              />
            </div>
          </Row>
        </Row>
        <Offcanvas
          isOpen={isRight}
          scrollable
          direction="end"
          toggle={toggleRightCanvas}
        >
          <OffcanvasHeader toggle={toggleRightCanvas}>
            {selectedData?._id ? "Update Sponsor" : "Add Sponsor"}
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
              onSubmit={event => {
                event.preventDefault()
                validation.handleSubmit()
              }}
            >
              <Row className="col-12 ">
                <Col className="col-lg-12 d-flex flex-wrap justify-content-between rounded">
                  <div className="mb-3 col-12 ">
                    <Table borderless>
                      <tbody>
                        <tr>
                          <th scope="row" className="fw-bold">
                            Membership
                          </th>
                          <td>:</td>
                          <td className="fw-bold text-warning">
                            {validation.values.membership}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="fw-bold">
                            MemberShip Level
                          </th>
                          <td>:</td>
                          <td className="fw-bold text-warning">
                            {validation.values.membership_category}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {/* <div className="mb-3 col-12 ">
                    <Label htmlFor="membership">
                      Membership <span className="text-danger">*</span>
                    </Label>
                    <fieldset className=" ms-3 d-flex  flex-column  ">
                      <div>
                        <input
                          type="radio"
                          name="membership"
                          id="track"
                          className="me-2 form-check-input "
                          value={validation.values.membership || ""}
                          defaultChecked={
                            validation.values.membership !== "Corporate"
                          }
                          disabled
                          onClick={e => {
                            validation.setFieldValue("membership", "Corporate")
                          }}
                        />
                        <label htmlFor="track" className="me-3">
                          Corporate Membership
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="membership"
                          className="me-2 form-check-input"
                          id="event"
                          value={validation.values.membership || ""}
                          defaultChecked={
                            validation.values.membership !== "Individual"
                          }
                          disabled
                          onClick={e => {
                            validation.setFieldValue("membership", "Individual")
                          }}
                        />
                        <label htmlFor="event" className="me-3">
                          Individual Membership
                        </label>
                      </div>
                    </fieldset>
                    {validation.touched.membership &&
                    validation.errors.membership ? (
                      <div
                        type="invalid"
                        className="invalid-feedback"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {validation.errors.membership}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-12">
                    <Label htmlFor="membership_category">
                      {validation.values.membership === "Corporate"
                        ? "Corporate Membership"
                        : "Individual membership"}
                      <span className="text-danger">*</span>
                    </Label>
                    <select
                      id="membership_category"
                      name="membership_category"
                      className={`form-select ${
                        validation.touched.membership_category &&
                        validation.errors.membership_category &&
                        "is-invalid"
                      }`}
                      value={validation.values.membership_category}
                      onChange={e =>
                        validation.setFieldValue(
                          "membership_category",
                          e.target.value
                        )
                      }
                    >
                      <option value={" "} disabled>
                        Select Your Level
                      </option>
                      <option value={"Platinum Level"}>Platinum Level</option>
                      <option value={"Gold Level"}>Gold Level</option>
                      <option value={"Silver Level"}>Silver Level</option>
                      {validation.values.membership !== "Corporate" && (
                        <option value={"Bronze Level"}>Bronze Level</option>
                      )}
                    </select>
                  </div>

                  {validation.touched.membership_category &&
                  validation.errors.membership_category ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      {validation.errors.membership_category}
                    </div>
                  ) : null} */}

                  <div className="mb-3 col-12">
                    <Label htmlFor="emasp_nameil">
                      Sponsor’s Name as it will appear in all printed material
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="sp_name"
                      className={`form-control`}
                      placeholder="Enter Your Sponser Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sp_name || ""}
                      invalid={
                        validation.touched.sp_name && validation.errors.sp_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.sp_name && validation.errors.sp_name ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.sp_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3 col-12">
                    <Label htmlFor="sp_ct_person">
                      Contact Person<span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="sp_ct_person"
                      className={`form-control`}
                      placeholder="Enter Your Contact Person"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sp_ct_person || ""}
                      invalid={
                        validation.touched.sp_ct_person &&
                        validation.errors.sp_ct_person
                          ? true
                          : false
                      }
                    />
                    {validation.touched.sp_ct_person &&
                    validation.errors.sp_ct_person ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.sp_ct_person}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3 col-12">
                    <Label htmlFor="sp_address">Address</Label>
                    <Input
                      name="sp_address"
                      className="form-control  "
                      placeholder="Enter Your Address"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sp_address || ""}
                      invalid={
                        validation.touched.sp_address &&
                        validation.errors.sp_address
                          ? true
                          : false
                      }
                    />
                    {validation.touched.sp_address &&
                    validation.errors.sp_address ? (
                      <FormFeedback type="invalid">
                        {validation.errors.sp_address}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3 col-12">
                    <Label htmlFor="sp_email">Email</Label>
                    <Input
                      name="sp_email"
                      className={`form-control`}
                      placeholder="Enter Your Email"
                      type="sp_email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sp_email || ""}
                      invalid={
                        validation.touched.sp_email &&
                        validation.errors.sp_email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.sp_email &&
                    validation.errors.sp_email ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.sp_email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3 col-12">
                    <Label htmlFor="sp_phone">Phone</Label>
                    <Input
                      name="sp_phone"
                      className="form-control  "
                      placeholder="Enter your Phone Number"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sp_phone || ""}
                      invalid={
                        validation.touched.sp_phone &&
                        validation.errors.sp_phone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.sp_phone &&
                    validation.errors.sp_phone ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.sp_phone}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3 col-12  ">
                    <Label htmlFor="logo">Upload Logo</Label>
                    <Input
                      name="logo"
                      className="form-control"
                      placeholder="Upload photo"
                      type="file"
                      onChange={e => {
                        console.log(e.currentTarget.files[0].name)
                        validation.setFieldValue(
                          "logo",
                          e.currentTarget.files[0].name
                        )
                        SetImage(e.currentTarget.files[0])
                      }}
                      invalid={
                        validation.touched.logo && validation.errors.logo
                          ? true
                          : false
                      }
                    />
                    {validation.touched.logo && validation.errors.logo ? (
                      <div
                        type="invalid"
                        className="invalid-feedback"
                        style={{ fontSize: "12px" }}
                      >
                        {validation.errors.logo}
                      </div>
                    ) : null}
                    {<span className="text-info">{selectedData?.logo}</span>}
                  </div>
                  <div className="mb-3 col-12">
                    <Label htmlFor="website_url">Website Url</Label>
                    <Input
                      name="website_url"
                      className={`form-control`}
                      placeholder="Enter Website url"
                      type="website_url"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.website_url || ""}
                      invalid={
                        validation.touched.website_url &&
                        validation.errors.website_url
                          ? true
                          : false
                      }
                    />
                    {validation.touched.website_url &&
                    validation.errors.website_url ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.website_url}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className=" mb-3 pt-1  ps-5 col-12 form-check">
                    <Label htmlFor="make_visible_web ">
                      Make Visible On Web
                    </Label>
                    <Input
                      name="make_visible_web"
                      className="form-check-input p-2 me-2"
                      type="checkbox"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.make_visible_web}
                      value={validation.values.make_visible_web || ""}
                      invalid={
                        validation.touched.make_visible_web &&
                        validation.errors.make_visible_web
                          ? true
                          : false
                      }
                    />
                    {validation.touched.make_visible_web &&
                    validation.errors.make_visible_web ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.make_visible_web}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <Col className=" mt-3 justify-content-around px-2 d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-light w-md px-5"
                      onClick={toggleRightCanvas}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary w-md px-5">
                      {selectedData?._id ? "Update" : "Submit"}
                    </button>
                  </Col>
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
        <Modal
          isOpen={modal_backdrop}
          toggle={tog_backdrop}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {"Delete"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={CloseandCancel}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body font-size-15">
            <p>Do you want Delete this Sponsor ,Then confirm It ....!</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                // dispatch(deleteEvent(delete_data))
                dispatch(deleteSponsor(delete_data))

                tog_backdrop()
              }}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={CloseandCancel}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default MalligaiMalar
