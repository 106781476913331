import React, { useMemo, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import {
  Alert,
  CardBody,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Card,
  Col,
  CardTitle,
  Badge,
  Modal,
  UncontrolledTooltip,
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader, InputGroup
} from "reactstrap"
import * as Yup from "yup"
import { useFormik, Field } from "formik"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//import components
import TableContainer from "components/Common/TableContainer"
import { useEffect } from "react"

// slice
import {
  fetchBookingDetails,
  deleteBookingDetails,
  createBookingDetails,
  updateBookingDetails,
  resetBookingDetails,
} from "toolkitStore/eventMSlice/SeatAllocationSlice"
import SeatAllocation from "./SeatAllocation"

function SeatAllocationList(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)
  const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
  // console.log("venue_id", venue_id_c)

  const { BookingDetailsList, BookingDetailsisSuccessfully, isUserEdit } =
    useSelector(state => state.SeatAllocationReducer)

  //Offacanvas
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()

  const [selectedData, SetSelectData] = useState()
  const [is_reserved, setIsReserved] = useState(false)

  const [isBookTypeSelected, setBookTypeSelected] = useState(false)


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  useEffect(() => {
    if (BookingDetailsisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
    dispatch(resetBookingDetails())
  }, [BookingDetailsisSuccessfully])

  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("editUser")
    SetSelectData("")
    setIsRight(false)
  }

  useEffect(() => {
    dispatch(
      fetchBookingDetails({
        master_event_id: event_id_c.EventId,
        master_venue_id: venue_id_c.VenueId,
      })
    )
  }, [isRight, modal_backdrop])

  const columns = useMemo(
    () => [
      {
        Header: "Booking Type",
        accessor: "booking_type",
        disableFilters: true,
      },
      // {
      //   Header: "Number of Tickets",
      //   accessor: "no_of_tickets",
      //   disableFilters: true,
      // },
      {
        Header: "Adult Ticket Price",
        accessor: "adult_price",
        disableFilters: true,
        Cell: cellProps => {
          return <div>$ {cellProps.row.original.adult_price}</div>
        },
      },

      {
        Header: "Kids Ticket Price",
        accessor: "kids_price",
        disableFilters: true,
        Cell: cellProps => {
          return <div>$ {cellProps.row.original.kids_price}</div>
        },
      },
      {
        Header: "Non Member Adult Ticket Price",
        accessor: "n_adult_price",
        disableFilters: true,
        Cell: cellProps => {
          return <div>$ {cellProps.row.original.n_adult_price}</div>
        },
      },

      {
        Header: "Non Member Kids Ticket Price",
        accessor: "n_kids_price",
        disableFilters: true,
        Cell: cellProps => {
          return <div>$ {cellProps.row.original.n_kids_price}</div>
        },
      },
      // {
      //   Header: "Reservation Status",
      //   accessor: "is_reserved",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     let res = cellProps.row.original
      //     console.log(typeof res.kids_price)
      //     return (
      //       <ul className="list-unstyled  mb-0">
      //         {res.is_reserved === "true" ? (
      //           <li>
      //             <div className="text-center">
      //               <span className="badge rounded-pill badge-soft-success font-size-12 px-3">
      //                 Reserved
      //               </span>
      //             </div>
      //           </li>
      //         ) : (
      //           <li>
      //             <div className="text-center">
      //               <span className="badge rounded-pill badge-soft-warning font-size-12 px-3">
      //                 Not Reserved
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //       </ul>
      //     )
      //   },
      // },

      {
        Header: "Action",
        accessor: "event_status",
        disableFilters: true,

        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <Link
                to="#!"
                className="btn btn-sm btn-soft-info"
                style={new Date(SData.end_date) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                onClick={e => {
                  e.preventDefault()
                  toggleRightCanvas()
                  SetSelectData(SData)
                  return false
                }}
              >
                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-soft-danger"
                style={new Date(SData.end_date) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                onClick={e => {
                  e.preventDefault()
                  const SData = cellProps.row.original
                  // console.log("delete", SData._id)
                  tog_backdrop()
                  SetDelete_data({ _id: SData._id })
                  return false
                }}
              >
                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      master_event_id: selectedData?.master_event_id || event_id_c.EventId,
      master_venue_id: selectedData?.master_venue_id || venue_id_c.VenueId,
      category: selectedData?.category || "",
      no_of_tickets: selectedData?.no_of_tickets || "",
      adult_price: selectedData?.adult_price || "",
      kids_price: selectedData?.kids_price || "",
      n_adult_price: selectedData?.n_adult_price || "",
      n_kids_price: selectedData?.n_kids_price || "",
      is_reserved: selectedData?.is_reserved || false,
      active_status: selectedData?.active_status || "",
      booking_type: selectedData?.booking_type || "",
      start_date: selectedData?.start_date || "",
      end_date: selectedData?.end_date || "",
    },
    validationSchema: Yup.object({
      //  category: Yup.string().required(" select Your Category type "),
      // no_of_tickets: Yup.string().required("Enter The Number of Tickets"),
      // is_reserved: Yup.string().required("Enter The reserved"),

      // adult_price: Yup.string().when("is_reserved", {
      //   is: false,
      //   then: Yup.string().required("Enter The Adult tickets price"),
      //   otherwise: Yup.string(),
      // }),
      // kids_price: Yup.string().when("is_reserved", {
      //   is: false,
      //   then: Yup.string().required("Enter The Kids tickets price"),
      //   otherwise: Yup.string(),
      // }),

      adult_price: Yup.string().required("Enter Member Adult Price"),
      kids_price: Yup.string().required("Enter Member Kids Price"),
      n_adult_price: Yup.string().required("Enter Non Member Adult Price"),
      n_kids_price: Yup.string().required("Enter Non Member Kids Price"),

      booking_type: Yup.string().required("Select Booking Type"),
      start_date: Yup.date().required("Start Date is required"),
      end_date: Yup.date().required("End Date is required")
    }),
    onSubmit: (values, actions) => {

      console.log("values", values);


      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        dispatch(updateBookingDetails(values))
      } else {
        dispatch(createBookingDetails(values))
      }

      // console.log(values)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="TICKETS ALLOCATION" />
          <Row className="">
            <Card>
              <CardBody className="border-botton">
                <div className="d-flex align-items-center">
                  <div className="mb-1 flex-grow-1 ">
                    <h5 className="card-title text-primary">
                      {event_id_c.EventName + " / " + venue_id_c.VenueName}
                    </h5>
                    <div>Maximum Tickets - {venue_id_c.MaxTicket}</div>
                  </div>

                  <div className="mb-1 flex-shrink-0">
                    <Link
                      to="#!"
                      onClick={e => {
                        setIsRight(true)
                        setBookTypeSelected(false)
                        localStorage.removeItem("editUser")
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add Ticket allocation
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-secondary me-1"
                      onClick={e => {
                        e.preventDefault()
                        history.back()
                      }}
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(
                          fetchBookingDetails({
                            master_event_id: event_id_c.EventId,
                            master_venue_id: venue_id_c.VenueId,
                          })
                        )
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={BookingDetailsList}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
                {BookingDetailsList.length > 10 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>

          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              style={{ borderBottom: "1px solid #dedede" }}
            >
              Add / Edit Ticket Allocation
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={event => {
                  event.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row >

                  <div className="row">
                    <div className="py-1"><label className="text-secondary text-opacity-75">Booking Type</label></div>
                    <div className="mb-3 py-2 col-12 d-flex flex-row align-items-center" style={{ borderRadius: 8, marginLeft: 10, border: "1px solid #ced4da" }}>
                      <div className="me-3 d-flex justify-content-center">
                        <input type="radio" id="early-bird" className="me-2" name="booking_type" value="early bird"
                          onChange={e => {
                            validation.setFieldValue("booking_type", e.target.value)
                            setBookTypeSelected(true)
                          }}
                        />
                        <label htmlFor="early-bird" style={{ marginBottom: 0 }}>Early Bird</label>
                      </div>
                      <div className="d-flex justify-content-center">
                        <input style={{ marginRight: '10px' }} type="radio" id="standard" name="booking_type" value="standard"
                          onChange={e => {
                            validation.setFieldValue("booking_type", e.target.value)
                            setBookTypeSelected(true)
                          }}
                        />
                        <label htmlFor="standard" style={{ marginBottom: 0 }}>Standard</label>
                      </div>
                    </div>
                  </div>

                  {
                    isBookTypeSelected &&
                    <div>
                      <Col className="col-12 d-flex flex-wrap justify-content-between">

                        {/* <div className="mb-3 col-12">
                          <Label htmlFor="category">Category</Label>
                          <select
                            id="category"
                            name="category"
                            className={`form-select ${validation.touched.category &&
                              validation.errors.category &&
                              "is-invalid"
                              }`}
                            value={validation.values.category || ""}
                            onChange={e => validation.setFieldValue("category", e.target.value)}
                          >
                            <option value={""}>Select Category </option>
                            <option value={"Member"}>Member</option>
                            <option value={"Non-Member"}>Non-Member</option>
                          </select>
                          {validation.touched.category &&
                            validation.errors.category ? (
                            <FormFeedback>
                              {validation.errors.category}
                            </FormFeedback>
                          ) : null}
                        </div> */}

                        <div className="mb-3 col-12">
                          <Label htmlFor="start_date" className="text-secondary text-opacity-75">Booking Started On</Label>
                          <Input
                            name="start_date"
                            className={`form-control`}
                            type="date"
                            defaultValue={validation.values.start_date || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.start_date &&
                                validation.errors.start_date
                                ? true
                                : false
                            }
                          />
                          {validation.touched.start_date &&
                            validation.errors.start_date ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="row p-2 m-0 mb-3" style={{ borderRadius: 8, border: "1px solid #ced4da" }}>
                          <div className="py-1"><label className="text-secondary text-opacity-75">Member Ticket Price</label></div>
                          <div className="mb-3 col-6">
                            <div className="">
                              <label htmlFor="adult_price">Adult</label>
                              <InputGroup>
                                <div className="input-group-text">$</div>
                                <Input
                                  name="adult_price"
                                  className={`form-control`}
                                  placeholder="Price"
                                  type="number"
                                  min={1}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.adult_price || ""}
                                  invalid={
                                    validation.touched.adult_price &&
                                      validation.errors.adult_price
                                      ? true
                                      : false
                                  }
                                />

                              </InputGroup>
                              {validation.touched.adult_price &&
                                validation.errors.adult_price ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.adult_price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="mb-3 col-6">
                            <div>
                              <label htmlFor="kids_price">Kids</label>
                              <InputGroup>
                                <div className="input-group-text">$</div>

                                <Input
                                  name="kids_price"
                                  className={`form-control`}
                                  placeholder="Price"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.kids_price || ""}
                                  invalid={
                                    validation.touched.kids_price &&
                                      validation.errors.kids_price
                                      ? true
                                      : false
                                  }
                                />
                              </InputGroup>
                              {validation.touched.kids_price &&
                                validation.errors.kids_price ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.kids_price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row p-2 m-0 mb-3" style={{ borderRadius: 8, border: "1px solid #ced4da" }}>
                          <div className="py-1"><label className="text-secondary text-opacity-75">Non Member Ticket Price</label></div>
                          <div className="mb-3 col-6">
                            <div className="">
                              <label htmlFor="n_adult_price">Adult</label>
                              <InputGroup>
                                <div className="input-group-text">$</div>
                                <Input
                                  name="n_adult_price"
                                  className={`form-control`}
                                  placeholder="Price"
                                  type="number"
                                  min={1}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.n_adult_price || ""}
                                  invalid={
                                    validation.touched.n_adult_price &&
                                      validation.errors.n_adult_price
                                      ? true
                                      : false
                                  }
                                />

                              </InputGroup>
                              {validation.touched.n_adult_price &&
                                validation.errors.n_adult_price ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.n_adult_price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="mb-3 col-6">
                            <div>
                              <label htmlFor="n_kids_price">Kids</label>
                              <InputGroup>
                                <div className="input-group-text">$</div>

                                <Input
                                  name="n_kids_price"
                                  className={`form-control`}
                                  placeholder="Price"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.n_kids_price || ""}
                                  invalid={
                                    validation.touched.n_kids_price &&
                                      validation.errors.n_kids_price
                                      ? true
                                      : false
                                  }
                                />
                              </InputGroup>
                              {validation.touched.n_kids_price &&
                                validation.errors.n_kids_price ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.n_kids_price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 col-12">
                          <Label htmlFor="end_date" className="text-secondary text-opacity-75">Booking Ends On</Label>
                          <Input
                            name="end_date"
                            className={`form-control`}
                            type="date"
                            defaultValue={validation.values.end_date || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.end_date &&
                                validation.errors.end_date
                                ? true
                                : false
                            }
                          />
                          {validation.touched.end_date &&
                            validation.errors.end_date ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.end_date}
                            </FormFeedback>
                          ) : null}
                        </div>

                        {/* ) : null} */}
                      </Col>

                      <Col className="d-flex mt-3 justify-content-center">
                        <div className=" text-center mt-3 ">
                          <button
                            type="submit"
                            className="btn btn-success w-md px-5"
                          >
                            {selectedData?._id ? "Update" : "Submit"}
                          </button>
                        </div>
                        <div className=" text-center mt-3 mx-3 ">
                          <button
                            type="button"
                            onClick={Cancel}
                            className="btn btn-danger w-md px-5"
                          >
                            {"Cancel"}
                          </button>
                        </div>
                      </Col>

                    </div>
                  }


                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>

          {/*delete Modal */}
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={tog_backdrop}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Event ?</p>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-danger mx-3"
                onClick={() => {
                  dispatch(deleteBookingDetails(delete_data))
                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={tog_backdrop}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SeatAllocationList