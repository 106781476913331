import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { useSelector, useDispatch } from "react-redux"
import {
    Alert,
    CardBody,
    Card,
    Row,
    Col,
    CardTitle,
    Badge,
    Modal,
    UncontrolledTooltip,
    Button,
    Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//import components
import TableContainer from "components/Common/TableContainer"
// slice

import { EditSeats, UpdateSeats } from "toolkitStore/SeatArrangement/SeatSlice"
import {
    fetchVenue,
    editUser,
    deleteVenue,
    activeInactiveVenue,
} from "../../../toolkitStore/eventMSlice/VenueSlice"

function EventVenues(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //Delete
    const [modal_backdrop, setmodal_backdrop] = useState(false)
    const [delete_data, SetDelete_data] = useState()
    const event_id_c = JSON.parse(localStorage.getItem("event_id"))
    // console.log("event_id", event_id_c)

    const { VenueList, VenueisSuccessfully } = useSelector(
        state => state.VenueReducer
    )

    useEffect(() => {
        if (VenueList) {
            dispatch(fetchVenue(event_id_c.EventId))
        }
    }, [setmodal_backdrop, VenueisSuccessfully])

    useEffect(() => {
        localStorage.removeItem("venue_id")
        dispatch(fetchVenue(event_id_c.EventId))
    }, [])

    // console.log(VenueList)
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop)
        removeBodyCss()
    }

    const columns = useMemo(
        () => [
            {
                Header: "Venue",
                accessor: "venue",
                disableFilters: true,
            },
            {
                Header: "Started on",
                accessor: "start_datetime",
                disableFilters: true,
                Cell: cellProps => {
                    let sd = moment
                        .parseZone(cellProps.row.original.start_datetime)
                        .format("YYYY-MM-DD  hh:mm A")
                    // let sd = moment(cellProps.row.original.start_datetime)
                    //   .utc(cellProps.row.original.start_datetime)
                    //   .tz("America/Chicago")
                    //   .format("LLLL")
                    return (
                        <ul className="list-unstyled">
                            <p>{sd}</p>
                        </ul>
                    )
                },
            },
            {
                Header: "Ends on",
                accessor: "end_datetime",
                disableFilters: true,
                Cell: cellProps => {
                    let sd = moment(cellProps.row.original.end_datetime).format(
                        "YYYY-MM-DD  hh:mm A"
                    )

                    return (
                        <ul className="list-unstyled">
                            <p>{sd}</p>
                        </ul>
                    )
                },
            },

            {
                Header: "Max tickets",
                accessor: "max_tickets",
                disableFilters: true,
            },
            {
                Header: "Tickets Booked",
                accessor: "ticket_sold",
                disableFilters: true,
                Cell: cellProps => (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                            <div className="me-2">{cellProps.row.original.ticket_sold}</div>
                        </li>
                        <li>
                            <Link
                                to="/bookinghistory"
                                className="btn btn-sm btn-info"
                                onClick={e => {
                                    const SData = cellProps.row.original
                                    const PassingData = {
                                        VenueName: SData.venue,
                                        VenueId: SData._id,
                                        MaxTicket: SData.max_tickets,
                                    }
                                    localStorage.setItem("venue_id", JSON.stringify(PassingData))
                                }}
                            >
                                Details
                            </Link>
                        </li>
                    </ul>
                ),
            },
            {
                Header: "Visited",
                accessor: "visited",
                disableFilters: true,
                Cell: cellProps => (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                            <div className="me-2">{cellProps.row.original.visited ? cellProps.row.original.visited : 0}</div>
                        </li>
                    </ul>
                ),
            },
            {
                Header: "Action",
                disableFilters: true,
                Cell: cellProps => {

                    var startDate = moment.parseZone(cellProps.row.original.start_datetime).format("YYYY-MM-DD  hh:mm A")
                    var endDate = moment.parseZone(cellProps.row.original.end_datetime).format("YYYY-MM-DD  hh:mm A")
                    var date = new Date()
                    // var rangee = moment().range(startDate, endDate);
                    var isGateOpen = moment(date).isBetween(startDate, endDate, 'days', true)
                    console.log("gateOpen", isGateOpen)
                    return (
                        <ul className="list-unstyled hstack  gap-3 mb-0">
                            <li>
                                <Link
                                    to={isGateOpen ? "/gatescanning" : "#"}
                                    className={isGateOpen ? "btn btn-sm btn-primary" : "btn btn-sm btn-secondary"}
                                    onClick={e => {
                                        e.preventDefault()
                                        if (isGateOpen) {
                                            const SData = cellProps.row.original
                                            const PassingData = {
                                                VenueName: SData.venue,
                                                VenueId: SData._id,
                                            }
                                            localStorage.setItem(
                                                "venue_id",
                                                JSON.stringify(PassingData)
                                            )
                                        }
                                    }}

                                >
                                    Gate Scanning
                                </Link><br />
                                <span className="font-size-12">* Gate will open on event date</span>
                            </li>

                        </ul>
                    )
                },
            },

        ],
        []
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Venues" breadcrumbItem="Venues" />
                    <Row className="justify-content-center">
                        <Card>
                            <CardBody className="border-botton">
                                <div className="d-flex align-items-center ">
                                    <h5 className="mb-1 card-title flex-grow-1 text-primary">
                                        {event_id_c.EventName}
                                    </h5>
                                    <div className="mb-1 flex-shrink-0">

                                        <Link
                                            to="#!"
                                            className="btn btn-secondary me-1"
                                            onClick={e => {
                                                e.preventDefault()
                                                navigate("/upcomingevents")
                                            }}
                                        >
                                            Back
                                        </Link>
                                        <Link
                                            to="#!"
                                            onClick={() => {
                                                dispatch(fetchVenue(event_id_c.EventId))
                                            }}
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                                <Row>
                                    <div className="container-fluid">
                                        <TableContainer
                                            columns={columns}
                                            data={VenueList}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            customPageSize={20}
                                            isJobListGlobalFilter={false}
                                            isColumnFilterEnabled={false}
                                        // className="custom-header-css"
                                        />
                                    </div>
                                </Row>
                                {VenueList.length > 10 ? (
                                    <Row>
                                        <Col xs="12">
                                            <div className="text-center my-3">
                                                <Link to="#" className="text-success">
                                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                                    Load more
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Row>
                    {/*delete Modal */}
                    <Modal
                        isOpen={modal_backdrop}
                        toggle={() => {
                            tog_backdrop()
                        }}
                        backdrop={"static"}
                        id="staticBackdrop"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Delete
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setmodal_backdrop(false)
                                }}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body font-size-15">
                            <p>Are you sure, you want to delete?</p>
                        </div>
                        <div className="modal-footer ">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                onClick={() => {
                                    dispatch(deleteVenue(delete_data))
                                    setmodal_backdrop(false)
                                    // dispatch(fetchVenue(event_id_c.EventId))
                                }}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    setmodal_backdrop(false)
                                }}
                            >
                                No
                            </button>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EventVenues
