import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import ExportExcel from "components/Excelconvert/ExportExcel"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  Table,
  FormFeedback,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createYouthWing,
  updateYouthWing,
  deleteYouthWing,
  activeInactiveYouthWing,
  fetchYouthWing,
  makeVisibleWeb,
  // editUser
} from "../../toolkitStore/YouthWingSlice"

import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import { memberList, YouthWingList } from "toolkitStore/memberSlice"

function YouthWing(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [imagec, SetImage] = useState()

  //image
  const { YouthWingList, YouthWingisSuccessfully } = useSelector(
    state => state.YouthWingReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchYouthWing())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchYouthWing())
    if (YouthWingisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [YouthWingisSuccessfully])

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Parent is SATS Member?",
        accessor: "parent_sats_member",
      },
      {
        Header: "Contact Number",
        accessor: "phone_no",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "School",
        accessor: "school",
      },

      {
        Header: "status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled  mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.status === 0
                      ? "badge badge-soft-danger"
                      : "badge badge-soft-success"
                  }
                >
                  {cellProps.row.original.status === 0 ? "Inactive" : "Active"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"

          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveYouthWing(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedData?.name || "",
      email: selectedData?.email || "",
      phone_no: selectedData?.phone_no || "",
      parent_sats_member: selectedData?.parent_sats_member || "",
      grade: selectedData?.grade || "",
      school: selectedData?.school || "",
      intrest: selectedData?.intrest || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone_no: Yup.string().required("Phone Number is required"),
      email: Yup.string()
        .email("Invalid mail Formate")
        .required("Email is required"),
      parent_sats_member: Yup.string().required("This is required"),
      grade: Yup.string().required("Your Grade is required"),
      school: Yup.string().required("Your School is required"),
      intrest: Yup.string().required("Area of Interest is required"),
    }),
    onSubmit: values => {
      dispatch(updateSponsor(values))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Youth Wing" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 card-title flex-grow-1"></h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel
                      excelData={YouthWingList}
                      fileName={"Sponsor's"}
                    />
                    {/* <Link
                    to="#!"
                    onClick={() => {
                     
                      toggleRightCanvas()
                      SetSelectData()
                    }}
                    className="btn btn-primary me-1"
                  >
                    Add Sponsor
                  </Link> */}
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={YouthWingList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {selectedData?._id ? "YouthWing Details" : "Add Sponsor"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={event => {
                  event.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="col-12 p-3">
                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Name
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-secondary"
                    >
                      {validation.values.name}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Mobile Number
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-secondary"
                    >
                      {validation.values.phone_no}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Email
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-secondary"
                    >
                      {validation.values.email}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Is your Parents SATS Member?
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-secondary"
                    >
                      {validation.values.parent_sats_member}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Grade
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-secondary"
                    >
                      {validation.values.grade}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      School
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-secondary"
                    >
                      {validation.values.school}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Area of Interest & Strength
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-secondary"
                    >
                      {validation.values.intrest}
                    </div>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Are you sure, you want to delete this wing?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteSponsor(delete_data))

                  tog_backdrop()
                }}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default YouthWing
