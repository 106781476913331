import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import ExportExcel from "components/Excelconvert/ExportExcel"
import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  Table,
  FormFeedback,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createDonate,
  updateDonate,
  deleteDonate,
  activeInactiveDonate,
  fetchDonate,
  makeVisibleWeb,
  // editUser
} from "../../toolkitStore/DonateSlice"

import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import { memberList, DonateList } from "toolkitStore/memberSlice"

function Donate(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [imagec, SetImage] = useState()

  //image
  const { DonateList, DonateisSuccessfully } = useSelector(
    state => state.DonateReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchDonate())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchDonate())
    if (DonateisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [DonateisSuccessfully])

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }

  const columns = useMemo(
    () => [
      {
        Header: "FirstName",
        accessor: "firstname",
      },
      {
        Header: "Lastname",
        accessor: "lastname",
      },
      {
        Header: "Contact Number",
        accessor: "mobile",
      },
      {
        Header: "Amount",
        accessor: "donate_amount",
      },
      {
        Header: "Project Chapter",
        accessor: "project_chapter",
      },

      {
        Header: "Payment Status",
        accessor: "payment_status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <div
                className={
                  cellProps.row.original.payment_status === "initiated"
                    ? "badge badge-soft-secondary"
                    : cellProps.row.original.payment_status === "failed"
                    ? "badge badge-soft-danger"
                    : "badge badge-soft-success"
                }
              >
                {cellProps.row.original.payment_status === "initiated"
                  ? "Initiated"
                  : cellProps.row.original.payment_status === "failed"
                  ? "Failed"
                  : "Success"}
              </div>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          let sdbtncolor =
            cellProps.row.original.status === 1
              ? "btn-soft-danger"
              : "btn-soft-success"

          return (
            <ul className="list-unstyled hstack d-flex gap-2 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )
  const Payment_status = ["initiated", "Success"]

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: selectedData?._id || "",
      firstname: selectedData?.firstname || "",
      lastname: selectedData?.lastname || "",
      email: selectedData?.email || "",
      mobile: selectedData?.mobile || "",
      address: selectedData?.address || "",
      address2: selectedData?.address2 || "",
      city: selectedData?.city || "",
      state: selectedData?.state || "",
      country_code: selectedData?.country_code || "",
      country: selectedData?.country || "",
      zipcode: selectedData?.zipcode || "",

      project_chapter: selectedData?.project_chapter || "",
      donate_amount: selectedData?.donate_amount || "",
      taxcheck: selectedData?.taxcheck || false,
      payment_status: selectedData?.payment_status || "",
    },
    validationSchema: Yup.object({
      payment_status: Yup.string().required("Select the payment status"),
    }),
    onSubmit: values => {
      // console.log(values)
      dispatch(updateDonate(values))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Donors" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 card-title flex-grow-1"></h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel excelData={DonateList} fileName={"Donation"} />
                    <Link
                      to="/adddonate"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-primary me-1"
                    >
                      Add Donate
                    </Link>
                    {/* <Link
                    to="#!"
                    onClick={() => {
                     
                      toggleRightCanvas()
                      SetSelectData()
                    }}
                    className="btn btn-primary me-1"
                  >
                    Add Sponsor
                  </Link> */}
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={DonateList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {selectedData?._id ? "Update Donate" : "Add Sponsor"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={event => {
                  event.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="col-12 p-3">
                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Name
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      {validation.values.firstname} {validation.values.lastname}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Contact Number
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      {validation.values.mobile}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Email
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      {validation.values.email}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Address
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      {validation.values.address},
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      {validation.values.address2},
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      {validation.values.city}, {validation.values.state},{" "}
                      {validation.values.country}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Amount
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      $ {validation.values.donate_amount}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Project Chapter
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-dark"
                    >
                      {validation.values.project_chapter}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <div
                      style={{ fontSize: 13 }}
                      className="text-secondary text-opacity-75"
                    >
                      Payment Status
                    </div>
                    <div
                      className={
                        validation.values.payment_status === "initiated"
                          ? "badge badge-soft-secondary"
                          : validation.values.payment_status === "failed"
                          ? "badge badge-soft-danger"
                          : "badge badge-soft-success"
                      }
                    >
                      {validation.values.payment_status}
                    </div>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Sponsor?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteDonate(delete_data))

                  tog_backdrop()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Donate
