import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../helpers/api_helper"
import { APIServerIP } from "../../src/helpers/Sats_api_helper"
import axios from 'axios'

const fetchSponsorApi = `${APIServerIP}sponsors/fetch-sponsorship`
const createSponsorApi = `${APIServerIP}sponsors/create-sponsorship`
const updateSponsorApi = `${APIServerIP}sponsors/update-sponsorship`
const activeInactiveSponsorApi = `${APIServerIP}member-type-lists/active-inactive-sponsorship`
const makeVisibleWebApi = `${APIServerIP}sponsors/make-visible-sponsorship`
const deleteSponsorApi = `${APIServerIP}sponsors/delete-sponsorship`
const getSponsorInfoApi = `${APIServerIP}sponsors/fetch-sponsor-info`
const sponsorTicketAllocationApi = `${APIServerIP}manage-booking-user/ticket-booking-for-sponsors`

const initialState = {
  SponsorList: [],
  SponsorisSuccessfully: false,
  sponsorship: null,
  sponsor_level: null
}

const SponsorSlice = createSlice({
  name: "Sponsor",
  initialState: initialState,
  reducers: {
    setSponsorList: (state, action) => {
      state.SponsorList = action.payload
    },
    setSponsorSuccessfullyAdded: (state, action) => {
      state.SponsorisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetSponsor: (state, action) => {
      state.SponsorisSuccessfully = false
      state.isUserEdit = false
    },

    sponsorInfo: (state, action) => {
      console.log("sponsor -->", action.payload)
      state.sponsorship = action.payload.sponsorship
      state.sponsor_level = action.payload.sponsor_level
    }
  },
})

export const sponsorTicketAllocation = (values, image) => {
  return async dispatch => {
    // console.log("values", values, image

    dispatch(resetSponsor())
    axios.post(sponsorTicketAllocationApi, values)
      .then(res => {
        // console.log("res", res)
        if (res.status === 200) {

        }
      })
      .catch(err => {
        console.log("try error", err)
      })
  }
}

export const createSponsor = (values, image) => {
  return async dispatch => {
    // console.log("values", values, image)
    const formData = new FormData()
    formData.append("file", image),
      formData.append("values", JSON.stringify(values))

    dispatch(resetSponsor())
    await fetch(createSponsorApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        // console.log("res", res)
        if (res.status === 200) {
          dispatch(setSponsorSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })
  }
}

export const updateSponsor = (values, image) => {
  return async dispatch => {
    // console.log("Updatevalues", values, image)
    const formData = new FormData()
    formData.append("file", image),
      formData.append("values", JSON.stringify(values))
    dispatch(resetSponsor())
    await fetch(updateSponsorApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        // console.log("Updateres", res)
        if (res.status == 200) {
          dispatch(setSponsorSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}
export const deleteSponsor = id => {
  return async dispatch => {
    dispatch(resetSponsor())
    try {
      const responseData = await post(deleteSponsorApi, id)

      if (responseData.response_code === 200) {
        // console.log("Sponsor user", responseData)
        dispatch(setSponsorSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveSponsor = data => {
  return async dispatch => {
    dispatch(resetSponsor())
    try {
      const responseData = await post(activeInactiveSponsorApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setSponsorSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const makeVisibleWeb = data => {
  return async dispatch => {
    dispatch(resetSponsor())
    try {
      const responseData = await post(makeVisibleWebApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setSponsorSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const fetchSponsorInformation = () => {
  return async dispatch => {
    try {
      const responseData = await get(getSponsorInfoApi)

      if (responseData.response_code === 200) {
        console.log("responseData", responseData)
        dispatch(sponsorInfo(responseData.data))
      }
    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

// export const editUser = data => {
//   return async dispatch => {
//     try {
//       localStorage.setItem("editUser", JSON.stringify(data))
//       dispatch(setUserEdit())
//     } catch (err) {
//       console.log("editUsererr", err)
//     }
//   }
// }

export const fetchSponsor = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchSponsorApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setSponsorList(responseData.data))
        dispatch(resetSponsor())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setSponsorList,
  setSponsorSuccessfullyAdded,
  setUserEdit,
  resetSponsor,
  sponsorInfo
} = SponsorSlice.actions

export default SponsorSlice.reducer
