import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { useNavigate } from "react-router-dom"
import { del, get, post, put } from "../helpers/api_helper"
import { APIServerIP } from "../helpers/Sats_api_helper"

const LoginApi = `${APIServerIP}admin/login-admin-user`

const initialRegState = {
  userInfo: null,
  isAuthenticated: false,
  authError: "",
  companyConfig: null,
}

const authenticationSlice = createSlice({
  name: "authenticate",
  initialState: initialRegState,
  reducers: {
    authUser: (state, action) => {
      state.isAuthenticated = true
      state.authError = ""
      state.userInfo = action.payload
    },
    authUserFailed: (state, action) => {
      state.authError = action.payload
      state.userInfo = null
    },
    logout: (state, action) => {
      // console.log("hiii")
      localStorage.removeItem("authUser")
      // localStorage.removeItem("role")
      nav("login")
      state.isAuthenticated = false
    },
    resetLogin: state => {
      state.isAuthenticated = false
    },
  },
})
export const login = data => {
  return async dispatch => {
    try {
      const responseData = await post(LoginApi, data)
      if (responseData.response_code === 200) {
        dispatch(authUser(responseData.data))
        localStorage.setItem("authUser", JSON.stringify(responseData.data))
        // localStorage.setItem("role", JSON.stringify(responseData.data.role))
      }
    } catch (error) {
      // console.log("try error", error)
      dispatch(authUserFailed(error?.response?.data?.message))
    }
  }
}
export const { authUser, resetLogin, logout, authUserFailed } =
  authenticationSlice.actions

export default authenticationSlice.reducer
