import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../helpers/api_helper"
import { APIServerIP } from "../../src/helpers/Sats_api_helper"

const fetchMalligaiMalarApi = `${APIServerIP}MalligaiMalars/fetch-MalligaiMalarship`
const createMalligaiMalarApi = `${APIServerIP}MalligaiMalars/create-MalligaiMalarship`
const updateMalligaiMalarApi = `${APIServerIP}MalligaiMalars/update-MalligaiMalarship`
const activeInactiveMalligaiMalarApi = `${APIServerIP}member-type-lists/active-inactive-MalligaiMalarship`
const deleteMalligaiMalarApi = `${APIServerIP}MalligaiMalars/delete-MalligaiMalarship`

const initialState = {
  MalligaiMalarList: [],
  MalligaiMalarisSuccessfully: false,
}

const MalligaiMalarSlice = createSlice({
  name: "MalligaiMalar",
  initialState: initialState,
  reducers: {
    setMalligaiMalarList: (state, action) => {
      state.MalligaiMalarList = action.payload
    },
    setMalligaiMalarSuccessfullyAdded: (state, action) => {
      state.MalligaiMalarisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetMalligaiMalar: (state, action) => {
      state.MalligaiMalarisSuccessfully = false
      state.isUserEdit = false
    },
  },
})


export const createMalligaiMalar = (values,image) => {
  return async dispatch => {
    console.log("values", values, image)
    const formData = new FormData()
    formData.append("file", image),
      formData.append("values", JSON.stringify(values))

      dispatch(resetMalligaiMalar())
    await fetch(createMalligaiMalarApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        console.log("res", res)
        if (res.status === 200) {
          dispatch(setMalligaiMalarSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })
    }}

export const updateMalligaiMalar = (values,image) => {
  return async dispatch => {
    console.log("Updatevalues", values, image)
    const formData = new FormData()
    formData.append("file", image),
    formData.append("values", JSON.stringify(values))
    dispatch(resetMalligaiMalar())
    await fetch(updateMalligaiMalarApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        console.log("Updateres", res)
        if (res.status == 200) {
          dispatch(setMalligaiMalarSuccessfullyAdded())

          
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}
export const deleteMalligaiMalar = id => {
  return async dispatch => {
    dispatch(resetMalligaiMalar())
    try {
      
      const responseData = await post(deleteMalligaiMalarApi, id)

      if (responseData.response_code === 200) {
        console.log("MalligaiMalar user", responseData)
        dispatch(setMalligaiMalarSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveMalligaiMalar = data => {
  return async dispatch => {
    dispatch(resetMalligaiMalar())
    try {
      
      const responseData = await post(activeInactiveMalligaiMalarApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setMalligaiMalarSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

// export const editUser = data => {
//   return async dispatch => {
//     try {
//       localStorage.setItem("editUser", JSON.stringify(data))
//       dispatch(setUserEdit())
//     } catch (err) {
//       console.log("editUsererr", err)
//     }
//   }
// }

export const fetchMalligaiMalar = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchMalligaiMalarApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setMalligaiMalarList(responseData.data))
        dispatch(resetMalligaiMalar())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setMalligaiMalarList,
  setMalligaiMalarSuccessfullyAdded,
  setUserEdit,
  resetMalligaiMalar,
} = MalligaiMalarSlice.actions

export default MalligaiMalarSlice.reducer
