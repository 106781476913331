import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const fetchSubscriberApi = `${APIServerIP}subscriber/fetch-subscriber`
const createSubscriberApi = `${APIServerIP}subscriber/fetch-subscriber`
const updateSubscriberApi = `${APIServerIP}subscriber/update-subscriber`
const activeInactiveSubscriberApi = `${APIServerIP}member-type-lists/active-inactive-member-type`
const deleteSubscriberApi = `${APIServerIP}`

const initialState = {
  SubscriberList: [],
  SubscriberisSuccessfully: false,
}

const SubscriberSlice = createSlice({
  name: "Subscriber",
  initialState: initialState,
  reducers: {
    setSubscriberList: (state, action) => {
      state.SubscriberList = action.payload
    },
    setSubscriberSuccessfullyAdded: (state, action) => {
      state.SubscriberisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetSubscriber: (state, action) => {
      state.SubscriberisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const createSubscriber = values => {
  return async dispatch => {
    try {
      dispatch(resetSubscriber())
      const responseData = await post(createSubscriberApi, values)
      // console.log("Subscriber user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setSubscriberSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateSubscriber = values => {
  return async dispatch => {
    try {
      dispatch(resetSubscriber())
      const responseData = await post(updateSubscriberApi, values)
      // console.log("Subscriber user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setSubscriberSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteSubscriber = id => {
  return async dispatch => {
    try {
      dispatch(resetSubscriber())
      const responseData = await post(deleteSubscriberApi, id)

      if (responseData.response_code === 200) {
        // console.log("Subscriber user", responseData)
        dispatch(setSubscriberSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveSubscriber = data => {
  return async dispatch => {
    try {
      dispatch(resetSubscriber())
      const responseData = await post(activeInactiveSubscriberApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setSubscriberSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

// export const editUser = data => {
//   return async dispatch => {
//     try {
//       localStorage.setItem("editUser", JSON.stringify(data))
//       dispatch(setUserEdit())
//     } catch (err) {
//       console.log("editUsererr", err)
//     }
//   }
// }

export const fetchSubscriber = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchSubscriberApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setSubscriberList(responseData.data))
        dispatch(resetSubscriber())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setSubscriberList,
  setSubscriberSuccessfullyAdded,
  setUserEdit,
  resetSubscriber,
} = SubscriberSlice.actions

export default SubscriberSlice.reducer
