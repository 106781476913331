import React, { useMemo, useState, useEffect } from "react"
import TableContainer from "components/Common/TableContainer"
import APIServerIP from "helpers/Sats_api_helper"
import ExportExcel from "components/Excelconvert/ExportExcel"

import {
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Label,
  Input,
  Modal,
  Table,
  FormFeedback,
  Container,
} from "reactstrap"
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createSponsor,
  updateSponsor,
  deleteSponsor,
  activeInactiveSponsor,
  fetchSponsor,
  makeVisibleWeb,
  fetchSponsorInformation
  // editUser
} from "../../toolkitStore/SponsorSlice"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"

// import { memberList, SponsorList } from "toolkitStore/memberSlice"

function Sponsor(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const [imagec, SetImage] = useState()
  const [spnLevel, setSponsorLevel] = useState()
  const [defaultSpnLevel, setDfSLValue] = useState(" ")

  //image
  const { SponsorList, SponsorisSuccessfully, sponsorship, sponsor_level } = useSelector(
    state => state.SponsorReducer
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  useEffect(() => {
    dispatch(fetchSponsorInformation())
    dispatch(fetchSponsor())
    // console.log("hii")
  }, [])
  useEffect(() => {
    dispatch(fetchSponsor())
    if (SponsorisSuccessfully) {
      setIsRight(false)
      validation.resetForm()
    }
  }, [SponsorisSuccessfully])

  const CloseandCancel = () => {
    // console.log("ii")
    tog_backdrop()
    SetDelete_data()
  }

  useEffect(() => {
    if (selectedData) {
      console.log("selectedData?.sponsorship", selectedData?.sponsorship)
      getSponsorLevels(String(selectedData?.sponsorship).toLowerCase())
    }
  }, [selectedData])

  const columns = useMemo(
    () => [
      {
        Header: "Sponsor’s Name",
        accessor: "sp_name",
      },
      {
        Header: "Contact Person",
        accessor: "sp_ct_person",
      },
      {
        Header: "Contact Number",
        accessor: "sp_phone",
      },
      {
        Header: "Sponsorship",
        accessor: "sponsorship",
      },
      {
        Header: "Sponsorship Level",
        accessor: "sponsorship_level",
      },
      {
        Header: "Old Sponsorship Level",
        accessor: "membership_category",
      },
      {
        Header: "Logo ",
        accessor: "logo",
        Cell: cellProps => {
          let { logo } = cellProps.row.original
          return (
            <ul className="list-unstyled mb-0">
              {logo ? (
                <li>
                  <img
                    src={`${APIServerIP}assets/sponsorship/${logo}`}
                    style={{
                      width: "120px",
                      objectFit: "contain",
                    }}
                  ></img>
                </li>
              ) : (
                <li>NA</li>
              )}
            </ul>
          )
        },
      },

      // {
      //   Header: "status",
      //   accessor: "status",
      //   Cell: cellProps => {
      //     return (
      //       <ul className="list-unstyled mb-0">
      //         <li>
      //           <div className={cellProps.row.original.status === 0 ? "badge badge-soft-danger" : "badge badge-soft-success"}>
      //             {cellProps.row.original.status === 0
      //               ? "Inactive"
      //               : "Active"}
      //           </div>
      //         </li>
      //       </ul>
      //     )
      //   },
      // },
      {
        Header: "Web View",
        accessor: "make_visible_web",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled mb-0">
              <li>
                <div
                  className={
                    cellProps.row.original.make_visible_web === true
                      ? "badge badge-soft-success "
                      : "badge badge-soft-danger"
                  }
                >
                  {cellProps.row.original.make_visible_web === true
                    ? "Visible"
                    : "Invisible"}
                </div>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        // accessor: "status",
        Cell: cellProps => {
          // let sdbtncolor =
          //   cellProps.row.original.status === 1
          //     ? "btn-soft-danger"
          //     : "btn-soft-success"
          let Invisblebtncolor =
            cellProps.row.original.make_visible_web === true
              ? "btn-soft-danger"
              : "btn-soft-success"
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#!"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    const SData = cellProps.row.original
                    SetSelectData(SData)
                    setIsRight(true)
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="#!"
                  className={`badge btn ${sdbtncolor} p-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      status: SData.status,
                    }

                    dispatch(activeInactiveSponsor(PassingData))
                  }}
                >
                  {cellProps.row.original.status === 1
                    ? "Set Inactive"
                    : "Set Active"}
                </Link>
              </li> */}
              <li>
                <Link
                  to="#!"
                  className={`badge btn ${Invisblebtncolor} p-2 me-2`}
                  id="active"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    let PassingData = {
                      _id: SData._id,
                      make_visible_web: SData.make_visible_web,
                    }

                    dispatch(makeVisibleWeb(PassingData))
                  }}
                >
                  {cellProps.row.original.make_visible_web === true
                    ? "Set Invisible"
                    : "Set Visible"}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      sponsorship: selectedData?.sponsorship || " ",
      sponsorship_level: selectedData?.sponsorship && selectedData?.sponsorship_level || " ",
      // corporate: selectedData?.corporate || "",
      // individual: selectedData?.individual || "",
      sp_name: selectedData?.sp_name || "",
      sp_ct_person: selectedData?.sp_ct_person || "",
      sp_address: selectedData?.sp_address || "",
      sp_email: selectedData?.sp_email || "",
      sp_phone: selectedData?.sp_phone || "",
      logo: selectedData?.logo || "",
      make_visible_web: selectedData?.make_visible_web || false,
      website_url: selectedData?.website_url || "",
    },
    validationSchema: Yup.object({
      // membership: Yup.string().required("membership is required"),
      sp_name: Yup.string().required("Name is required"),
      // sp_ct_person: Yup.string().required("Contact Person is required"),
      // sp_email: Yup.string()
      //   .email("Invalid email address")
      //   .required("Email address is required"),
      // sp_phone: Yup.string().required("Phone Number is required"),
      // membership_category: Yup.string().required(
      //   "Membership level is required"
      // ),
      // logo: Yup.string().required("Logo is required"),
      // website_url: Yup.string()
      //   .url("Invalid url format")
      //   .required("Website url is required"),
    }),
    onSubmit: values => {
      console.log(values)
      if (selectedData?._id) {
        // console.log(values, selectedData?._id)
        values["_id"] = selectedData?._id
        dispatch(updateSponsor(values, imagec))
      } else {
        dispatch(createSponsor(values, imagec))
      }
    },
  })

  const getSponsorLevels = (sponsorLevel) => {
    //console.log("Selected Sponsor", sponsorLevel, sponsorship, sponsor_level)

    var getData = sponsor_level.filter(level => level.category.includes(sponsorLevel.toLowerCase()));
    var SponsorshipLevels = getData.map(item => {
      return {
        "label": item.name,
        "value": item.name
      }
    })
    setSponsorLevel(SponsorshipLevels)

    // console.log("getData", getData)

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Sponsors" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 card-title flex-grow-1"></h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel
                      excelData={SponsorList}
                      fileName={"Sponsor's"}
                    />

                    <Link to="/sponsorticket" className="btn btn-primary me-1">Sponsor Tickets</Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={SponsorList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            scrollable
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              className="border-bottom border-secondary"
            >
              {selectedData?._id ? "Update Sponsor" : "Add Sponsor"}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={event => {
                  console.log("am in")
                  event.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <Row className="col-12 p-3">
                  <Col className="col-12 mb-3">

                    <div
                      style={{ fontSize: 14, fontWeight: 500 }}
                      className="text-warning"
                    >
                      {validation.values.membership}
                    </div>
                    <Label htmlFor="sponsorship_n" className="text-secondary text-opacity-75">Sponsorship</Label>
                    <select
                      id="sponsorship_n"
                      name="sponsorship"
                      className={`form-select ${validation.touched.sponsorship &&
                        validation.errors.sponsorship &&
                        "is-invalid"
                        }`}
                      onChange={e => {
                        getSponsorLevels(e.target.value)
                        validation.setFieldValue("sponsorship", e.target.value)
                        validation.setFieldValue("sponsorship_level", " ")
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.sponsorship}
                    // defaultValue={" "}
                    >
                      <option value={" "} disabled defaultChecked>
                        Select Sponsorship
                      </option>
                      {
                        sponsorship && sponsorship.map((item, idx) => {
                          return (
                            <option key={idx + "sprnship"} value={item.name}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                    {validation.touched.sponsorship &&
                      validation.errors.sponsorship ? (
                      <FormFeedback style={{ fontSize: "12px" }}>
                        {validation.errors.sponsorship}
                      </FormFeedback>
                    ) : null}
                  </Col>

                  <Col className="col-12 mb-3">
                    <div>
                      <Label htmlFor="sponsorship_level " className="text-secondary text-opacity-75">Sponsorship Level</Label>
                      <select
                        id="sponsorship_level"
                        name="sponsorship_level"
                        disabled={validation.values.sponsorship === " "}
                        className={`form-select ${validation.touched.sponsorship_level &&
                          validation.errors.sponsorship_level &&
                          "is-invalid"
                          }`}
                        onChange={e => {
                          validation.setFieldValue("sponsorship_level", e.target.value)
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.sponsorship_level}
                      // defaultValue={" "}
                      >
                        <option value={" "} disabled defaultChecked>
                          Select Sponsorship Level
                        </option>
                        {
                          spnLevel && spnLevel.map((item, idx) => {
                            return (
                              <option key={idx + "sprnshiplvl"} value={item.value}>{item.label}</option>
                            )
                          })
                        }
                      </select>
                      {validation.touched.sponsorship_level &&
                        validation.errors.sponsorship_level ? (
                        <FormFeedback style={{ fontSize: "12px" }}>
                          {validation.errors.sponsorship_level}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="col-lg-12 d-flex flex-wrap justify-content-between rounded">
                    <div className="mb-3 col-12">
                      <Label htmlFor="emasp_nameil">
                        Sponsor’s Name as it will appear in all printed material
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="sp_name"
                        className={`form-control`}
                        placeholder="Enter Your Sponser Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sp_name || ""}
                        invalid={
                          validation.touched.sp_name &&
                            validation.errors.sp_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sp_name &&
                        validation.errors.sp_name ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.sp_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12">
                      <Label htmlFor="sp_ct_person">
                        Contact Person<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="sp_ct_person"
                        className={`form-control`}
                        placeholder="Enter Your Contact Person"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sp_ct_person || ""}
                        invalid={
                          validation.touched.sp_ct_person &&
                            validation.errors.sp_ct_person
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sp_ct_person &&
                        validation.errors.sp_ct_person ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.sp_ct_person}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12">
                      <Label htmlFor="sp_address">Address</Label>
                      <Input
                        name="sp_address"
                        className="form-control  "
                        placeholder="Enter Your Address"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sp_address || ""}
                        invalid={
                          validation.touched.sp_address &&
                            validation.errors.sp_address
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sp_address &&
                        validation.errors.sp_address ? (
                        <FormFeedback type="invalid">
                          {validation.errors.sp_address}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12">
                      <Label htmlFor="sp_email">Email</Label>
                      <Input
                        name="sp_email"
                        className={`form-control`}
                        placeholder="Enter Your Email"
                        type="sp_email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sp_email || ""}
                        invalid={
                          validation.touched.sp_email &&
                            validation.errors.sp_email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sp_email &&
                        validation.errors.sp_email ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.sp_email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-12">
                      <Label htmlFor="sp_phone">Phone</Label>
                      <Input
                        name="sp_phone"
                        className="form-control  "
                        placeholder="Enter your Phone Number"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sp_phone || ""}
                        invalid={
                          validation.touched.sp_phone &&
                            validation.errors.sp_phone
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sp_phone &&
                        validation.errors.sp_phone ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.sp_phone}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-12  ">
                      <Label htmlFor="logo">Upload Logo</Label>
                      <Input
                        name="logo"
                        className="form-control"
                        placeholder="Upload photo"
                        type="file"
                        onChange={e => {
                          // console.log(e.currentTarget.files[0].name)
                          validation.setFieldValue(
                            "logo",
                            e.currentTarget.files[0].name
                          )
                          SetImage(e.currentTarget.files[0])
                        }}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {validation.touched.logo && validation.errors.logo ? (
                        <div
                          type="invalid"
                          className="invalid-feedback"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.logo}
                        </div>
                      ) : null}
                      {<span className="text-info">{selectedData?.logo}</span>}
                    </div>
                    <div className="mb-3 col-12">
                      <Label htmlFor="website_url">Website Url</Label>
                      <Input
                        name="website_url"
                        className={`form-control`}
                        placeholder="Enter Website url"
                        type="website_url"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.website_url || ""}
                        invalid={
                          validation.touched.website_url &&
                            validation.errors.website_url
                            ? true
                            : false
                        }
                      />
                      {validation.touched.website_url &&
                        validation.errors.website_url ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.website_url}
                        </FormFeedback>
                      ) : null}
                    </div>
                    {/* <div className=" mb-3  ps-3 col-12 d-flex gap-1">
                  <Input
                      name="make_visible_web"
                      className="form-check-input mb-2"
                      type="checkbox"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                     
                      checked={validation.values.make_visible_web}
                      invalid={
                        validation.touched.make_visible_web &&
                        validation.errors.make_visible_web
                          ? true
                          : false
                      }
                    />
                    <Label htmlFor="make_visible_web">
                      Make Visible On Web
                    </Label>
                    
                    {validation.touched.make_visible_web &&
                    validation.errors.make_visible_web ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.make_visible_web}
                      </FormFeedback>
                    ) : null}
                  </div> */}

                    <Col className=" mt-3 justify-content-around px-2 d-flex gap-1">
                      <button
                        className="btn btn-danger w-md px-5"
                        onClick={toggleRightCanvas}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary w-md px-5"
                      >
                        {selectedData?._id ? "Update" : "Submit"}
                      </button>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Modal
            isOpen={modal_backdrop}
            toggle={tog_backdrop}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {"Delete"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={CloseandCancel}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Sponsor ,Then confirm It ....!</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // dispatch(deleteEvent(delete_data))
                  dispatch(deleteSponsor(delete_data))

                  tog_backdrop()
                }}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={CloseandCancel}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Sponsor
