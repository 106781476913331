import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const SalesAnalytics = ({ dataColors, SponsorList }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors)
  // console.log("sponsors", SponsorList)
  const sponsorGrandlevel = SponsorList.filter(
    item => item.membership_category === "Grand Level"
  )
  const sponsorPlatinumlevel = SponsorList.filter(
    item => item.membership_category === "Platinum Level"
  )
  const sponsorGoldlevel = SponsorList.filter(
    item => item.membership_category === "Gold Level"
  )

  const sponsorSliverlevel = SponsorList.filter(
    item => item.membership_category === "Silver Level"
  )
  const sponsorBronzelevel = SponsorList.filter(
    item => item.membership_category === "Bronze Level"
  )
  const series = [
    sponsorGrandlevel.length || 0,
    sponsorPlatinumlevel.length,
    sponsorGoldlevel.length,
    sponsorSliverlevel.length,
    sponsorBronzelevel.length,
  ]
  // const series = [56, 38, 26, 40, 70]
  const options = {
    labels: ["Grand", "Platinum", "Gold", "Sliver", "Bronze"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  }

  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">
              Sponsor's Level ({SponsorList.length})
            </h4>

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-info me-1" /> Grand
                    </p>
                    <h5>{sponsorGrandlevel.length}</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-primary me-1" />
                      Platinum
                    </p>
                    <h5>{sponsorPlatinumlevel.length}</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-success me-1" /> Gold
                    </p>
                    <h5>{sponsorGoldlevel.length}</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-danger me-1" /> Sliver
                    </p>
                    <h5>{sponsorSliverlevel.length}</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-warning me-1" />
                      Bronze
                    </p>
                    <h5>{sponsorBronzelevel.length}</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default SalesAnalytics
