import React, { useRef, useState, useEffect } from "react"
import { get, put } from "../../../../helpers/api_helper"
import { Link, useNavigate } from "react-router-dom"
import classnames from "classnames"
import moment from "moment"
import { Country } from "../../../../components/country-state-city/countries"
import { State } from "../../../../components/country-state-city/states"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Container,
} from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//Import Date Picker

// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

// Redux
import { useSelector, useDispatch } from "react-redux"

import {
  createVenue,
  updateVenue,
} from "../../../../toolkitStore/eventMSlice/VenueSlice"
import { id } from "date-fns/locale"

const Venue = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { VenueisSuccessfully, isUserEdit } = useSelector(
    state => state.VenueReducer
  )

  const [selectedData, SetSelectData] = useState()

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)
  const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))

  // console.log("venue_id_c -->", venue_id_c)

  const nfds = []
  for (let i = 1; i <= event_id_c.EventDay; i++) {
    nfds.push(i)
  }

  useEffect(() => {
    if (VenueisSuccessfully) {
      navigate("/venuelist")
    }
  }, [VenueisSuccessfully])

  useEffect(() => {
    if (isUserEdit) {
      const editData = JSON.parse(localStorage.getItem("editUser"))
      SetSelectData(editData)
    }
  }, [isUserEdit])
  //Cancel
  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("editUser")
    SetSelectData("")
    navigate("/venuelist")
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      master_event_id: selectedData?.master_event_id || event_id_c.EventId,
      day: selectedData?.day || "Day 1",
      start_datetime: selectedData?.start_datetime
        ? moment
          .parseZone(selectedData?.start_datetime)
          .format("yyyy-MM-DDThh:mm")
        : "",
      end_datetime: selectedData?.end_datetime
        ? moment
          .parseZone(selectedData?.end_datetime)
          .format("yyyy-MM-DDThh:mm")
        : "",
      venue: selectedData?.venue || "",
      address: selectedData?.address || "",
      city: selectedData?.city || "",
      state: selectedData?.state || "",
      country: selectedData?.country || "",
      zipcode: selectedData?.zipcode || "",
      country_code: selectedData?.country_code || "",
      max_tickets: selectedData?.max_tickets || 0,
      remaining_tickets: selectedData?.remaining_tickets || 0,
      ticket_sold: selectedData?.ticket_sold || 0,
      active_status: selectedData?.active_status || "Active",
      code_type: selectedData?.code_type || "none",
    },
    validationSchema: Yup.object({
      start_datetime: Yup.string().required(
        " Enter Your Event Start Date and Time"
      ),
      end_datetime: Yup.string().required(
        " Enter Your Event End Date and Time"
      ),
      venue: Yup.string().required("Enter The Event Venue"),
      address: Yup.string().required("Enter The Event Address"),
      city: Yup.string().required("Enter The Event City"),
      state: Yup.string().required("Enter The Event State"),
      country: Yup.string().required("Enter The Event country"),
      zipcode: Yup.string().required("Enter The Event zipcode"),
      country_code: Yup.string().required("Enter The Event country_code"),
      max_tickets: Yup.string().required("Enter the Maximum tickets "),
    }),
    onSubmit: (values, actions) => {
      {
        if (selectedData?._id) {
          values["master_event_id"] = selectedData?.master_event_id
          values["_id"] = selectedData?._id
          values["remaining_tickets"] = values.max_tickets
          dispatch(updateVenue(values))
        } else {
          values["master_event_id"] = event_id_c.EventId
          values["remaining_tickets"] = values.max_tickets
          dispatch(createVenue(values))
        }
      }
      navigate("/venuelist")
      actions.resetForm()
    },
  })

  const filterstates = State.filter(
    sta => sta.country_name == validation.values.country
  )

  // console.log(filterstates)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Venue Information" />
          <Row>
            <Form
              onSubmit={event => {
                event.preventDefault()
                validation.handleSubmit()
              }}
            >
              <Row className="col-12 ">
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center border-bottom border-secondary pb-3">
                      <h5 className="mb-1 card-title flex-grow-1 text-primary ">
                        {venue_id_c
                          ? event_id_c.EventName + " / " + venue_id_c.VenueName
                          : event_id_c.EventName}
                      </h5>
                      <div className="mb-1 flex-shrink-0">
                        <Link
                          to="/venuelist"
                          className="btn btn-secondary me-1"
                        >
                          Back
                        </Link>
                        <Link
                          to="#!"
                          onClick={() => {
                            /*  dispatch(fetchServices(userInformation.m_company_id)) */
                          }}
                          className="btn btn-light me-1"
                        >
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div>
                    </div>

                    <Row className="mb-3 mt-3">
                      <Col>
                        <div className="">
                          <Label htmlFor="day"> Day</Label>
                          <select
                            id="day"
                            name="day"
                            className="form-select"
                            onChange={e =>
                              validation.setFieldValue("day", e.target.value)
                            }
                            value={validation.values.day}
                          >
                            <option>Select for a DAY</option>
                            {nfds.map((d, id) => (
                              <option value={`Day ${d}`} key={id}>
                                {`Day ${d}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="">
                          <Label htmlFor="start_datetime">
                            Start Date & Time
                          </Label>
                          <Input
                            name="start_datetime"
                            className="form-control "
                            type="datetime-local"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.start_datetime || ""}
                            invalid={
                              validation.touched.start_datetime &&
                                validation.errors.start_datetime
                                ? true
                                : false
                            }
                          />
                          {validation.touched.start_datetime &&
                            validation.errors.start_datetime ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_datetime}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="">
                          <Label htmlFor="end_datetime">End Date & Time</Label>
                          <Input
                            name="end_datetime"
                            className="form-control "
                            type="datetime-local"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.end_datetime || ""}
                            invalid={
                              validation.touched.end_datetime &&
                                validation.errors.end_datetime
                                ? true
                                : false
                            }
                          />
                          {validation.touched.end_datetime &&
                            validation.errors.end_datetime ? (
                            <FormFeedback type="invalid">
                              {validation.errors.end_datetime}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="">
                        <Label htmlFor="venue">Venue</Label>
                        <Input
                          name="venue"
                          className={`form-control`}
                          placeholder="Enter Venue Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.venue || ""}
                          invalid={
                            validation.touched.venue && validation.errors.venue
                              ? true
                              : false
                          }
                        />
                        {validation.touched.venue && validation.errors.venue ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.venue}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col>
                        <div className="">
                          <Label htmlFor="address">Address</Label>
                          <Input
                            name="address"
                            className={`form-control`}
                            placeholder="Enter Venue Address"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                                validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                            validation.errors.address ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="">
                          <Label htmlFor="country_code">Country Code</Label>
                          <select
                            id="country_code"
                            name="country_code"
                            className="form-select"
                            onChange={e =>
                              validation.setFieldValue(
                                "country_code",
                                e.target.value
                              )
                            }
                            value={validation.values.country_code}
                          >
                            <option>Select country code</option>
                            {Country.map(country => (
                              <option
                                value={country.phone_code}
                                key={country.id}
                              >
                                {country.phone_code}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col>
                        <div className="">
                          <Label htmlFor="country">Country</Label>
                          <select
                            id="country"
                            name="country"
                            className="form-select"
                            onChange={e =>
                              validation.setFieldValue(
                                "country",
                                e.target.value
                              )
                            }
                            value={validation.values.country}
                          >
                            <option>Select country</option>
                            {Country.map(country => (
                              <option value={country.name} key={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="">
                          <Label htmlFor="state">State</Label>
                          <select
                            id="state"
                            name="state"
                            className="form-select"
                            onChange={e =>
                              validation.setFieldValue("state", e.target.value)
                            }
                            value={validation.values.state}
                          >
                            <option value="">Select state</option>
                            {filterstates.map(state => (
                              <option
                                value={state.name}
                                key={state.id}
                                className="d-flex flex-grow-1"
                              >
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="">
                          <Label htmlFor="city">City</Label>
                          <Input
                            name="city"
                            className={`form-control`}
                            placeholder="Enter city name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                            invalid={
                              validation.touched.city && validation.errors.city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.city}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="">
                          <Label htmlFor="zipcode">ZipCode</Label>
                          <Input
                            name="zipcode"
                            className={`form-control`}
                            placeholder="Enter zipcode"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zipcode || ""}
                            invalid={
                              validation.touched.zipcode &&
                                validation.errors.zipcode
                                ? true
                                : false
                            }
                          />
                          {validation.touched.zipcode &&
                            validation.errors.zipcode ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.zipcode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="col-3">
                        <div className="">
                          <Label htmlFor="max_tickets">Maximum Tickets</Label>
                          <Input
                            name="max_tickets"
                            min={1}
                            className={`form-control`}
                            placeholder="Enter tickets capacity"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.max_tickets || ""}
                            invalid={
                              validation.touched.max_tickets &&
                                validation.errors.max_tickets
                                ? true
                                : false
                            }
                          />
                          {validation.touched.max_tickets &&
                            validation.errors.max_tickets ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.max_tickets}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col className="col-3">
                        <div className="mb-3 col-12">
                          <Label htmlFor="code_type" className="text-secondary text-opacity-75">
                            QR / BARCODE
                          </Label>
                          <Input
                            name="code_type"
                            className={`form-control`}
                            type="select"
                            defaultValue={validation.values.code_type || "none"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          >
                            <option value="" disabled>Select Type</option>
                            <option value="none">None</option>
                            <option value="barcode">Barcode</option>
                            <option value="qrcode">QR Code</option>
                          </Input>
                        </div>
                      </Col>

                    </Row>

                    <Row>
                      <Col className="d-flex mt-3 justify-content-end">
                        <div className=" text-center mt-3 ">
                          <button
                            type="submit"
                            className="btn btn-success w-md px-5"
                          >
                            {selectedData?._id ? "Update" : "Submit"}
                          </button>
                        </div>
                        <div className=" text-center mt-3 mx-3 ">
                          <button
                            onClick={Cancel}
                            className="btn btn-danger w-md px-5"
                          >
                            {"Cancel"}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Venue
