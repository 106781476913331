import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Card, CardBody } from "reactstrap"

import { fetchEventandVenue } from "toolkitStore/eventMSlice/eventSlice"
//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import user1 from "../../assets/images/users/adminuserlogo.jpg"
import { APIServerIP } from "helpers/Sats_api_helper"

function CardUser({
  TotalMembers,
  MemberLifeMembership,
  MemberAnnualFamily,
  MemberStudent,
  // inactiveMembers,
}) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  const dispatch = useDispatch()
  const { EventListWithvenue } = useSelector(state => state.EventReducer)
  //Setting Menu
  const Username = JSON.parse(localStorage.getItem("authUser"))
  useEffect(() => {
    dispatch(fetchEventandVenue())
  }, [])
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={user1}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome to SATS Dashboard</p>
                        <h5 className="mb-1">{Username?.user_name}</h5>
                        <p className="mb-0">{Username?.role}</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="8" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total Members
                          </p>
                          <h5 className="mb-0">{TotalMembers}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Lifetime
                          </p>
                          <h5 className="mb-0">{MemberLifeMembership}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">Term</p>
                          <h5 className="mb-0">{MemberAnnualFamily}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Student
                          </p>
                          <h5 className="mb-0">{MemberStudent}</h5>
                        </div>
                      </Col>
                      {/* <Col xs="2">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Inactive
                          </p>
                          <h5 className="mb-0">{inactiveMembers}</h5>
                        </div>
                      </Col> */}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {EventListWithvenue.map((item, ind) => (
            <Card key={ind}>
              <CardBody>
                <Row className="d-flex">
                  <Col lg="4">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={`${APIServerIP}assets/event_cover/${item.image_name}`}
                          alt=""
                          className="avatar-md rounded img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <p className="mb-2">SATS Upcoming Event</p>
                          <h5 className="mb-1">{item.name}</h5>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="8" className="align-self-center mb-2">
                    {item?.venues.map((item2, ind2) => (
                      <Col
                        lg="12"
                        key={ind2}
                        className="align-self-center mb-2"
                      >
                        <div className="text-lg-center mt-4 mt-lg-0">
                          <Row>
                            <Col xs="5">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Venue
                                </p>
                                <h5 className="mb-0">{item2.venue}</h5>
                              </div>
                            </Col>
                            <Col xs="3">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Total Tickets
                                </p>
                                <h5 className="mb-0">{item2.max_tickets}</h5>
                              </div>
                            </Col>
                            <Col xs="2">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Remaining
                                </p>
                                <h5 className="mb-0">
                                  {Number(item2.max_tickets) - Number(item2.ticket_sold)}
                                </h5>
                              </div>
                            </Col>
                            <Col xs="2">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Sold
                                </p>
                                <h5 className="mb-0">{item2.ticket_sold}</h5>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ))}

                    {/* <Col lg="12" className="align-self-end ">
                      <div className="text-lg-center mt-4 mt-lg-0">
                        <Row>
                          <Col xs="3">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Venue
                              </p>
                              <h5 className="mb-0">{TotalMembers}</h5>
                            </div>
                          </Col>
                          <Col xs="3">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Total Tickets
                              </p>
                              <h5 className="mb-0">{MemberLifeMembership}</h5>
                            </div>
                          </Col>
                          <Col xs="3">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Remaining Tickets
                              </p>
                              <h5 className="mb-0">{MemberAnnualFamily}</h5>
                            </div>
                          </Col>
                          <Col xs="3">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Sold Tickets
                              </p>
                              <h5 className="mb-0">{MemberStudent}</h5>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
