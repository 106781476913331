import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../helpers/api_helper"
import { APIServerIP } from "../../src/helpers/Sats_api_helper"

const fetchGalleryApi = `${APIServerIP}gallery/admin-fetch-gallery`
const createGalleryApi = `${APIServerIP}gallery/create-gallery`
const updateGalleryApi = `${APIServerIP}member-type-lists/update-member-type`
const activeInactiveGalleryApi = `${APIServerIP}gallery/active-inactive-gallery`
const deleteGalleryApi = `${APIServerIP}gallery/delete-gallery`

const initialState = {
  GalleryList: [],
  GalleryisSuccessfully: false,
}

const GallerySlice = createSlice({
  name: "Gallery",
  initialState: initialState,
  reducers: {
    setGalleryList: (state, action) => {
      state.GalleryList = action.payload
    },
    setGallerySuccessfullyAdded: (state, action) => {
      state.GalleryisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetGallery: (state, action) => {
      state.GalleryisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const createGallery = (values, image) => {
  return async dispatch => {
    // console.log("values", values, image)
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))

    dispatch(resetGallery())
    await fetch(createGalleryApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        // console.log("res", res)
        if (res.status === 200) {
          dispatch(setGallerySuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })
  }
}

export const updateGallery = (values, image) => {
  return async dispatch => {
    // console.log("Updatevalues", values, image)
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))
    dispatch(resetGallery())
    await fetch(updateGalleryApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        // console.log("Updateres", res)
        if (res.status == 200) {
          dispatch(setGallerySuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}
export const deleteGallery = id => {
  return async dispatch => {
    try {
      dispatch(resetGallery())
      const responseData = await post(deleteGalleryApi, id)

      if (responseData.response_code === 200) {
        // console.log("Gallery user", responseData)
        dispatch(setGallerySuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveGallery = data => {
  return async dispatch => {
    try {
      dispatch(resetGallery())
      const responseData = await post(activeInactiveGalleryApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setGallerySuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

// export const editUser = data => {
//   return async dispatch => {
//     try {
//       localStorage.setItem("editUser", JSON.stringify(data))
//       dispatch(setUserEdit())
//     } catch (err) {
//       console.log("editUsererr", err)
//     }
//   }
// }

export const fetchGallery = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchGalleryApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setGalleryList(responseData.data))
        dispatch(resetGallery())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setGalleryList,
  setGallerySuccessfullyAdded,
  setUserEdit,
  resetGallery,
} = GallerySlice.actions

export default GallerySlice.reducer
