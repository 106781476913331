import React, { useMemo, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import {
  CardBody,
  Card,
  Row,
  Col,
  CardTitle,
  Badge,
  Modal,
  UncontrolledTooltip,
  Button,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

import moment from "moment"

//import components
import TableContainer from "components/Common/TableContainer"
import { useEffect } from "react"

// slice
import { volunteerList } from "toolkitStore/eventMSlice/VenueSlice"

function Volunteers(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)
  const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
  // console.log("venue_id", venue_id_c)

  const { volunteers } = useSelector(state => state.VenueReducer)

  useEffect(() => {
    if (volunteerList) {
      dispatch(volunteerList(venue_id_c.VenueId))
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstname",
      },
      {
        Header: "Last Name",
        accessor: "lastname",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Phone NUmber",
        accessor: "phone_no",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Event ID",
        accessor: "event_id",
      },
      {
        Header: "Member ID",
        accessor: "member_id",
      },
      {
        Header: "Status",
        accessor: "event_status",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack d-flex justify-content-center mb-0">
              {cellProps.row.original.event_status == "0" && (
                <li>
                  <div className="text-center">
                    <span className="badge rounded-pill badge-soft-warning font-size-12 px-3">
                      Draft
                    </span>
                  </div>
                </li>
              )}
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <div className="page-content">
        <Row className="justify-content-center">
          <Card>
            <CardBody className="border-botton">
              <div className="d-flex align-items-center border-2 border-bottom">
                <h5 className="mb-1 card-title flex-grow-1">volunteers List</h5>
                <div className="mb-1 flex-shrink-0">
                  <Link to="/venuelist" className="btn btn-secondary me-1">
                    Back
                  </Link>
                  <Link
                    to="#!"
                    onClick={() => {
                      /*  dispatch(fetchServices(userInformation.m_company_id)) */
                    }}
                    className="btn btn-light me-1"
                  >
                    <i className="mdi mdi-refresh"></i>
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
          <Row>
            <div className="container-fluid">
              <TableContainer
                columns={columns}
                data={volunteers}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={30}
                isJobListGlobalFilter={false}
                className="custom-header-css"
              />
            </div>
          </Row>
          {volunteerList.length > 10 ? (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    Load more
                  </Link>
                </div>
              </Col>
            </Row>
          ) : null}
        </Row>
      </div>
    </>
  )
}

export default Volunteers
