import React, { useMemo, useState } from "react"
import ExportExcel from "components/Excelconvert/ExportExcel"

import { useSelector, useDispatch } from "react-redux"
import {
    CardBody,
    Card,
    Col,
    CardTitle,
    Badge,
    Modal,
    UncontrolledTooltip,
    Button,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
    Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

import moment from "moment"

//import components
import TableContainer from "components/Common/TableContainer"
import { useEffect } from "react"

import BarcodeReader from 'react-barcode-reader'
// import BarcodeScannerComponent from "react-webcam-barcode-scanner";

// slice
import {
    TicketBookedHistory,
    resetVenue,
    scanningData,
    resetScannedData
} from "toolkitStore/eventMSlice/VenueSlice"

function GateScanning(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const event_id_c = JSON.parse(localStorage.getItem("event_id"))
    // console.log("event_id", event_id_c)
    const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
    // console.log("venue_id", venue_id_c)

    const { TicketBookedDetails, VenueisSuccessfully, scannedUser, scannedUserStatus, scannerStatusMessage } = useSelector(
        state => state.VenueReducer
    )

    const [scannedData, setScandata] = useState('')
    const [data, setData] = React.useState('Not Found');
    const [barValue, setBarValue] = useState('')

    useEffect(() => {
        dispatch(TicketBookedHistory(venue_id_c.VenueId))
        dispatch(resetScannedData())
        dispatch(resetVenue())


    }, [])
    useEffect(() => {
        console.log("scannedUser", scannedUser, scannedUserStatus)
    }, [scannedUser, scannedUserStatus])
    // useEffect(() => {
    //   dispatch(TicketBookedHistory(venue_id_c.VenueId))
    //   dispatch(resetVenue())
    // }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Member ID",
                accessor: "sats_member_id",
                Cell: cellProps => (
                    <ul className="list-unstyled hstack gap-3 mb-0 text-center">
                        <li
                            className={
                                cellProps.row.original.sats_member_id
                                    ? "text-dark"
                                    : "text-danger"
                            }
                        >
                            {cellProps.row.original.sats_member_id
                                ? cellProps.row.original.sats_member_id
                                : "Non Member"}
                        </li>
                    </ul>
                ),
            },
            {
                Header: "First Name",
                accessor: "firstname",
            },
            {
                Header: "Last Name",
                accessor: "lastname",
            },


            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Payment Date",
                accessor: "createdAt",
                Cell: cellProps => {
                    return (
                        <div>
                            {moment
                                .parseZone(cellProps.row.original.createdAt)
                                .format("MM/DD/YYYY")}
                        </div>
                    )
                },
            },
            {
                Header: "Tansaction ID",
                accessor: "transaction_id",
            },

            {
                Header: "Adult",
                accessor: "adult_count",
            },
            {
                Header: "Child",
                accessor: "child_count",
            },
            {
                Header: "Amount",
                accessor: "net_amount",
                Cell: cellProps => {
                    return <div>$ {cellProps.row.original.net_amount}</div>
                },
            },
            {
                Header: "Payment Status",
                accessor: "payment_status",
                Cell: cellProps => {
                    // console.log("cellProps.row.original", cellProps.row.original)
                    return (
                        <ul className="list-unstyled mb-0">
                            <div
                                className={
                                    cellProps.row.original.ticket_payment_status === "initiated"
                                        ? "badge badge-soft-secondary"
                                        : cellProps.row.original.ticket_payment_status === "failed"
                                            ? "badge badge-soft-danger"
                                            : "badge badge-soft-success"
                                }
                            >
                                {cellProps.row.original.ticket_payment_status === "initiated"
                                    ? "Initiated"
                                    : cellProps.row.original.ticket_payment_status === "failed"
                                        ? "Failed"
                                        : "Success"}
                            </div>
                        </ul>
                    )
                },
            },

        ],
        []
    )

    const handleScan = (data) => {
        setScandata(data)
    }
    const handleError = (err) => {
        console.error(err)
    }

    const storeScannedData = () => {

    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            scan_data: ""
        },
        validationSchema: Yup.object({
            scan_data: Yup.string().required(
                "Scan barcode"
            ),

        }),
        onSubmit: (values, actions) => {
            dispatch(resetScannedData())
            console.log("values", values)
            dispatch(scanningData(values))
            actions.resetForm()
        },
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Utility" breadcrumbItem="Booked Information" />

                    <Row className="">
                        <Card>
                            <CardBody className="border-botton" style={{ minHeight: 570 }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-1 card-title flex-grow-1 text-primary">
                                        {event_id_c.EventName + " / " + venue_id_c.VenueName}
                                    </h5>
                                    <div className="mb-1 flex-shrink-0">

                                        {/* <ExportExcel
                                            excelData={TicketBookedDetails}
                                            fileName={`Booking Details ${venue_id_c.VenueName}`}
                                        /> */}

                                        <Link to="/eventvenues" className="btn btn-secondary me-1">
                                            Back
                                        </Link>
                                        <Link
                                            to="#!"
                                            onClick={() => {
                                                /*  dispatch(fetchServices(userInformation.m_company_id)) */
                                            }}
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                                <Row>
                                    <div className="col-6">
                                        <div>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    validation.handleSubmit()
                                                    // storeScannedData()
                                                }}
                                            >
                                                <Label htmlFor="scan_data">
                                                    Barcode data
                                                </Label>
                                                <Input
                                                    name="scan_data"
                                                    className="form-control "
                                                    type="text"
                                                    autoFocus
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.scan_data || ""}
                                                    invalid={
                                                        validation.touched.scan_data &&
                                                            validation.errors.scan_data
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.scan_data &&
                                                    validation.errors.scan_data ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.scan_data}
                                                    </FormFeedback>
                                                ) : null}
                                                {/* <button
                                                    className="btn btn-primary mt-3"
                                                    type="submit"

                                                >Submit</button> */}
                                            </Form>
                                        </div>
                                        {/* <BarcodeReader
                                            onError={handleError}
                                            onScan={handleScan}
                                        /> */}
                                        <p>{scannedData}</p>
                                    </div>
                                </Row>
                                <Row>
                                    {
                                        scannedUserStatus && scannedUserStatus === "2" ?
                                            <div style={{ fontSize: 30, fontWeight: "bold", color: "red" }}>{scannerStatusMessage}</div>
                                            : scannedUserStatus && scannedUserStatus === "3" ?
                                                <div className="col-10">
                                                    <div>
                                                        <div>Name : <br /><span style={{ fontSize: 40 }}>{scannedUser.firstname} {scannedUser.lastname}</span></div>
                                                        <div style={{ fontSize: 20 }}>Adult : {scannedUser.adult_count}, Child : {scannedUser.child_count}</div>
                                                        <div>Email : {scannedUser.email}</div>
                                                        <div style={{ fontSize: 30, fontWeight: "bold", color: "red" }}>{scannerStatusMessage}</div>
                                                    </div>
                                                </div>
                                                : scannedUserStatus && scannedUserStatus === "1" ?
                                                    <div className="col-10">
                                                        <div>
                                                            <div>Name : <br /><span style={{ fontSize: 40 }}>{scannedUser.firstname} {scannedUser.lastname}</span></div>
                                                            <div style={{ fontSize: 20 }}>Adult : {scannedUser.adult_count}, Child : {scannedUser.child_count}</div>
                                                            <div>Email : {scannedUser.email}</div>
                                                            <div style={{ fontSize: 30, fontWeight: "bold", color: "green" }}>{scannerStatusMessage}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null

                                    }
                                </Row>
                                {/* <Row>
                                    <div className="container-fluid">
                                        <TableContainer
                                            columns={columns}
                                            data={TicketBookedDetails}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            customPageSize={30}
                                            isJobListGlobalFilter={false}
                                        // className="custom-header-css"
                                        />
                                    </div>
                                </Row>
                                {TicketBookedDetails.length > 30 ? (
                                    <Row>
                                        <Col xs="12">
                                            <div className="text-center my-3">
                                                <Link to="#" className="text-success">
                                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                                    Load more
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null} */}
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default GateScanning
