import React, { useRef, useState, useEffect } from "react"
/* import axios from "axios" */
import { Link, useNavigate } from "react-router-dom"
import APIServerIP from "helpers/Sats_api_helper"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import moment from "moment"
import {
  Card,
  Modal,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Col,
  Container,
  CardImg,
  CardImgOverlay,
  Form,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import classnames from "classnames"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

import { Country } from "components/country-state-city/countries"
import { State } from "components/country-state-city/states"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { editUserMember } from "../../../toolkitStore/MemberMagement/MemberSlice"

// const formData = new FormData()

function MemberForm(props) {
  //meta title
  /* document.title =
    "Create New Project | Skote - React Admin & Dashboard Template" */
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EditUser, MemberisSuccessfully } = useSelector(
    state => state.MemberReducer
  )

  // console.log(EditUser)

  //   const handleCkeditorIn = (event, editor) => {
  //     const data = editor.getData()
  //     validation.setFieldValue("description", data)
  //   }

  document.title = "Form File Upload | Skote - React Admin & Dashboard Template"
  const { spouse, children } = EditUser

  const [image, SetImage] = useState()
  const [selectedData, SetSelectData] = useState()
  const [dis, setDis] = useState(true)
  const [dis4, SetDis4] = useState(false)
  const [dis1, SetDis1] = useState(false)
  const [SpouseDetails, setSpouseDetails] = useState(spouse)
  const [ChildModal, setChildModal] = useState(false)
  const [SpouseModal, setSpouseModal] = useState(false)
  const [ChildDetails, setChildDetails] = useState(children)
  const [selectedChild, setSelectedChild] = useState()
  const [profile, setProfile] = useState("")

  //edit /update Function

  // useEffect(() => {
  //   if (MemberisSuccessfully) {
  //     navigate("/eventlist")
  //   }
  // }, [MemberisSuccessfully])

  useEffect(() => {
    const EU = JSON.parse(localStorage.getItem("editMember"))
    // console.log(EU)
    SetSelectData()
    if (EU != "") {
      // console.log(EU)
      dispatch(editUserMember({ _id: EU }))
      SetSelectData(EditUser)
    }
  }, [])
  useEffect(() => {
    setSpouseDetails(spouse)
    setChildDetails(children)
    setProfile(`${APIServerIP}assets/uploads/${EditUser?.profile_img}`)
  }, [EditUser])
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: selectedData?._id || "",
      email: selectedData?.email || "",
      firstname: selectedData?.firstname || "",
      lastname: selectedData?.lastname || "",
      gender: selectedData?.gender || "",
      mobile: selectedData?.mobile || "",
      package_type: selectedData?.package_type || "",
      package_fee: selectedData?.package_fee || "",
      address: selectedData?.address || "",
      address2: selectedData?.address2 || "",
      city: selectedData?.city || "",
      state: selectedData?.state || "",
      country_code: selectedData?.country_code || "",
      country: selectedData?.country || "",
      zipcode: selectedData?.zipcode || "",
      dob: selectedData?.dob || "",
      profile_img: selectedData?.profile_img || "",
      spouse: selectedData?.spouse || "",
      children: selectedData?.children || "",
      currency: selectedData?.currency || "",
      login_status: selectedData?.login_status || "",
      Subscribe: selectedData?.Subscribe || "",
      dobcheck: selectedData?.dobcheck || "",
    },
    validationSchema: Yup.object({
      // member_type_id: Yup.string().required("Select the Membership"),
      email: Yup.string()
        .email("Invalid email Id")
        .required("Email Id is required"),
      firstname: Yup.string().required("Enter Your First Name"),
      lastname: Yup.string().required("Enter Your Last Name"),
      country: Yup.string().required("Select Your Country"),
      state: Yup.string().required("Select Your State"),

      gender: Yup.string().required("Select Your Gender"),
      dob: Yup.string().required("Enter Your Date of Birth"),

      mobile: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 (Enter country code and Mobile number)"
        )
        .required("Enter the Mobile Number"),
      gender: Yup.string().required("Select Your Gender"),
      city: Yup.string().required("Enter Your City"),

      dob: Yup.string()
        .matches(
          /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])$/,
          " date format Should be (date-Month) example : 27-07"
        )
        .required("Date-Month is required"),
      Subscribe: Yup.bool().oneOf([true], "You must agree to the terms"),
      dobcheck: Yup.bool().oneOf(
        [true],
        "You must agree to the terms for Allow To Submission"
      ),
      // address: Yup.string().required("Enter Your Address"),
    }),
    onSubmit: (values, actions) => {
      validation.setFieldValue("spouse", SpouseDetails)
      validation.setFieldValue("children", ChildDetails)
      //   console.log("njkk", values)
      //   dispatch(UpadteBAM(values))
      //   setDis(true)
    },
  })
  const childrenData = async data => {
    // const value1 = await [...ChildDetails]
    const value1 = ChildDetails.push(data)
    // console.log(value1, data)
    setChildDetails(value1)
  }

  const DeleteChild = id => {
    let data = ChildDetails.filter((data, ind) => ind !== id)
    setChildDetails(data)
  }

  const ValidationChild = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: selectedChild?.firstname || "",
      gender: selectedChild?.gender || "",
      age: selectedChild?.age || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Enter Your child Name"),
      gender: Yup.string().required("select Your child Gender "),

      age: Yup.string().required("Enter Your child Age"),
    }),
    onSubmit: (values, actions) => {
      // console.log("Childss", values)
      if (selectedChild?.id !== null || undefined) {
        let edit = ChildDetails.map((val, ind) => {
          if (ind === selectedChild?.id) {
            val.firstname = values.firstname
            val.gender = values.gender
            val.age = values.age
          }
        })
        // console.log("value change", edit)
        setChildDetails(edit)
      } else {
        childrenData(values)
      }

      setChildModal(false)
      actions.resetForm()
      setSelectedChild()
      //   dispatch(ResetPassword(userData))
    },
  })

  useEffect(() => {
    if (!ChildModal) {
      setSelectedChild()
    }
  }, [setChildModal])

  const ValidationSpouse = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: SpouseDetails?.firstname || "",
      email: SpouseDetails?.email || "",
      gender: SpouseDetails?.gender || "",
      age: SpouseDetails?.age || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Enter Your Spouse Name"),
      gender: Yup.string().required("select Your Spouse Gender "),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      age: Yup.string().required("Enter Your Spouse Age"),
    }),
    onSubmit: (values, actions) => {
      // console.log("spouse", values)
      setSpouseDetails(values)
      // console.log(SpouseDetails.length)
      setSpouseModal(false)
      actions.resetForm()
      //   dispatch(ResetPassword(userData))
    },
  })

  const filterstates = State.filter(
    sta => sta.country_name == validation.values.country
  )
  const handleKeyDownonlynumber = event => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDownmobile = event => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9()\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDowndob = event => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9-\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDowncharcters = event => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[A-Za-z\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }

  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("editMember")
    SetSelectData("")
    navigate("/members")
  }

  return (
    <>
      <div className="page-content ">
        <Container fluid>
          <Row>
            <Card className="bg-light">
              {/* <CardTitle className=" p-3 mb-4  border-2 border-bottom border-success">
                {selectedData?._id ? "Update" : "Create"}  Member
              </CardTitle> */}
              <CardBody>
                <div className="p-3 mb-4 d-flex align-items-center border-2 border-bottom border-success ">
                  <h5 className="mb-1 card-title flex-grow-1">
                    {selectedData?._id ? "Update" : "Create"} Member
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <Link
                      to="/memberform"
                      onClick={e => {
                        Cancel(e)
                      }}
                      className="btn btn-dark me-1"
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                      }}
                      className="btn btn-info me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
              </CardBody>

              <CardBody>
                <Form
                  onSubmit={event => {
                    event.preventDefault()
                    validation.handleSubmit()
                  }}
                >
                  <div
                    className="col-12 d-flex flex-wrap justify-content-around gap-1 "
                    style={{ position: "relative", top: "-35px" }}
                  >
                    <div className="col-md-4 col-xs-12 ">
                      <div className="col-12  d-flex justify-content-center gap-1">
                        <Card>
                          <CardImg
                            src={`${APIServerIP}assets/uploads/${selectedData?.profile_img}`}
                            style={{
                              width: "200px",
                              height: "200px",
                              objectFit: "cover",
                              alignSelf: "center",
                            }}
                            className="rounded-circle m-5 "
                          ></CardImg>

                          <CardImgOverlay
                            style={{
                              top: "9.5rem",
                              left: "12rem",
                            }}
                          >
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                // setDis(false)
                                setProfileImageModal(true)
                              }}
                            >
                              {/* <BiSolidCameraPlus
                            className="text-primary fw-5 bg-light rounded-circle"
                            style={{ fontSize: "2.5rem" }}
                          /> */}
                            </button>
                          </CardImgOverlay>
                          <CardText className="text-left">
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <th scope="row" className="fw-bold">
                                    Member Id{" "}
                                  </th>
                                  <td>:</td>
                                  <td className="fw-bold text-warning">
                                    {selectedData?.member_id}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className="fw-bold">
                                    MemberShip Type{" "}
                                  </th>
                                  <td>:</td>
                                  <td className="fw-bold text-warning">
                                    {selectedData?.package_type}
                                  </td>
                                </tr>
                                {selectedData?.package_type ===
                                "Lifetime members" ? (
                                  ""
                                ) : (
                                  <tr>
                                    <th scope="row" className="fw-bold">
                                      Expired Date{" "}
                                    </th>
                                    <td>: </td>
                                    <td className="fw-bold text-warning">
                                      30th April {moment().format("YYYY")}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <th scope="row" className="fw-bold">
                                    {null}
                                  </th>
                                  <td></td>
                                  <td className="fw-bold text-warning">
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                    >
                                      Upgrade
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </CardText>
                        </Card>
                      </div>
                    </div>
                    <div className=" col-md-7  overflow-hidden">
                      <div>
                        <Card>
                          {dis !== false && (
                            <button
                              type="button"
                              className="btn btn-warning text-dark  align-self-end m-3"
                              onClick={() => setDis(false)}
                            >
                              {`Edit `}
                              <i className="far fa-edit"></i>

                              {/* <BiEdit style={{ fontSize: "1rem" }} /> */}
                            </button>
                          )}

                          <CardBody>
                            <Nav
                              pills
                              className="navtab-bg nav-justified border-bottom border-info pb-2"
                            >
                              <NavItem>
                                <NavLink
                                  style={{
                                    cursor: "pointer",
                                    background: "transparent",
                                    color: "black",
                                  }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1")
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    <i className="fas fa-user"></i>
                                  </span>
                                  <span className="d-none d-sm-block h4 bg-light p-2">
                                    Personal Information
                                  </span>
                                </NavLink>
                              </NavItem>
                              {selectedData?.package_type === "Family (Yr)" && (
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                      toggleCustom("2")
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block">
                                      Family Information
                                    </span>
                                  </NavLink>
                                </NavItem>
                              )}
                            </Nav>
                            <TabContent
                              activeTab={customActiveTab}
                              className="p-3 "
                            >
                              <TabPane tabId="1" className="mt-2">
                                <Row>
                                  <Col>
                                    <CardText className="mb-0">
                                      <Row className="col-12 d-flex flex-wrap justify-content-around ">
                                        <div className="mb-3 col-md-6 col-5">
                                          <Label htmlFor="email">
                                            First Name
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            name="firstname"
                                            className={`form-control `}
                                            placeholder="Enter Your First Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.firstname || ""
                                            }
                                            invalid={
                                              validation.touched.firstname &&
                                              validation.errors.firstname
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.firstname &&
                                          validation.errors.firstname ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.firstname}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 col-5 mb-3">
                                          <Label htmlFor="email">
                                            Last Name
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            name="lastname"
                                            className={`form-control`}
                                            placeholder="Enter Your Last Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.lastname || ""
                                            }
                                            invalid={
                                              validation.touched.lastname &&
                                              validation.errors.lastname
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.lastname &&
                                          validation.errors.lastname ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.lastname}
                                            </FormFeedback>
                                          ) : null}
                                        </div>

                                        <div className="col-md-6 col-5 mb-3">
                                          <Label htmlFor="email">
                                            Email
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            name="email"
                                            className={`form-control`}
                                            placeholder="Enter Your Email"
                                            type="email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.email || ""
                                            }
                                            invalid={
                                              validation.touched.email &&
                                              validation.errors.email
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.email &&
                                          validation.errors.email ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.email}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className=" col-md-6 col-5 mb-3">
                                          <Label htmlFor="dob">
                                            Date of Birth(DD-MM)
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            name="dob"
                                            className="form-control "
                                            placeholder="Date-Month"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.dob || ""}
                                            invalid={
                                              validation.touched.dob &&
                                              validation.errors.dob
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.dob &&
                                          validation.errors.dob ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.dob}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 col-5 mb-3">
                                          <Label htmlFor="mobile">
                                            Mobile Number
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            name="mobile"
                                            type="tel"
                                            min={1}
                                            className="form-control  "
                                            placeholder="Enter Mobile Number"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.mobile || ""
                                            }
                                            invalid={
                                              validation.touched.mobile &&
                                              validation.errors.mobile
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.mobile &&
                                          validation.errors.mobile ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.mobile}
                                            </FormFeedback>
                                          ) : null}
                                        </div>

                                        <div className="col-md-6 col-5 mb-3">
                                          <Label htmlFor="address">
                                            Address #1 Line
                                          </Label>
                                          <Input
                                            name="address"
                                            className="form-control  "
                                            placeholder="Enter Your Address"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.address || ""
                                            }
                                            invalid={
                                              validation.touched.address &&
                                              validation.errors.address
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.address &&
                                          validation.errors.address ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.address}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 col-5 mb-3">
                                          <Label htmlFor="homeadd2">
                                            Address #2 Line
                                          </Label>
                                          <Input
                                            name="address2"
                                            className="form-control  "
                                            placeholder="Enter Your Address"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.address2 || ""
                                            }
                                            invalid={
                                              validation.touched.address2 &&
                                              validation.errors.address2
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.address2 &&
                                          validation.errors.address2 ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.address2}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className=" col-md-6 col-5 mb-3">
                                          <Label htmlFor="city">
                                            City
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            name="city"
                                            className="form-control  "
                                            placeholder="Enter the City"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.city || ""}
                                            invalid={
                                              validation.touched.city &&
                                              validation.errors.city
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.city &&
                                          validation.errors.city ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.city}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 col-5 mb-3">
                                          <Label htmlFor="country">
                                            Country
                                            <span className="text-danger">
                                              *
                                            </span>{" "}
                                          </Label>
                                          <select
                                            id="country"
                                            name="country"
                                            className="form-control"
                                            onChange={e => {
                                              const V_code = Country.filter(
                                                country =>
                                                  country.name ===
                                                  e.target.value
                                              )

                                              validation.setFieldValue(
                                                "country",
                                                e.target.value
                                              )
                                              validation.setFieldValue(
                                                "country_code",
                                                V_code?.[0].iso2
                                              )
                                              validation.setFieldValue(
                                                "currency",
                                                V_code?.[0].currency
                                              )
                                            }}
                                            value={validation.values.country}
                                            disabled={dis}
                                          >
                                            <option
                                              value={validation.values.country}
                                            >
                                              {validation.values.country ||
                                                "Select  Country"}
                                            </option>
                                            {Country.map(country => (
                                              <option
                                                value={country.name}
                                                key={country.id}
                                              >
                                                {country.name}
                                              </option>
                                            ))}
                                          </select>
                                          {validation.errors.country ? (
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "red",
                                              }}
                                            >
                                              {validation.errors.country}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 col-5 mb-3">
                                          <Label htmlFor="state">
                                            State
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <select
                                            id="state"
                                            name="state"
                                            className="form-control"
                                            onChange={e =>
                                              validation.setFieldValue(
                                                "state",
                                                e.target.value
                                              )
                                            }
                                            value={validation.values.state}
                                            disabled={dis}
                                          >
                                            <option
                                              value={validation.values.state}
                                            >
                                              {validation.values.state ||
                                                "Select  State"}
                                            </option>
                                            {filterstates.map(state => (
                                              <option
                                                value={state.name}
                                                key={state.id}
                                                className="d-flex flex-grow-1"
                                              >
                                                {state.name}
                                              </option>
                                            ))}
                                          </select>
                                          {validation.errors.state ? (
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "red",
                                              }}
                                            >
                                              {validation.errors.state}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className=" col-lg-12 col-11 mb-3">
                                          <Label htmlFor="zipcode">
                                            Zip Code
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            name="zipcode"
                                            className="form-control  "
                                            placeholder="Enter the zipcode"
                                            type="tel"
                                            min={1}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.zipcode || ""
                                            }
                                            invalid={
                                              validation.touched.zipcode &&
                                              validation.errors.zipcode
                                                ? true
                                                : false
                                            }
                                            disabled={dis}
                                          />
                                          {validation.touched.zipcode &&
                                          validation.errors.zipcode ? (
                                            <FormFeedback
                                              type="invalid"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {validation.errors.zipcode}
                                            </FormFeedback>
                                          ) : null}
                                        </div>

                                        <div className="mb-3 col-11 col-sm-11">
                                          <Label htmlFor="gender">
                                            Gender
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <fieldset>
                                            <input
                                              type="radio"
                                              name="gender"
                                              id="Male"
                                              onChange={validation.handleChange}
                                              className="me-2 form-check-input "
                                              value={
                                                validation.values.gender || ""
                                              }
                                              checked={
                                                validation.values.gender ===
                                                "Male"
                                              }
                                              onClick={e => {
                                                validation.setFieldValue(
                                                  "gender",
                                                  "Male"
                                                )
                                              }}
                                              disabled={dis}
                                            />
                                            <label
                                              htmlFor="Male"
                                              className="me-3"
                                            >
                                              Male
                                            </label>

                                            <input
                                              type="radio"
                                              name="gender"
                                              className="me-2 form-check-input"
                                              id="FeMale"
                                              onChange={validation.handleChange}
                                              value={
                                                validation.values.gender || ""
                                              }
                                              checked={
                                                validation.values.gender ===
                                                "FeMale"
                                              }
                                              onClick={e => {
                                                validation.setFieldValue(
                                                  "gender",
                                                  "FeMale"
                                                )
                                              }}
                                              disabled={dis}
                                            />
                                            <label
                                              htmlFor="FeMale"
                                              className="me-3"
                                            >
                                              Female
                                            </label>

                                            <input
                                              type="radio"
                                              name="gender"
                                              className="me-2 form-check-input"
                                              id="Transgender"
                                              value={
                                                validation.values.gender || ""
                                              }
                                              onChange={validation.handleChange}
                                              checked={
                                                validation.values.gender ===
                                                "Transgender"
                                              }
                                              onClick={e => {
                                                validation.setFieldValue(
                                                  "gender",
                                                  "Transgender"
                                                )
                                              }}
                                              disabled={dis}
                                            />
                                            <label
                                              htmlFor="Transgender"
                                              className="me-3"
                                            >
                                              Transgender
                                            </label>
                                          </fieldset>
                                          {validation.errors.gender ? (
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "red",
                                              }}
                                            >
                                              {validation.errors.gender}
                                            </div>
                                          ) : null}
                                        </div>
                                        {/* <div className="col-11 mb-3 d-flex flex-column flex-wrap ">
                                      <Label htmlFor="member">
                                        SATS Membership
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <div
                                        className="btn-group d-flex flex-wrap"
                                        role="group"
                                      >
                                        {MembershipTypes.map((item, ind) => (
                                          <>
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              name="btnradio"
                                              id={item._id}
                                              autoComplete="off"
                                              value={
                                                validation.values
                                                  .package_type || ""
                                              }
                                              checked={
                                                validation.values
                                                  .package_type ===
                                                item.membertype
                                              }
                                              onClick={(e) => {
                                                validation.setFieldValue(
                                                  "package_type",
                                                  item.membertype
                                                )
                                                validation.setFieldValue(
                                                  "package_fee",
                                                  item.memtypeprice
                                                )
                                                console.log(item.sno)
                                              }}
                                              invalid={
                                                validation.errors.package_type
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="btn btn-outline-warning d-flex flex-column"
                                              htmlFor={item._id}
                                            >
                                              <p>{item.membertype}</p>

                                              <h6>${item.memtypeprice}*</h6>
                                            </label>
                                          </>
                                        ))}
                                      </div>
                                      {validation.errors.package_type ? (
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            color: "red",
                                          }}
                                        >
                                          {validation.errors.package_type}
                                        </div>
                                      ) : null}
                                    </div> */}
                                      </Row>
                                    </CardText>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="2" className="mt-2">
                                <Row>
                                  {SpouseDetails?.firstname != null && (
                                    <Table
                                      bordered
                                      hover
                                      responsive
                                      className="text-right mb-2 "
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            colSpan={4}
                                            className="text-center fw-bold"
                                          >
                                            Spouse Details
                                            {/* <button
                                          type="button"
                                          className="btn btn-outline-primary text-end mx-3 p-2"
                                          onClick={(e) => {
                                            setSpouseModal(true)
                                          }}
                                          disabled={dis}
                                        >
                                          Edit{" "}
                                          <BiEdit
                                            style={{ fontSize: "1rem" }}
                                          />
                                        </button> */}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Gender</th>
                                          {/* <th>Age</th> */}
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{SpouseDetails?.firstname}</td>
                                          <td>{SpouseDetails?.email}</td>
                                          <td>{SpouseDetails?.gender}</td>
                                          {/* <td>{SpouseDetails?.age}</td> */}
                                          <td className="d-flex">
                                            <button
                                              type="button"
                                              className="btn btn-info  mx-3 p-2"
                                              onClick={e => {
                                                setSpouseModal(true)
                                              }}
                                              disabled={dis}
                                            >
                                              <i className="far fa-edit"></i>
                                              {/* <BiEdit
                                            style={{ fontSize: "1rem" }}
                                          /> */}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-danger  mx-3 p-2"
                                              onClick={e => {
                                                setSpouseDetails()
                                              }}
                                              disabled={dis}
                                            >
                                              <DeleteForeverOutlinedIcon fontSize="small" />
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  )}
                                  {ChildDetails?.length > 0 && (
                                    <Table
                                      bordered
                                      hover
                                      responsive
                                      className="text-right "
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            colSpan={4}
                                            className="text-center fw-bold"
                                          >
                                            Children Details
                                          </th>
                                        </tr>
                                        <tr>
                                          <th>Name</th>
                                          <th>Gender</th>
                                          <th>Age</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {ChildDetails?.map((ch, ind) => (
                                          <tr key={ind}>
                                            <td>{ch?.firstname}</td>
                                            <td>{ch?.gender}</td>
                                            <td>{ch?.age}</td>
                                            <td className="d-flex">
                                              <button
                                                type="button"
                                                className="btn btn-info text-end mx-3 p-2"
                                                onClick={e => {
                                                  setSelectedChild({
                                                    age: ch?.age,
                                                    firstname: ch?.firstname,
                                                    gender: ch?.gender,
                                                    id: ind,
                                                  })
                                                  // console.log(
                                                  //   "child edit ",
                                                  //   selectedChild
                                                  // )
                                                  setChildModal(true)
                                                }}
                                                disabled={dis}
                                              >
                                                <i className="far fa-edit"></i>
                                                {/* <BiEdit
                                              style={{ fontSize: "1rem" }}
                                            /> */}
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-danger text-end mx-3 p-2"
                                                onClick={e => {
                                                  DeleteChild(ind)
                                                }}
                                                disabled={dis}
                                              >
                                                <DeleteForeverOutlinedIcon fontSize="small" />
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  )}
                                  <Col>
                                    <CardText className="mt-3">
                                      <div className=" text-end col-12 mb-4">
                                        {SpouseDetails?.firstname == null && (
                                          <button
                                            type="button"
                                            onClick={() => setSpouseModal(true)}
                                            className="btn btn-sm btn-outline-primary mx-2 "
                                            disabled={dis}
                                          >
                                            {/* <AiOutlinePlusCircle className="fs-5" /> */}
                                            <span className="ps-2">
                                              Add Spouse
                                            </span>
                                          </button>
                                        )}

                                        {ChildDetails?.length < 4 && (
                                          <button
                                            type="button"
                                            onClick={() => setChildModal(true)}
                                            className="btn btn-sm btn-outline-primary  "
                                            disabled={dis}
                                          >
                                            {/* <AiOutlinePlusCircle className="fs-5" /> */}
                                            <span className="ps-2">
                                              Add Child
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    </CardText>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                  <Col className="col-12 d-flex">
                    {dis === false && (
                      <div className=" text-end col-12 mb-4">
                        <button
                          type="button"
                          className="btn btn-light px-3 mx-3"
                          onClick={() => setDis(true)}
                        >
                          {"Cancel"}
                        </button>
                        <button
                          type="button"
                          onClick={event => {
                            event.preventDefault()
                            validation.handleSubmit()
                          }}
                          className="btn btn-info px-3 me-3"
                        >
                          {"Update"}
                        </button>
                      </div>
                    )}
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={ChildModal}
        onHide={setChildModal}
        centered
      >
        <h2 className="title">Add Child</h2>
        <Form
        // onSubmit={(e) => {
        //   e.preventDefault()
        //   ValidationChild.handleSubmit()
        // }}
        >
          <div className="mb-3  col-12">
            <Label htmlFor="firstname">Child Name</Label>
            <Input
              name="firstname"
              className="form-control  "
              placeholder="Enter the Child Name"
              type="text"
              onChange={ValidationChild.handleChange}
              onBlur={ValidationChild.handleBlur}
              value={ValidationChild.values.firstname || ""}
              invalid={
                ValidationChild.touched.firstname &&
                ValidationChild.errors.firstname
                  ? true
                  : false
              }
            />
            {ValidationChild.touched.firstname &&
            ValidationChild.errors.firstname ? (
              <FormFeedback type="invalid">
                {ValidationChild.errors.firstname}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3  col-12">
            <Label htmlFor="gender"> Gender</Label>
            <select
              id="gender"
              name="gender"
              className="form-control"
              value={ValidationChild.values.gender}
              onChange={e =>
                ValidationChild.setFieldValue("gender", e.target.value)
              }
            >
              <option value={""}>Select Your Child Gender</option>
              <option value={"Male"}>Male</option>
              <option value={"Female"}>Female</option>
            </select>
          </div>
          <div className="mb-3  col-12">
            <Label htmlFor="age">Child Age</Label>
            <Input
              name="age"
              className="form-control  "
              placeholder="Enter the Age"
              type="number"
              onChange={ValidationChild.handleChange}
              onBlur={ValidationChild.handleBlur}
              value={ValidationChild.values.age || ""}
              invalid={
                ValidationChild.touched.age && ValidationChild.errors.age
                  ? true
                  : false
              }
            />
            {ValidationChild.touched.age && ValidationChild.errors.age ? (
              <FormFeedback type="invalid">
                {ValidationChild.errors.age}
              </FormFeedback>
            ) : null}
          </div>
          <div className="form-group">
            <button
              type="button"
              onClick={e => {
                e.preventDefault()
                ValidationChild.handleSubmit()
              }}
              className="btn btn-outline-primary btn-block"
            >
              Submit
            </button>
          </div>
          <Link
            to={"#"}
            className="sign-text d-block"
            data-bs-toggle="collapse"
            onClick={() => setChildModal(false)}
          >
            Back
          </Link>
        </Form>
      </Modal>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={SpouseModal}
        onHide={setSpouseModal}
        centered
      >
        <h2 className="title">Add Spouse</h2>
        <Form
        // onSubmit={(e) => {
        //   e.preventDefault()
        //   ValidationSpouse.handleSubmit()
        // }}
        >
          <div className="mb-3 col-12">
            <Label htmlFor="firstname">Spouse Name</Label>
            <Input
              name="firstname"
              className="form-control  "
              placeholder="Enter the  Name"
              type="text"
              onChange={ValidationSpouse.handleChange}
              onBlur={ValidationSpouse.handleBlur}
              value={ValidationSpouse.values.firstname || ""}
              invalid={
                ValidationSpouse.touched.firstname &&
                ValidationSpouse.errors.firstname
                  ? true
                  : false
              }
            />
            {ValidationSpouse.touched.firstname &&
            ValidationSpouse.errors.firstname ? (
              <FormFeedback type="invalid">
                {ValidationSpouse.errors.firstname}
              </FormFeedback>
            ) : null}
          </div>

          <div className=" mb-3 col-12">
            <Label htmlFor="email">Spouse Email</Label>
            <Input
              name="email"
              className="form-control  "
              placeholder="Enter the  Email"
              type="email"
              onChange={ValidationSpouse.handleChange}
              onBlur={ValidationSpouse.handleBlur}
              value={ValidationSpouse.values.email || ""}
              invalid={
                ValidationSpouse.touched.email && ValidationSpouse.errors.email
                  ? true
                  : false
              }
            />
            {ValidationSpouse.touched.email && ValidationSpouse.errors.email ? (
              <FormFeedback type="invalid">
                {ValidationSpouse.errors.email}
              </FormFeedback>
            ) : null}
          </div>
          <div className="mb-3 col-12">
            <Label htmlFor="gender"> Gender</Label>
            <select
              id="gender"
              name="gender"
              className="form-control"
              value={ValidationSpouse.values.gender}
              onChange={e =>
                ValidationSpouse.setFieldValue("gender", e.target.value)
              }
            >
              <option value={""}>Select Your Gender</option>
              <option value={"Male"}>Male</option>
              <option value={"Female"}>Female</option>
            </select>
          </div>
          <div className="mb-3 col-12">
            <Label htmlFor="age">Age </Label>
            <Input
              name="age"
              className="form-control  "
              placeholder="Enter the Age"
              type="number"
              onChange={ValidationSpouse.handleChange}
              onBlur={ValidationSpouse.handleBlur}
              value={ValidationSpouse.values.age || ""}
              invalid={
                ValidationSpouse.touched.age && ValidationSpouse.errors.age
                  ? true
                  : false
              }
            />
            {ValidationSpouse.touched.age && ValidationSpouse.errors.age ? (
              <FormFeedback type="invalid">
                {ValidationSpouse.errors.age}
              </FormFeedback>
            ) : null}
          </div>
          <div className="form-group">
            <button
              onClick={e => {
                e.preventDefault()
                ValidationSpouse.handleSubmit()
              }}
              type="button"
              className="btn btn-outline-primary btn-block"
            >
              Submit
            </button>
          </div>
          <Link
            to={"#"}
            className="sign-text d-block"
            data-bs-toggle="collapse"
            onClick={() => setSpouseModal(false)}
          >
            Back
          </Link>
        </Form>
      </Modal>
    </>
  )
}

export default MemberForm
