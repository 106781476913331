import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const fetchMemberApi = `${APIServerIP}manage-user/fetch-user-members`
const fetchFamilyMembersApi = `${APIServerIP}manage-user/fetch-family-members`
// const createMemberApi = `${APIServerIP}member-type-lists/create-member-type`
// const updateMemberApi = `${APIServerIP}member-type-lists/update-member-type`
// const activeInactiveMemberApi = `${APIServerIP}member-type-lists/active-inactive-member-type`
// const deleteMemberApi = `${APIServerIP}`
const fetchRemainingMemberApi = `${APIServerIP}manage-user/fetch-remaining-user-members`
const editUserApi = `${APIServerIP}members-list/fetch-member-info`
const fetchHomeCountApi = `${APIServerIP}manage-user/active-members-separate-count`
const initialState = {
  MemberList: [],
  FamilyMembersList: [],
  RemainingMemberList: [],
  MemberisSuccessfully: false,
  EditUser: "",
  HomeMembersCountList: [],
}

const MemberSlice = createSlice({
  name: "Member",
  initialState: initialState,
  reducers: {
    setMemberList: (state, action) => {
      state.MemberList = action.payload
    },
    setFamilyMemberList: (state, action) => {
      state.FamilyMembersList = action.payload
    },
    setMemberSuccessfullyAdded: (state, action) => {
      state.MemberisSuccessfully = true
    },
    setReamingMembers: (state, action) => {
      state.RemainingMemberList = action.payload
    },
    setUserEdit: (state, action) => {
      state.EditUser = action.payload
    },
    setHomeMembersCountList: (state, action) => {
      state.HomeMembersCountList = action.payload
    },

    resetMember: (state, action) => {
      state.MemberisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

// export const createMember = values => {
//   return async dispatch => {
//     try {
//       dispatch(resetMember())
//       const responseData = await post(createMemberApi, values)
//       // console.log("Member user", responseData)
//       if (responseData.response_code === 200) {
//         dispatch(setMemberSuccessfullyAdded())
//       }
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }
// export const updateMember = values => {
//   return async dispatch => {
//     try {
//       dispatch(resetMember())
//       const responseData = await post(updateMemberApi, values)
//       // console.log("Member user", responseData)
//       if (responseData.response_code === 200) {
//         dispatch(setMemberSuccessfullyAdded())
//       }
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }

// export const deleteMember = id => {
//   return async dispatch => {
//     try {
//       dispatch(resetMember())
//       const responseData = await post(deleteMemberApi, id)

//       if (responseData.response_code === 200) {
//         console.log("Member user", responseData)
//         dispatch(setMemberSuccessfullyAdded())
//       }
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }
// export const activeInactiveMember = data => {
//   return async dispatch => {
//     try {
//       dispatch(resetMember())
//       const responseData = await post(activeInactiveMemberApi, data)

//       if (responseData.response_code === 200) {
//         // console.log("active user", responseData)
//         dispatch(setMemberSuccessfullyAdded())
//       }
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }

export const editUserMember = data => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await post(editUserApi, data)
      // console.log("active get users", responseData.data[0])
      if (responseData.response_code === 200) {
        dispatch(setUserEdit(responseData.data[0]))
        dispatch(resetMember())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const fetchMember = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchMemberApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setMemberList(responseData.data))
        dispatch(resetMember())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const fetchFamilyMembers = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchFamilyMembersApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setFamilyMemberList(responseData.data))
        dispatch(resetMember())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const fetchRemainingMember = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchRemainingMemberApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setReamingMembers(responseData.data))
        dispatch(resetMember())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const fetchHomeCount = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchHomeCountApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setHomeMembersCountList(responseData.data))
        dispatch(resetMember())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setMemberList,
  setMemberSuccessfullyAdded,
  setUserEdit,
  setHomeMembersCountList,
  resetMember,
  setFamilyMemberList,
  setReamingMembers,
} = MemberSlice.actions

export default MemberSlice.reducer
