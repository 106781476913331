import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const fetchMemberTypeApi = `${APIServerIP}member-type-lists/fetch-members-types`
const createMemberTypeApi = `${APIServerIP}member-type-lists/create-members-type`
const updateMemberTypeApi = `${APIServerIP}member-type-lists/update-members-type`
const activeInactiveMemberTypeApi = `${APIServerIP}member-type-lists/active-inactive-members-type`
const deleteMemberTypeApi = `${APIServerIP}member-type-lists/delete-members-type`

const initialState = {
  MemberTypeList: [],
  MemberTypeisSuccessfully: false,
}

const MemberTypeSlice = createSlice({
  name: "Membertype",
  initialState: initialState,
  reducers: {
    setMemberTypeList: (state, action) => {
      state.MemberTypeList = action.payload
    },
    setMemberTypeSuccessfullyAdded: (state, action) => {
      state.MemberTypeisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetMemberType: (state, action) => {
      state.MemberTypeisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const createMemberType = values => {
  return async dispatch => {
    dispatch(resetMemberType())
    try {
      const responseData = await post(createMemberTypeApi, values)
      // console.log("MemberType user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setMemberTypeSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateMemberType = values => {
  return async dispatch => {
    dispatch(resetMemberType())
    try {
      const responseData = await post(updateMemberTypeApi, values)
      // console.log("MemberType user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setMemberTypeSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteMemberType = id => {
  return async dispatch => {
    dispatch(resetMemberType())
    try {
      const responseData = await post(deleteMemberTypeApi, id)

      if (responseData.response_code === 200) {
        // console.log("MemberType user", responseData)
        dispatch(setMemberTypeSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveMemberType = data => {
  return async dispatch => {
    dispatch(resetMemberType())
    try {
      const responseData = await post(activeInactiveMemberTypeApi, data)

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setMemberTypeSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const fetchMemberType = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchMemberTypeApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setMemberTypeList(responseData.data))
        dispatch(resetMemberType())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setMemberTypeList,
  setMemberTypeSuccessfullyAdded,
  setUserEdit,
  resetMemberType,
} = MemberTypeSlice.actions

export default MemberTypeSlice.reducer
