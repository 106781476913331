import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../helpers/api_helper"
import { APIServerIP } from "../helpers/Sats_api_helper"

const createDonateApi = `${APIServerIP}donate-user/admin-create-donator`
const fetchDonateApi = `${APIServerIP}donate-user/fetch-donaters`
const updateDonateApi = `${APIServerIP}donate-user/update-donate-info`
const deleteDonateApi = `${APIServerIP}donate-user/delete-donate-user`

const initialState = {
  DonateList: [],
  DonateisSuccessfully: false,
}

const DonateSlice = createSlice({
  name: "Donate",
  initialState: initialState,
  reducers: {
    setDonateList: (state, action) => {
      state.DonateList = action.payload
    },
    setDonateSuccessfullyAdded: (state, action) => {
      state.DonateisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },

    resetDonate: (state, action) => {
      state.DonateisSuccessfully = false
      state.isUserEdit = false
    },
  },
})

export const createDonate = values => {
  return async dispatch => {
    dispatch(resetDonate())
    try {
      const responseData = await post(createDonateApi, values)

      if (responseData.response_code === 200) {
        // console.log("Donate user", responseData)
        dispatch(setDonateSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const updateDonate = values => {
  return async dispatch => {
    dispatch(resetDonate())
    try {
      const responseData = await post(updateDonateApi, values)

      if (responseData.response_code === 200) {
        // console.log("Donate user", responseData)
        dispatch(setDonateSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteDonate = id => {
  return async dispatch => {
    dispatch(resetDonate())
    try {
      const responseData = await post(deleteDonateApi, id)

      if (responseData.response_code === 200) {
        // console.log("Donate user", responseData)
        dispatch(setDonateSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const fetchDonate = () => {
  // console.log(id)
  return async dispatch => {
    try {
      let responseData = await get(fetchDonateApi)
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setDonateList(responseData.data))
        dispatch(resetDonate())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setDonateList,
  setDonateSuccessfullyAdded,
  setUserEdit,
  resetDonate,
} = DonateSlice.actions

export default DonateSlice.reducer
